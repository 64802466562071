import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from './AuthContext';

export default function PrivateRoutes() {
  const { currentUser } = useAuth();
  return (
    currentUser ? <div>
        <Outlet />
    </div> : <Navigate to="/login" />
  )
}
