import React, { useContext, useEffect, useState, createContext } from 'react'
import { auth, analytics } from './firebase'
import axios from 'axios'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, GithubAuthProvider, getAdditionalUserInfo } from 'firebase/auth';
const AuthContext = createContext();


export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    async function emailSignup(email, password) {
        try {
            const result = await createUserWithEmailAndPassword(auth, email, password)

            sendWelcomeEmail(result);

            return result;
        } catch (e) {
            console.log("CATCH")
            return e
        } finally {
            analytics.logEvent('sign_up')
        }
    }

    async function emailLogin(email, password) {
        try {
            const result = await signInWithEmailAndPassword(auth, email, password)

            sendWelcomeEmail(result);

            return result;
        } catch (e) {
            console.log(e);
        } finally {
            analytics.logEvent('login')
        }
    }

    async function googleLogin() {
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);

            sendWelcomeEmail(result);
        } catch (e) {
            console.log(e);
        } finally {
            analytics.logEvent('login')
        }
    }

    async function githubLogin() {
        try {
            const provider = new GithubAuthProvider();
            const result = await signInWithPopup(auth, provider);

            sendWelcomeEmail(result);
        } catch (e) {
            console.log(e);
        } finally {
            analytics.logEvent('login')
        }
    }

    async function sendWelcomeEmail(result) {
        const additionalUserInfo = getAdditionalUserInfo(result);

        // If the user is new, send a welcome email
        if (additionalUserInfo.isNewUser === true) {
            const token = result.user.accessToken

            await fetch(`/v2/auth/welcome-email`, {
                method: 'POST',
                headers: {
                    'authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'email': `${result.user.email}`,
                    'uid': `${result.user.uid}`
                })
            }).then((res) => {
                if(res.status !== 200){
                    console.log("Welcome email error: ", res)
                }
            })
        } else {
            // Get user's welcome email status
            axios.get(`/v2/checkWelcomeEmailStatus/${result.user.uid}`).then((res) => {
                // If the user's welcome email status exists in arango, handle it
                if(res.data.length > 0){
                    const welcomeEmailSentStatus = res.data[0].sent
                    const milisecondsSinceLastEmailAttempt = new Date() - new Date(res.data[0].dateAttempted)
                    const weekInMiliseconds = 604800000

                    // If it has been less than a week and the user has not received a welcome email, try to send it again
                    if(welcomeEmailSentStatus === false && milisecondsSinceLastEmailAttempt < weekInMiliseconds){
                        const token = result.user.accessToken

                        fetch(`/v2/auth/welcome-email`, {
                            method: 'POST',
                            headers: {
                                'authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                'email': `${result.user.email}`,
                                'uid': `${result.user.uid}`
                            })
                        }).then((res) => {
                            if(res.status !== 200){
                                console.log("Welcome email error: ", res)
                            }
                        })
                    }
                }
            })
        }
    }

    async function sendWelcomeEmail(result) {
        const additionalUserInfo = getAdditionalUserInfo(result);

        // If the user is new, send a welcome email
        if (additionalUserInfo.isNewUser === true) {
            const token = result.user.accessToken

            await fetch(`/v2/auth/welcome-email`, {
                method: 'POST',
                headers: {
                    'authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'email': `${result.user.email}`,
                    'uid': `${result.user.uid}`
                })
            }).then((res) => {
                if(res.status !== 200){
                    console.log("Welcome email error: ", res)
                }
            })
        } else {
            // Get user's welcome email status
            var token = await currentUser.getIdToken();
            axios.get(`/v2/checkWelcomeEmailStatus`, {
                headers: {
                  'authorization': `Bearer ${token}`
                }
            }).then((res) => {
                // If the user's welcome email status exists in arango, handle it
                if(res.data.length > 0){
                    const welcomeEmailSentStatus = res.data[0].sent
                    const milisecondsSinceLastEmailAttempt = new Date() - new Date(res.data[0].dateAttempted)
                    const weekInMiliseconds = 604800000

                    // If it has been less than a week and the user has not received a welcome email, try to send it again
                    if(welcomeEmailSentStatus === false && milisecondsSinceLastEmailAttempt < weekInMiliseconds){
                        const token = result.user.accessToken

                        fetch(`/v2/auth/welcome-email`, {
                            method: 'POST',
                            headers: {
                                'authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                'email': `${result.user.email}`,
                                'uid': `${result.user.uid}`
                            })
                        }).then((res) => {
                            if(res.status !== 200){
                                console.log("Welcome email error: ", res)
                            }
                        })
                    }
                }
            })
        }
    }

    function logout() {
        return auth.signOut();
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })

        return unsubscribe;
    })

    const value = {
        currentUser,
        emailSignup,
        emailLogin,
        googleLogin,
        githubLogin,
        useAuth,
        logout,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
