import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Divider, IconButton,
    Tooltip, Typography, TextField } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import GroupView from './GroupView'
import { useAuth } from '../auth/AuthContext'

export default function PhaseView({DATE_OPTIONS, colors, item, id, manifestLength, getItemStyle, getListStyle, completed, nodes, allowEdit,
        candidates, IconArray, manifest, AddPhase, allDetails, setAllDetails, dragging, mode, username, mGroups, mCandidates, RefreshManifest, showAddPhase, setShowAddPhase})
{
    const [showDelete, setShowDelete] = useState(false)
    const [deletePhase, setDeletePhase] = useState(false)
    const [groupName, setGroupName] = useState('')
    const [showG, setShowG] = useState(false)
    const {currentUser} = useAuth()
    // function DeletePhase() {
    //     let listTemp = currentManifest
    //     let detailsTemp = [...allDetails]
    //     const phaseName = listTemp.phaseView[id].name
    //     listTemp.phaseView.splice(id,1)
    //     const manifestIndex = detailsTemp.findIndex((item,id)=>item.id == sessionStorage.getItem('current'))
    //     detailsTemp.splice(manifestIndex, 1, listTemp)
    //     const postObj = {
    //         key: "manifestDetails",
    //         user: 0,
    //         data: detailsTemp
    //     }
    //     axios.post('/manifestdetails',postObj)
    //     .then(res=>{
    //         const addedTime = new Date()
    //         const auditObj = {
    //             username: username,
    //             uid: currentUser?.uid,
    //             event: `${phaseName} deleted from ${currentManifest.id}`,
    //             time: addedTime,
    //             manifest: currentManifest.id,
    //             type: "Manifest Edit"
    //         }
    //         axios.post('/v2/audit',auditObj)
    //         axios.get('/manifestdetails')
    //         .then(res=>{
    //             const phaseTemp = res.data.results[0][`release-service`].data
    //             const currentTemp = phaseTemp.find((item,ind)=>item.id == sessionStorage.getItem('current'))
    //             setCurrentManifest(currentTemp)
    //             setAllDetails(phaseTemp)
    //             setDeletePhase(false)
    //         })
    //     })
    // }

    async function DeletePhase() {
        const postObj = {
            name: item.name.split('-')[0]
        }

        var token = await currentUser.getIdToken();

        axios.post(`/v2/manifests/${manifest.id}/deletePhase`, postObj, {
            headers: {
            'authorization': `Bearer ${token}`
            }
        }).then(() => {
            RefreshManifest();
            setDeletePhase(false);
        })
    }

    async function AddGroup(phaseNum) {
        const postObj = {
            name: `Group ${mGroups.length+1}`,
            label: groupName
        }

        var token = await currentUser.getIdToken();

        axios.post(`/v2/manifests/${manifest.id}/addGroup/${phaseNum.name.split('-')[0]}`, postObj, {
            headers: {
              'authorization': `Bearer ${token}`
            }
        })
        .then(res=>{
            RefreshManifest()
            setShowG(false)
        })
    }

    async function AddPhaseAction() {
        var token = await currentUser.getIdToken();
        
        axios.post(`/v2/manifests/${manifest.id}/addPhase`, {}, {
            headers: {
              'authorization': `Bearer ${token}`
            }
        })
        .then(res=>{
            RefreshManifest()
            setShowAddPhase(false)
        })
    }

    return (
        <Box key={item.name} sx={{mt:'1vh'}}>
            <Box sx={{display:'flex', alignItems:'center', gap:2}} onMouseEnter={()=>setShowDelete(true)}
                onMouseLeave={()=>setShowDelete(false)}
            >
                <Typography variant="subtitle1">{item.name.split('-')[0]}</Typography>
                {allowEdit ? 
                    <button size="small" onClick={()=>setShowG(true)}
                        className={`border ${mode === 'dark' ? 'border-neutral-600' : 'border-neutral-300'} h-max py-1 px-2 shadow-sm rounded-md hover:bg-opacity-40 hover:text-[#2997ff] hover:border-blue-200 transition duration-300`}
                    >
                        Add Group    
                    </button>
                :
                    <></>
                }
                {allowEdit && id > 0 ? 
                    <button size="small" onClick={()=>setDeletePhase(true)}
                        className={`border ${mode === 'dark' ? 'border-neutral-600' : 'border-neutral-300'} ml-auto h-max py-1 px-3 shadow-sm rounded-md hover:bg-opacity-40 hover:text-red-500 hover:border-red-300 transition duration-300`}>
                        Delete {item.name.split('-')[0]}    
                    </button>
                :
                    <></>
                }
            </Box>
            <Divider sx={{margin:'1vh 0'}}/>
            { mGroups.map((group,ind)=>
                group.phase === item.name.split('-')[0] ?
                <GroupView group={group} DATE_OPTIONS={DATE_OPTIONS} colors={colors} item={item} id={id} ind={ind} manifestLength={manifestLength} getItemStyle={getItemStyle} nodes={nodes}
                    getListStyle={getListStyle} key={`${item.name}${ind}`} completed={completed} candidates={candidates} IconArray={IconArray}
                    dragging={dragging} mode={mode} allowEdit={allowEdit}
                    username={username} mCandidates={mCandidates} mGroups={mGroups} manifest={manifest} RefreshManifest={RefreshManifest}
                />
                :
                <></>
            )}
                    
            <Dialog open={deletePhase} onClose={()=>setDeletePhase(false)}>
                <DialogTitle>Delete Phase</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete {item.name.split('-')[0]}?<br />All groups and candidates in {item.name.split('-')[0]} will be deleted.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={DeletePhase}
                        className={` border border-neutral-800 bg-neutral-800 text-white h-max py-1 px-4 shadow-sm rounded-md transition hover:bg-opacity-80 duration-300 hover:shadow-lg`}
                    >
                        Delete
                    </button>
                    <button onClick={()=>setDeletePhase(false)}
                        className={` border border-neutral-800 h-max py-1 px-4 shadow-sm rounded-md transition duration-300 hover:shadow-lg`}
                    >
                        Cancel
                    </button>
                </DialogActions>
            </Dialog>
            <Dialog open={showG} onClose={()=>{setShowG(false)
                setGroupName('')}} maxWidth="sm"
            >
                <DialogTitle>Add Group to {item.name}</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: 2, justifyContent:'center', display:'flex', gap:2}}>
                        <TextField label="Group Name" size="small" value={groupName} onChange={(e)=>setGroupName(e.target.value)}></TextField>
                        
                    </Box>
                </DialogContent>
                <DialogActions>
                    { groupName !== '' && groupName !== undefined ?
                        <button onClick={()=>AddGroup(item)}
                            className={` border border-neutral-800 bg-neutral-800 text-white h-max py-1 px-4 shadow-sm rounded-md transition hover:bg-opacity-80 duration-300 hover:shadow-lg`}
                        >
                            Add Group
                        </button>
                    :
                        <button
                            className={`border text-gray-400 h-max py-1 px-4 shadow-sm rounded-md`}
                        >
                            Add Group
                        </button>
                    }
                </DialogActions>
            </Dialog>
            <Dialog open={showAddPhase} onClose={()=>{setShowAddPhase(false)
                setGroupName('')}} maxWidth="sm"
            >
                <DialogTitle>Add Phase to {manifest.description}</DialogTitle>
                <DialogActions>
                    <button onClick={()=>AddPhaseAction()}
                        className={` bg-neutral-800 text-white h-max py-1 px-4 shadow-sm rounded-md transition hover:bg-opacity-80 duration-300 hover:shadow-lg`}
                    >
                        Add Phase
                    </button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}