import React, { useState, useEffect } from 'react'
import { InputBase, Paper, Table, TableRow, TableCell, TableBody, IconButton, Container, Box,
        Typography, Button, MenuItem, TableHead, Menu, Dialog, DialogTitle, DialogContent, Tooltip,
        Select, InputLabel, FormControl, TextField, Grid, Input, Divider } from '@mui/material'
import axios from 'axios'
import TourNavigation from './TourNavigation'
import { useNavigate } from 'react-router'
import { useAuth } from '../auth/AuthContext'

export default function CreateRelease({show, setShow, username, mode, DATE_OPTIONS, setManifests, slide, setSlide, homepage, setCurrent, tour }) {
    const [editing, setEditing] = useState(null)
    const [editDescription, setEditDescription] = useState('')
    const [editVersion, setEditVersion] = useState('')
    const [editEnv, setEditEnv] = useState('')
    const [env, setEnv] = useState('QA')
    const [type, setType] = useState('SCHEDULED')
    const [release, setRelease] = useState('')
    const [description, setDescription] = useState('')
    const timeNow = new Date().getTime()
    const [creating, setCreating] = useState(false)
    const [scheduledDate, setScheduledDate] = useState(new Date(timeNow).toISOString().slice(0,16));
    const currentDate = new Date().toLocaleDateString()
    const ConditionalWrapper = ({condition, wrapper, children }) =>
        condition ? wrapper(children) : children
    const navigate = useNavigate()
    
    const { currentUser } = useAuth()

    async function CreateManifest(e) {
        e.preventDefault()

        var token = await currentUser.getIdToken();

        axios.get('/v2/generateHash', {
            headers: {
              'authorization': `Bearer ${token}`
            }
        })
        .then((hashResponse) => {
            const newId = hashResponse.data
            let formData = {
                dateAdded: timeNow,
                id: newId,
                uid: currentUser.uid,
                description: description,
                scheduledTimeStamp: Date.parse(scheduledDate),
                status: "In Progress",
                targetEnvironment: env,
                releaseType: type,
                type: "manifest",
                version: release
            }
    
            setCreating(true)

            axios.post(`/v2/manifest/create/${newId}`, formData, {
                headers: {
                    'authorization': `Bearer ${token}`
                }
            })
            .then((res) => { 
                // axios.post('/manifestdetails',dpostObj)
                // .then(res=>{
                //     GetAllReleases()
                //     .then(res=>{
                    axios.get('/v2/manifests', {
                        headers: {
                            'authorization': `Bearer ${token}`
                        }
                    })
                    .then(r=>{
                        setShow(false)
                        if (setManifests)
                        {
                            setManifests(r.data.reverse())
                        }
                        setDescription('')
                        setRelease('')
                        setScheduledDate(new Date(timeNow).toLocaleTimeString([],DATE_OPTIONS))
                        setCreating(false)
                        if(homepage)
                        {
                            navigate('/releases')
                        }
                    })
                    // })
                //     GetAllDetails()
                //     .then(res=>{
                //         setManifestDetails(res)
                //     })
                // })
            })
        })
        .catch(err=>{
            setCreating(false)
        })
    }

    return (
        <Dialog open={show} onClose={()=>{setShow(false)
            setDescription('')
            setRelease('')
            setScheduledDate(new Date(timeNow).toISOString().slice(0,16))}}
        >
            <DialogTitle>Create New Release</DialogTitle>
            <DialogContent>
                <Box component='form' onSubmit={(e)=>CreateManifest(e)} sx={{padding:'10px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Environment</InputLabel>
                                <Select value={env} size="small" label="Environment" variant='standard'
                                    onChange={(e)=>setEnv(e.target.value)}
                                >
                                    <MenuItem value="DEV">DEV</MenuItem>
                                    <MenuItem value="QA">QA</MenuItem>
                                    <MenuItem value="STAGE">STAGE</MenuItem>
                                    <MenuItem value="PROD">PROD</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Release Version" value={release}
                                variant='standard'
                                onChange={(e)=>setRelease(e.target.value)}
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Release Name" value={description}
                                variant='standard'
                                onChange={(e)=>setDescription(e.target.value)}
                                size="small"
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12}>
                                <Input label="Scheduled Date" size="small" variant="standard" fullWidth error={isNaN(Date.parse(scheduledDate))}
                                value={scheduledDate} type="datetime-local"
                                onChange={(e)=>setScheduledDate(e.target.value)}
                                />
                        </Grid>
                        <Grid item xs={12}>
                            {isNaN(Date.parse(scheduledDate)) || description.length < 1 ?
                            <button className={`${mode === 'dark' ? 'bg-neutral-700' : 'bg-neutral-200'} text-neutral-500 px-4 py-2 rounded-md shadow-sm`}
                            disabled
                            >
                                Create Release
                            </button>
                            :
                            <button className={`${mode === 'dark' ? 'bg-neutral-600' : 'bg-neutral-800'} text-white px-4 py-2 rounded-md shadow-sm hover:shadow-lg transition duration-400`}
                            type="submit"
                            >
                                { creating ? <>Creating Release...</> : <>Create Release</> }
                            </button>
                            }
                        </Grid>
                        {/* <Grid item xs={6} sx={{textAlign:'right'}}>
                            {slide !== 2 || !tour ?
                            <></>
                            :
                            <ConditionalWrapper
                                condition={slide === 2 && tour}
                                wrapper={children =>
                                    <Tooltip open={true} title={<TourNavigation slide={slide} setSlide={setSlide} />} placement="bottom" arrow
                                    >
                                        {children}
                                    </Tooltip>
                                }
                            >
                                <button className={`${mode === 'dark' ? 'bg-neutral-600 text-white' : 'border border-neutral-500'} ${description.length <= 1  ? 'opacity-50' : ' hover:shadow-lg'} px-4 py-2 rounded-md shadow-sm transition duration-400`}
                                    type="button"
                                    disabled={description.length <= 1}
                                    onClick={CreateExample}
                                >
                                    { creating ? <>Creating Release...</> : <>Create Example Release</> }
                                </button>
                            </ConditionalWrapper>
                            
                            }
                        </Grid> */}
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    )
}