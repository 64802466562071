import React, { useState } from "react";
import { Box, Typography, Collapse, Divider } from '@mui/material'
import {BsChevronDown, BsChevronUp } from 'react-icons/bs'
import DeploySteps from "./DeploySteps";
export default function DeployView({group, DATE_OPTIONS, colors, order, started, phase, ind, deployObj, loaded, candidates, IconArray, mode, slide, setSlide, tour, deployed, counter}) {
    const [view, setView] = useState(true)

    return (
        <Box>
            <div className={`flex items-center px-4 py-3 ${mode === 'dark' ? 'bg-neutral-800 hover:bg-neutral-700' : 'bg-slate-100 hover:bg-slate-200'} cursor-pointer`} onClick={()=>setView(!view)}>
                <div className="flex items-baseline">
                    <p className="text-sm">{group.label}</p>
                    <p className={`text-xs ${mode === 'dark' ? 'text-neutral-400' : 'text-neutral-500'}`}>&nbsp; candidates</p>
                </div>
            {view ? <BsChevronUp size={20} style={{marginRight:'10px', marginLeft:'auto'}} /> : <BsChevronDown size={20} style={{marginRight:'10px', marginLeft:'auto'}} />}
            </div>
            <Collapse in={view} sx={{width:'100%'}}>
                <div className="divide-y">
                    {candidates.map((can,index)=>
                        can.group === group.name.split('-')[0] ?
                            <DeploySteps DATE_OPTIONS={DATE_OPTIONS} colors={colors} order={order} can={can} deployObj={deployObj} loaded={loaded}
                                started={started} phase={phase} index={index} group={group} candidates={candidates} IconArray={IconArray}
                                ind={ind} mode={mode} slide={slide} setSlide={setSlide} tour={tour} deployed={deployed} counter={counter}
                            />
                        :
                            <></>
                    )}
                </div>
            </Collapse>
        </Box>
    )
}