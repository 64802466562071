import React, { useState, useEffect } from "react";
import { Paper, Box, Typography, Collapse, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material'
import { BsChevronDown, BsChevronUp, BsInfoCircle, BsPencil, BsTrash } from 'react-icons/bs'
import { HiOutlineGlobeAlt } from 'react-icons/hi'
import { SiKubernetes, SiPostgresql, SiRabbitmq, SiNginx, SiGradle, SiElastic, SiAlpinelinux } from "react-icons/si";
import axios from "axios";
import {Draggable, Droppable} from 'react-beautiful-dnd'
import CandidateView from "./CandidateView";
import { useAuth } from "../auth/AuthContext";

export default function GroupView({group, DATE_OPTIONS, colors, item, id, ind, manifestLength, getItemStyle, getListStyle, nodes,
    completed, candidates, IconArray, allowEdit, dragging, mode, username, mCandidates, mGroups, manifest, RefreshManifest}) 
{
    const [view, setView] = useState(true)
    const [deleteGroup, setDeleteGroup] = useState(false)
    const [showDelete, setShowDelete] = useState(null)
    const [editName, setEditName] = useState(false)
    const [rename, setRename] = useState(group.label ? group.label : group.name.split('-')[0])
    const {currentUser} = useAuth()
    async function FinalizeDelete() {
        const postObj = {
            candidate: {
                name: showDelete.name,
            },
            group: showDelete.group,
            phase: showDelete.phase
        }

        var token = await currentUser.getIdToken();

        axios.post(`/v2/manifests/${manifest.id}/delete`, postObj, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        })
        .then((res) => {
            RefreshManifest()
            setShowDelete(null)
        })
    }

    async function DeleteGroup() {
        const postObj = {
            name: group.name.split('-')[0]
        }

        var token = await currentUser.getIdToken();

        axios.post(`/v2/manifests/${manifest.id}/deleteGroup`, postObj, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(() => {
            RefreshManifest();
            setDeleteGroup(false);
        })
    }

    async function ChangeName() {
        const endGroup = nodes.find(el=>el.name === group.name.replace('-Start','-End'))
        if(!!endGroup)
        {
            const postObj = {
                start: group._key,
                end: endGroup._key,
                label: rename   
            }

            var token = await currentUser.getIdToken();

            axios.post(`/v2/manifests/${manifest.id}/edit/rename`, postObj, {
                headers: {
                  'authorization': `Bearer ${token}`
                }
            })
            .then(res=>{
                setEditName(false)
                RefreshManifest()
            })
            .catch(err=>{
                console.error(err.message)
            })
        }
    }
    return (
        <Box sx={{p:'10px 0 10px 0'}}>
            <Box sx={{display:'flex', alignitems:'center', justifyContent:'space-between'}}>
                <Box sx={{width:'max-content',paddingTop:"5px"}}>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <Box>
                            <Typography sx={{display:'flex', alignItems:'center'}}>
                                {group.label ? group.label : group.name.split('-')[0]}
                                {allowEdit ? 
                                <IconButton size="small" onClick={()=>setEditName(true)} sx={{ml:1}}>
                                    <BsPencil size={15}/>
                                </IconButton>
                                :
                                <></>
                                }
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {group.candidates?.length === 1 ?
                                    <>1 Candidate</>
                                :
                                    <>{group.candidates?.length} Candidates</>
                                }
                            </Typography>
                        </Box>
                        <IconButton size="small" onClick={()=>setView(!view)} sx={{ml:2}}>
                            {view ? <BsChevronUp size={20} /> : <BsChevronDown size={20} />}
                        </IconButton>
                    </Box>
                </Box>
                { ind === 0 ? 
                <></>
                :
                <button size="small" onClick={()=>setDeleteGroup(true)}
                    className={`border ${mode === 'dark' ? 'border-neutral-600' : 'border-neutral-300'} h-max py-1 px-2 shadow-sm rounded-md hover:bg-opacity-40 hover:text-red-500 hover:border-red-300 transition duration-300`}
                >
                        Delete {group.label ? group.label : group.name.split('-')[0]}    
                </button>
                }
            </Box>
            <Collapse in={view} sx={{width:'100%', mt:1}}>
                <Paper variant="outlined">
                    <Droppable droppableId={id+','+ind} key={item.name+','+group.name}>
                    {(provided, snapshot)=>(
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                        >
                            <div className={`w-full grid grid-cols-12 border-b-2 ${mode === 'dark' ? 'border-neutral-600 bg-zinc-900' : ''} text-sm`}>
                                    <div className="p-1 text-left font-medium"></div>
                                    <div className="p-1 text-left font-medium col-span-2">Name</div>
                                    {/* <div className="p-1 text-left font-medium col-span-2">Type</div>
                                    <div className="p-1 text-left font-medium col-span-2">Description</div> */}
                                    <div className="p-1 text-left font-medium col-span-6">Artifact Repo</div>
                                    <div className="p-1 text-left font-medium col-span-2">Date Added</div>
                                    <div className="p-1 text-left font-medium">Actions</div>
                            </div>
                            { candidates.length > 0 && candidates[0].name ?
                                <div className={`w-full divide-y ${mode === 'dark' ? 'divide-neutral-600' : ''}`}>
                                    {
                                        mCandidates.map((can,index)=>
                                            can.group === group.name.split('-')[0] ?
                                            <CandidateView can={can} index={index} ind={ind} id={id} completed={completed} IconArray={IconArray} setShowDelete={setShowDelete}
                                                candidates={candidates} DATE_OPTIONS={DATE_OPTIONS} getItemStyle={getItemStyle} dragging={dragging} mode={mode}
                                                allowEdit={allowEdit}
                                            />
                                            :
                                            <></>
                                    )}
                                </div>
                            :
                                <></>
                            }
                            {provided.placeholder}
                        </div>  
                        )}
                    </Droppable>
                </Paper>
            </Collapse>
            <Dialog open={deleteGroup} onClose={()=>setDeleteGroup(false)}>
                <DialogTitle>Delete Group</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete {group.label ? group.label : group.name.split('-')[0]}?<br />All candidates in this group will be deleted.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={DeleteGroup}
                        className={` border border-neutral-800 bg-neutral-800 text-white h-max py-1 px-4 shadow-sm rounded-md transition hover:bg-opacity-80 duration-300 hover:shadow-lg`}
                    >
                        Delete
                    </button>
                    <button onClick={()=>setDeleteGroup(false)}
                        className={` border border-neutral-800 h-max py-1 px-4 shadow-sm rounded-md transition duration-300 hover:shadow-lg`}
                    >
                        Cancel
                    </button>
                </DialogActions>
            </Dialog>
            <Dialog open={showDelete !== null} onClose={()=>setShowDelete(null)}>
                <DialogTitle>Delete Candidate</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this candidate?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={FinalizeDelete}
                        className={` border border-neutral-800 bg-neutral-800 text-white h-max py-1 px-4 shadow-sm rounded-md transition hover:bg-opacity-80 duration-300 hover:shadow-lg`}
                    >
                        Delete
                    </button>
                    <button onClick={()=>setShowDelete(null)}
                        className={` border border-neutral-800 h-max py-1 px-4 shadow-sm rounded-md transition duration-300 hover:shadow-lg`}
                    >
                        Cancel
                    </button>
                </DialogActions>
            </Dialog>
            <Dialog open={editName} onClose={()=>{setEditName(false)
            setRename(group.label ? group.label : group.name.split('-')[0])}}>
                <DialogTitle>Edit Group Name</DialogTitle>
                <DialogContent>
                    <TextField value={rename} onChange={(e)=>setRename(e.target.value)} size="small"/>
                </DialogContent>
                <DialogActions>
                    <button onClick={ChangeName}
                        className={` border border-neutral-800 bg-neutral-800 text-white h-max py-1 px-4 shadow-sm rounded-md transition hover:bg-opacity-80 duration-300 hover:shadow-lg`}
                    >
                        Rename
                    </button>
                    <button onClick={()=>setEditName(false)}
                        className={` border border-neutral-800 h-max py-1 px-4 shadow-sm rounded-md transition duration-300 hover:shadow-lg`}
                    >
                        Cancel
                    </button>
                </DialogActions>
            </Dialog>
            {/* <Dialog open={false} onClose={()=>{setShowDetails(null)
            setCanDetails(null)}}>
                <DialogTitle>Candidate Info</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {canDetails ?
                        <>{canDetails.map((item,id)=>
                            <div className="grid grid-cols-2">
                                <p>{item}:&nbsp;</p>
                                <p>{showDetails[item]}</p>
                            </div>    
                        )}</>
                        :
                        <></>
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className="border border-gray-400 rounded-md px-5 py-2 shadow-sm hover:bg-red-100 hover:text-red-500 hover:border-red-200 transition duration-200"
                        onClick={()=>{setShowDetails(null)
                        setCanDetails(null)}}
                    >Close</button>
                </DialogActions>
            </Dialog> */}
        </Box>
    )
}