import React, { useState, useEffect } from 'react'
import { BsCheckCircleFill, BsChevronDown, BsChevronUp, BsPlusCircleFill } from 'react-icons/bs'
import axios from 'axios'

export default function CandidateAdd ({item, num, allCan, setShowAdd, completed, IconArray, candidates, filteredCan, globalCandidate, user}) {
    const [show, setShow] = useState(false)
    const [filtered, setFiltered] = useState([])
    const [present, setPresent] = useState(false)

    // useEffect(()=>{
    //     if(item.packageType.includes('Builder'))
    //     {
    //         setFiltered([item])
    //     }
    //     else
    //     {
    //         const filterCan = [...candidates].filter((can,id)=>can.icon === item.icon)
    //         setFiltered(filterCan)
    //     }
    // },[filteredCan])

    useEffect(()=>{
        if (globalCandidate) {
            user.getIdToken().then((token) => {
                axios.get(`/v2/candidates/${item.name}`, {
                    headers: {
                      'authorization': `Bearer ${token}`
                    }
                })
                .then(res=>{
                    setFiltered(res.data)
                })
            })
        } else {
            user.getIdToken().then((token) => {
                axios.get(`/v2/user/catalog/${item.name}`, {
                    headers: {
                    'authorization': `Bearer ${token}`
                    }
                })
                .then(res=>{
                    setFiltered(res.data)
                })
            })
        }
    },[show])

    // useEffect(()=>{
    //     if(filtered.length > 0)
    //     {
    //         filtered.forEach((can,ind)=>allCan.includes(can.id) ? setPresent(true) : <></>)
    //     }
    // },[allCan, filtered])

    // useEffect(()=>{
    //     if(present && show)
    //     {
    //         setShow(false)
    //     }
    // },[present])

    return (
        <div>
            <div className={`${present ? 'bg-blue-400 bg-opacity-30': show ? '' : ''} grid grid-cols-12 items-center cursor-pointer hover:bg-violet-200 hover:bg-opacity-30`} onClick={()=>setShow(!show)}>
                <div className='py-1 px-2'>
                    <img src={IconArray[item.icon]} style={{height:22, width:22}}
                    />
                    {/* <img src={builder ?
                        item.artifacts[0].ProjectType === 'Node' ? IconArray[88] : item.artifacts[0].ProjectType === 'Go' ? IconArray[89]
                        : item.artifacts[0].ProjectType === 'Python' ? IconArray[90] : item.artifacts[0].ProjectType === 'C' ? IconArray[91]
                        : item.artifacts[0].ProjectType === 'Java' ? IconArray[92] : item.artifacts[0].ProjectType === 'Npm' ? IconArray[93] : IconArray[81]
                        : IconArray[item.icon]} style={{height:22, width:22}}
                    /> */}
                </div>
                <div className='py-1 px-2 col-span-9'>
                    {item.name}
                </div>
                <div className='py-1 px-2'>
                    <button className='py-1 px-2 text-gray-400'>
                        {show ? <BsChevronUp size={20} /> : <BsChevronDown size={20}/>}
                    </button>
                </div>
            </div>
            { show ? 
            <table className='w-full'>
                <thead className='bg-slate-200'>
                    <tr>
                        <th className='p-2 text-sm text-left font-medium'>Artifact ID</th>
                        <th className='p-2 text-sm text-left font-medium'>Version</th>
                        <th className='p-2 text-sm text-left font-medium'>Created At</th>
                        <th className='p-2 text-sm text-left font-medium'>Source Code Repo</th>
                        <th className='p-2 text-sm text-left font-medium'>Artifact Repo</th>
                    </tr>
                </thead>
                <tbody className='divide-y'>
                { filtered.map((filt,index)=>
                    <tr className='hover:bg-violet-200 hover:bg-opacity-40 cursor-pointer' onClick={()=>setShowAdd(filt)}>
                        <td className='p-2 text-sm'>{filt.hash}</td>
                        <td className='p-2 text-sm'>{filt.version}</td>
                        <td className='p-2 text-sm'>{new Date(filt.createdAt).toLocaleString()}</td>
                        <td className='p-2 text-sm'>{filt.sourceCodeRepo}</td>
                        <td className='p-2 text-sm'>{filt.artifactRepo}</td>
                    </tr>
                )}
                </tbody>
            </table>
            :
            <></>
            }
        </div>
        
    )
}