import React, { useState, useEffect, useMemo } from 'react'
import { createPortal } from "react-dom";
import { BsThreeDotsVertical } from 'react-icons/bs'
import { CiWavePulse1 } from 'react-icons/ci'
import { HiViewGrid, HiViewList } from 'react-icons/hi'
import { HiCircleStack, HiCommandLine, HiListBullet, HiOutlineCommandLine } from 'react-icons/hi2'
import { SiGithub } from 'react-icons/si'
import { Menu, MenuItem, Popover, setRef } from '@mui/material'
import EnvironmentCard from './EnvironmentCard'
import DeploymentLog from './DeploymentLog'
import axios from 'axios';

export default function Environments({ drawerWidth, setPage, colors, mode, loading, user, currentUser }) {
    const [list, setList] = useState(false)
    const [open, setOpen] = useState(null)
    const [logId, setLogId] = useState(null)
    const [search, setSearch] = useState('')
    const [sortedItems, setSortedItems] = useState([])
    const [deployed, setDeployed] = useState([])
    const [detach, setDetach] = useState(false)
    const [showLogs, setShowLogs] = useState(false)
    const [refreshCount, setRefreshCount] = useState(0)
    const [refreshing, setRefreshing] = useState(false)

    useEffect(()=>{
        if(!loading)
        {
            setPage('Environments')
            RefreshList()
            setRefreshing(true)
        }
    },[loading])

    useEffect(()=>{
        if(search !== '' && search.length > 1)
        {
            let sortedTemp = []
            deployed.forEach((item)=>{
                if(JSON.stringify(item).includes(search))
                {
                    sortedTemp.push(item)
                }
            })
            setSortedItems(sortedTemp)
        }
        else
        {
            setSortedItems([...deployed])
        }
    },[search, deployed])

    useEffect(()=>{
        setTimeout(()=>{
            RefreshList()
            setRefreshCount(refreshCount+1)
        },5000)
    },[refreshCount])

    // const NewWindow = ({children, close}) => {
    //     const newWindow = useMemo(() =>
    //         window.open(
    //         "about:blank",
    //         "newWin",
    //         `width=400,height=300,left=${window.screen.availWidth / 2 -
    //             200},top=${window.screen.availHeight / 2 - 150}`
    //         )
    //     );
    //     newWindow.onbeforeunload = () => {
    //       close();
    //     };
    //     useEffect(() => () => close());
    //     return createPortal(children, newWindow.document.body);
    // }

    async function RefreshList() {
        var token = await currentUser.getIdToken();
        axios.get(`/v2/deployed`, {
            headers: {
              'authorization': `Bearer ${token}`
            }
        }).then((res) => {
            setDeployed(res.data)
        }).catch(err => setRefreshing(false))
    }


    return (
        <div
        onClick={()=>setOpen(null)}
        >
            <div className={`pt-24 w-5/6 2xl:w-3/4 ${drawerWidth < 240 ? 'mx-auto' : 'ml-[280px]'} min-h-[100vh]`}
            >
                <div className='flex items-center justify-between mb-4 px-2'>
                    <input placeholder='Search Deployments' value={search} onChange={(e)=>setSearch(e.target.value)}
                        className='border rounded-md px-3 py-2 bg-white'
                    />
                    <div className='flex gap-4'>
                        {/* <select className='px-4 py-2 border rounded-md'>
                            <option>Activity</option>
                            <option>Project</option>
                            <option>User</option>
                        </select> */}
                        <div className='flex px-4 py-0.5 rounded-md gap-2 items-center'>
                            {refreshing ? 
                            <>
                                <div className='w-[10px] h-[10px] rounded-full bg-green-500 animate-pulse' />
                                <p className='text-green-500'>Connected</p>
                            </>
                            : 
                            <>
                                <div className='w-[10px] h-[10px] rounded-full bg-red-500' />
                                <p className='text-red-500'>Disconnected</p>
                            </>
                            }
                        </div>
                        <div className='flex bg-white px-1 py-0.5 border rounded-md gap-2'
                            onClick={()=>setList(!list)}
                        >
                            <button className={`${list ? 'bg-gray-100 text-slate-800' : 'text-neutral-500'} p-2 rounded-md`}><HiListBullet size={20} /></button>
                            <button className={`${list ? 'text-neutral-500' : 'bg-gray-100 text-slate-800'} p-2 rounded-md`}><HiViewGrid size={20} /></button>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-8'>
                    <ul className={`grid grid-cols-2 2xl:grid-cols-3 items-stretch gap-2 2xl:gap-4 ${logId !== null && !detach && showLogs ? 'max-h-[24vh] overflow-y-auto' : ''} p-2`}>
                        { sortedItems.map((item, id)=>
                            <EnvironmentCard item={item} open={open} setOpen={setOpen} id={id} setLogId={setLogId} logId={logId} setDetach={setDetach}
                                showLogs={showLogs} setShowLogs={setShowLogs} RefreshList={RefreshList} currentUser={currentUser}            
                            />
                        )}
                    </ul>
                    { logId !== null && showLogs ?
                        <DeploymentLog title={sortedItems[logId].deploymentName} started={true} handleClose={()=>setLogId(null)} setDetach={setDetach}
                            envPage={true} candidate={sortedItems[logId]}
                        />
                    :
                        <></>
                    }
                </div>
            </div>
        </div>
    )
}