import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ResponsiveBar } from '@nivo/bar'
import { BsArrowClockwise, BsArrowRepeat, BsCheck2Circle, BsCheckCircle, BsChevronLeft, BsChevronRight, BsCircleFill, BsDashCircle, BsDashCircleFill, BsFileBinary, BsInfo, BsInfoCircle, BsInfoSquare, BsPerson, BsPersonBadge, BsPlayCircle, BsPlayCircleFill, BsPlusCircle, BsPlusCircleFill } from 'react-icons/bs'
import { HiOutlineShare } from 'react-icons/hi2'
import { Dialog, IconButton } from '@mui/material'
import { useNavigate } from 'react-router'
import { useAuth } from '../../auth/AuthContext'

export default function Audits({mode, loading, colors, setPage, drawerWidth, setCurrent}) {
    const [deploymentHistory, setDeploymentHistory] = useState([])
    const [deployments, setDeployments] = useState([])
    const [manifests, setManifests] = useState([])
    const [dateRange, setDateRange] = useState([])
    const [latest, setLatest] = useState(null)
    const [candidates, setCandidates] = useState([])
    const [candidatesGraph, setCandidatesGraph] = useState([])
    const [paging, setPaging] = useState(0)
    const [limit, setLimit] = useState(100)
    const [manifestFilter, setManifestFilter] = useState([])
    const [showManifests, setShowManifests] = useState('All')
    const [filterAudit, setFilterAudit] = useState('All')
    const [auditLog, setAuditLog] = useState([])
    const [infoDialog, setInfoDialog] = useState(null)
    const [loadingResults, setLoadingResults] = useState(true)
    const {currentUser} = useAuth()
    const dayNow = new Date().getTime()
    const dayStart = dayNow-5097600000
    const navigate = useNavigate()
    useEffect(()=>{
        if(!loading)
        {
            let days = []
            for (let  i = 0; i < 60; i++)
            {
                days.push(new Date(dayStart+(i*86400000)).toLocaleDateString().replaceAll('/','-').split('-2023')[0])
            }
            setDateRange(days)
            setPage('Audits')
        }
    },[loading])

    useEffect(()=>{
        if(manifests.length > 0)
        {
            let daystemp = []
            manifests.forEach((item,id)=>daystemp.push(new Date(item.scheduledTimeStamp).toLocaleDateString().replaceAll('/','-').split('-2023')[0]))
            let deploymentstemp = []
            let deploymenthistemp = []
            let canTemp = []
            const uniqueDays = Array.from(new Set(daystemp))
            dateRange.forEach((date,ind)=>{
                    if (uniqueDays.includes(date))
                    {
                        const graphValue = getAllIndex(daystemp, date)
                        deploymenthistemp.push({
                            "day": date,
                            "value": graphValue
                        })
                    }
                    else
                    {
                        deploymenthistemp.push({
                            "day": date,
                            "value": 0
                        })
                    }
            })
            manifests.forEach((man,id)=>{
                const deploymentDate = new Date(man.scheduledTimeStamp).toLocaleDateString().replaceAll('/','-').split('-2023')[0]
                if(Date.parse(deploymentDate) >= Date.parse(dateRange[0]))
                if (uniqueDays.includes(deploymentDate))
                {
                    canTemp.push({
                        "day": deploymentDate,
                        "value": man?.candidates ? man.candidates : 0
                    })
                }
                else
                {
                    canTemp.push({
                        "day": deploymentDate,
                        "value": 0
                    })
                }
            })
            setCandidates(canTemp)
            deploymenthistemp.forEach((day,value)=>{
                if(day.value > 0)
                {
                    deploymentstemp.push({
                        "day": day.day,
                        "value": day.value
                    })
                }
            })
            setDeployments(deploymentstemp)
            setDeploymentHistory(Array.from(new Set(deploymenthistemp)))
            setLatest(manifests[manifests.length-1])
        }
    },[manifests])

    useEffect(()=>{
        if(candidates.length > 0)
        {
            let canGraph = []
            candidates.forEach((item,id)=>{
                let arrIndex = -1
                canGraph.forEach((can,i)=>can.day === item.day ? arrIndex = i : <></>)
                if(arrIndex >= 0)
                {
                    canGraph[arrIndex].value += item.value
                }
                else
                {
                    canGraph.push(item)
                }
            })
            setCandidatesGraph(canGraph)
        }
    },[candidates])

    useEffect(()=>{
        setLoadingResults(true)
        currentUser.getIdToken().then((token) => {
            axios.get('v2/audit', {params:{page:paging, limit:limit}, headers: {
                'authorization': `Bearer ${token}`
            }})
            .then(res=>{
                const avManifests = []
                res.data.forEach((item)=>{
                    if (item.manifest !== undefined)
                    {
                        avManifests.push(item.manifest)
                    }
                })
                setLoadingResults(false)
                if(manifestFilter.length === 0)
                {
                    const uniqManifests = Array.from(new Set(avManifests))
                    setManifestFilter(uniqManifests)
                }
                setAuditLog(res.data)
            })
        })
    },[paging, limit])
    
    function getAllIndex(arr, val) {
        var indexes = [], i;
        for(i = 0; i < arr.length; i++)
            if (arr[i] === val)
                indexes.push(i);
        return indexes.length
    }

    function NavigateToManifest(id) {
        if(!!id)
        {
            sessionStorage.setItem('current', id)
            setCurrent(id)
            navigate(`/release/${id}`)
        }
    }

    return (
        <div className={`pt-16 ${drawerWidth < 240 ? 'mx-auto w-5/6' : 'ml-[280px] w-3/4'} min-h-[100vh] pb-8`}>
            <div className='mt-6'>
                <div className={`border px-2 py-2 rounded-md ${mode === 'dark' ? 'bg-neutral-800 border-gray-600' : 'bg-white'} w-full`}>
                    <div className='flex justify-between items-baseline mb-2'>
                        <p className='font-semibold text-lg block w-max pl-2 py-2'>Audit Trail</p>
                        <div id='filters' className='flex gap-3 items-center'>
                            { showManifests !== 'All' || filterAudit !== 'All' ?
                                <button className='border px-2 py-1 border-neutral-500 rounded-md h-max hover:bg-neutral-200 transition duration-300 hover:border-white hover:text-red-500'
                                    onClick={()=>{setFilterAudit('All');setShowManifests('All'); setPaging(0);setLimit(100)}}
                                >Clear Filters</button>
                                :
                                <></>
                            }
                            <div className='flex flex-col gap-1 items-center'>
                                <div className='flex items-center gap-4'>
                                    { paging > 0 ?
                                        <button onClick={()=>setPaging(paging-1)}><BsChevronLeft size={15} /></button>
                                    :
                                        <button className='text-gray-300'><BsChevronLeft size={15} /></button>  
                                    }
                                    <div>{paging+1}</div>
                                    <button onClick={()=>setPaging(paging+1)}><BsChevronRight size={15}  /></button>
                                </div>
                                <p className={`text-xs ${mode === 'dark' ? 'text-neutral-300' : 'text-neutral-500'}`}>Page</p>
                            </div>
                            <div className='flex flex-col gap-1'>
                                <select className={`px-1 py-0.5 border border-gray-300 shadow-sm rounded-md ${mode === 'dark' ? 'bg-neutral-700' : ''}`}
                                    value={limit} onChange={(e)=>setLimit(e.target.value)}
                                >
                                    <option className='p-1' value={10}>10</option>
                                    <option className='p-1' value={100}>100</option>
                                    <option className='p-1' value={1000}>1000</option>
                                    <option className='p-1' value='All'>All</option>
                                </select>
                                <p className={`text-xs ${mode === 'dark' ? 'text-neutral-300' : 'text-neutral-500'}`}>Results per Page</p>
                            </div>
                            <div className='flex flex-col gap-1'>
                                <select className={`px-1 py-0.5 border border-gray-300 shadow-sm rounded-md ${mode === 'dark' ? 'bg-neutral-700' : ''}`}
                                >
                                    <option className='p-1' value='All'>All</option>
                                    <option className='p-1' value='capture-demo'>capture-demo</option>
                                </select>
                                <p className={`text-xs ${mode === 'dark' ? 'text-neutral-300' : 'text-neutral-500'}`}>Filter by User</p>
                            </div>
                            <div className='flex flex-col gap-1'>
                                <select className={`px-1 py-0.5 border border-gray-300 shadow-sm rounded-md ${mode === 'dark' ? 'bg-neutral-700' : ''}`}
                                    onChange={(e)=>setShowManifests(e.target.value)}
                                    value={showManifests}
                                >
                                    <option className='p-1' value='All'>All</option>
                                    { manifestFilter.map((item,id)=>
                                        <option className='p-1' value={item} key={id}>{item}</option>
                                    )}
                                </select>
                                <p className={`text-xs ${mode === 'dark' ? 'text-neutral-300' : 'text-neutral-500'}`}>Filter by Manifest</p>
                            </div>
                            <div className='flex flex-col gap-1'>
                                <select className={`px-1 py-0.5 border border-gray-300 shadow-sm rounded-md ${mode === 'dark' ? 'bg-neutral-700' : ''}`}
                                    onChange={(e)=>setFilterAudit(e.target.value)}
                                >
                                    <option className='p-1' value='All'>All</option>
                                    <option className='p-1' value='Candidate Added'>Candidate Added</option>
                                    <option className='p-1' value='Candidate Deleted'>Candidate Deleted</option>
                                    <option className='p-1' value='Deployment Started'>Deployment Started</option>
                                    <option className='p-1' value='Deployment Finished'>Deployment Finished</option>
                                    <option className='p-1' value='Login'>Login</option>
                                    <option className='p-1' value='Manifest Edit'>Manifest Edit</option>
                                    <option className='p-1' value='Manifest Created'>Manifest Created</option>
                                    <option className='p-1' value='Phase Done'>Phase Done</option>
                                    <option className='p-1' value='Phase Done'>Pod Restarted</option>
                                    <option className='p-1' value='Phase Done'>Pod Deleted</option>
                                    <option className='p-1' value='Phase Done'>Check Logs</option>
                                </select>
                                <p className={`text-xs ${mode === 'dark' ? 'text-neutral-300' : 'text-neutral-500'}`}>Filter by Type</p>
                            </div>
                        </div>
                    </div>
                    <div className=' max-h-[75vh] overflow-y-auto'>
                        { loadingResults ? 
                            <div className='w-full flex flex-col gap-2'>
                                <div className='rounded-md animate-pulse w-full h-[30px] bg-neutral-200' />
                                <div className='rounded-md animate-pulse w-1/2 h-[30px] bg-neutral-200' />
                                <div className='rounded-md animate-pulse w-1/2 h-[30px] bg-neutral-200' />
                                <div className='rounded-md animate-pulse w-1/2 h-[30px] bg-neutral-200' />
                                <div className='rounded-md animate-pulse w-1/4 h-[30px] bg-neutral-200' />
                                <div className='rounded-md animate-pulse w-1/2 h-[30px] bg-neutral-200' />
                                <div className='rounded-md animate-pulse w-3/4 h-[30px] bg-neutral-200' />
                                <div className='rounded-md animate-pulse w-1/2 h-[30px] bg-neutral-200' />
                                <div className='rounded-md animate-pulse w-1/2 h-[30px] bg-neutral-200' />
                            </div>
                        :
                            <table className={`w-full border rounded-md ${mode==='dark' ? 'border-gray-600' :''}`}>
                                <thead className={`border-b-2 ${mode === 'dark' ? 'border-b-gray-600' : ''}`}>
                                    <th className='text-left py-2 px-2'>Date/Time</th>
                                    <th className='text-left py-2 px-2'>Manifest</th>
                                    <th className='text-left py-2 px-2'>User</th>
                                    <th className='text-left py-2 px-2'>Action</th>
                                    <th className='text-left py-2 px-2'>Details</th>
                                    <th className='text-left py-2 px-2'>Organization</th>
                                </thead>
                                
                                <tbody className={`divide-y ${mode === 'dark' ? 'divide-gray-600' : ''}`}>
                                    { auditLog.map((item,id)=>{
                                            const ItemIcon = item.type === 'Candidate Added' ? <BsPlusCircleFill size={18} /> : item.type === 'Manifest Created' ? <BsFileBinary size={18} />
                                                : item.type === 'Deployment Started' ? <BsPlayCircle size={18} /> : item.type === 'Candidate Deleted' ? <BsDashCircleFill size={18} />
                                                : item.type === 'Login' ? <BsPerson size={18} /> : item.type === 'Deployment Finished' ? <BsPlayCircleFill size={18} />
                                                : item.type === 'Phase Done' ? <BsCheckCircle size={18} /> : <BsArrowRepeat size={18} />
                                            if(filterAudit === 'All' || item.type === filterAudit)
                                            {
                                                return (
                                                    <>
                                                    { showManifests === 'All' || item?.manifest === showManifests ?
                                                        <tr className='hover:shadow-md cursor-pointer py-1 transition duration-200 text-sm'
                                                            onClick={()=>NavigateToManifest(item?.manifest)}
                                                        >
                                                            <td className='px-2 py-3 align-top'>{item.time.replace('T',' ').split('.')[0]}</td>
                                                            <td className='px-2 py-3 align-top'>{item?.manifest}</td>
                                                            <td className='px-2 py-3 align-top'>{item.username}</td>
                                                            <td className={`px-2 py-3 flex gap-3 items-center align-top`}
                                                            >
                                                                <span
                                                                className={`${item.type === 'Deployment Started' || item.type === 'Deployment Finished'  ? 'text-green-500' : item.type === 'Candidate Added' ? 'text-blue-500' : item.type === 'Candidate Deleted' ? 'text-red-500' : ''}`}
                                                                >{ItemIcon}</span>
                                                                <span>{item.type}</span>
                                                            </td>
                                                            <td className='px-2 py-3 align-top'>{item.event}</td>
                                                            <td className='px-2 py-3 align-top'>{item?.email || 'AuditDeploy'}</td>
                                                            <td className='px-2 py-3 align-top'><IconButton size="small" onClick={(e)=>{e.stopPropagation();setInfoDialog(item)}}><BsInfoCircle /></IconButton></td>
                                                        </tr>
                                                    :
                                                        <></>
                                                    }
                                                    </>
                                                )
                                            }
                                        })
                                    }   
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
                {/* <div className='col-span-6'>
                    <div className={`w-full h-max rounded-md border ${mode === 'dark' ? 'bg-neutral-800' : 'bg-white'} pt-2 mb-2`}>
                        <p className='font-semibold w-max mx-auto'>Deployment Frequency</p>
                        <div className='w-full h-[20vh]'>
                            <ResponsiveBar
                                data={deployments}
                                keys={[
                                    'value'
                                ]}
                                indexBy="day"
                                margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
                                padding={0.3}
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: true }}
                                colors={[  '#53abff', '#1976d2' ]}
                                borderColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            1.6
                                        ]
                                    ]
                                }}
                                enableGridY={false}
                                axisTop={null}
                                axisRight={null}
                                isInteractive={false}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Date',
                                    legendPosition: 'middle',
                                    legendOffset: 32
                                }}
                                axisLeft={{
                                    legend: 'Deployments',
                                    legendPosition: 'middle',
                                    legendOffset: -40,
                                    tickValues: [0,1,2,3,4,5,6,7,8,9,10]
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                theme={{
                                    textColor: colors[5],
                                    }}
                                role="application"
                            />
                        </div>
                    </div>
                    <div className={`w-full h-max rounded-md border ${mode === 'dark' ? 'bg-neutral-800' : 'bg-white'} pt-2`}>
                        <p className='font-semibold w-max mx-auto'>Candidates Deployed</p>
                        { candidatesGraph.length > 0 ?
                        <div className='w-full h-[24vh]'>
                            <ResponsiveBar
                                data={candidatesGraph}
                                keys={[
                                    'value'
                                ]}
                                indexBy="day"
                                margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
                                padding={0.3}
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: true }}
                                colors={[  '#53abff', '#1976d2' ]}
                                borderColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            1.6
                                        ]
                                    ]
                                }}
                                enableGridY={false}
                                axisTop={null}
                                axisRight={null}
                                isInteractive={false}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Date',
                                    legendPosition: 'middle',
                                    legendOffset: 32
                                }}
                                axisLeft={{
                                    legend: 'Candidates',
                                    legendPosition: 'middle',
                                    legendOffset: -40
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                theme={{
                                    textColor: colors[5],
                                    }}
                                role="application"
                            />
                        </div>
                        :
                        <></>
                        }
                    </div>
                </div> */}
            </div>
            <Dialog open={infoDialog !== null} onClose={()=>setInfoDialog(null)} maxWidth="md" fullWidth>
                <div className='px-4 py-3'>
                    <table className={`w-full`}>
                            <thead className={`border-b-2`}>
                                <th className='text-left py-2 px-2'>Date/Time</th>
                                <th className='text-left py-2 px-2'>Time</th>
                                <th className='text-left py-2 px-2'>User</th>
                                <th className='text-left py-2 px-2'>Action</th>
                                <th className='text-left py-2 px-2'>Details</th>
                                <th className='text-left py-2 px-2'>Organization</th>
                            </thead>
                            <tbody className={`divide-y ${mode === 'dark' ? 'divide-gray-600' : ''}`}>
                                {
                                    <tr className='hover:shadow-md cursor-pointer py-1 transition duration-200 text-sm'>
                                        <td className='px-2 py-3'>{infoDialog?.time.split('T')[0]}</td>
                                        <td className='px-2 py-3'>{infoDialog?.time.split('T')[1].split('.')[0]}</td>
                                        <td className='px-2 py-3'>{infoDialog?.username}</td>
                                        <td className={`px-2 py-3 flex gap-3 items-center`}
                                        >
                                            <span>{infoDialog?.type}</span>
                                        </td>
                                        <td className='px-2 py-3'>{infoDialog?.event}</td>
                                        <td className='px-2 py-3'>{infoDialog?.email || 'AuditDeploy'}</td>
                                    </tr>
                                }   
                            </tbody>
                        </table>
                        <p className='mt-2 font-semibold'>Additional Details</p>
                        <hr className='mb-2 my-1' />
                        {/* <p className={`text-xs ${mode === 'dark' ? 'text-neutral-300' : 'text-neutral-500'}`}>Manifest</p> */}
                        {/* <p>{infoDialog?.manifest}</p> */}
                </div>
            </Dialog>
        </div>
        
    )

}