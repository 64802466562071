import { Tooltip, Dialog, Box } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { BsChevronRight, BsThreeDotsVertical, BsX } from 'react-icons/bs'
import { HiLink } from 'react-icons/hi'
import { HiCircleStack, HiOutlineCommandLine } from 'react-icons/hi2'
import axios from 'axios'
import deployed from '../deployed.svg'

export default function Environments({ item, open, setOpen, id, logId, setLogId, setDetach, showLogs, setShowLogs, RefreshList, currentUser }) {
    const [warning, setWarning] = useState(null)
    const [message, setMessage] = useState(null)
    const [showDetails, setShowDetails] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [restarting, setRestarting] = useState(false)

    function DisplayLogs() {
        if(logId === id)
        {
            setLogId(null)
        }
        else
        {
            setLogId(id)
            setShowLogs(true)
        }
    }

    function Delete() {
        setDeleting(false)
        axios.post(`/v2/delete-deployment/namespace/${item.belongsTo}/deployment/${item.deploymentName}`,
            {
                "serviceName": item.serviceName,
                "nginxFilePath": item.nginxFilePath,
                "deployedKey": item._key
            }
        )
        .then((res) => {
            setMessage(`${item.deploymentName} deleted successfully.`)
            const auditObj = {
                username: currentUser.displayName,
                email: currentUser?.email,
                uid: currentUser?.uid,
                event: `${item.candidateName} in ${item.manifestDescription} terminated by ${currentUser.displayName}`,
                time: new Date(),
                manifest: item.manifestId,
                type: "Pod Deleted"
            }
            
            currentUser.getIdToken().then((token) => {
                axios.post('/v2/audit', auditObj, {
                    headers: {
                    'authorization': `Bearer ${token}`
                    }
                })
            })

            RefreshList()
        })
        .catch(err=>{
            console.error(err.message)
            setWarning(`Failed to delete ${item.deploymentName}. Please try again later.`)
        })
    }

    function Restart() {
        setRestarting(false)
        axios.post(`/v2/restart-pod/namespace/${item.belongsTo}/deployment/${item.deploymentName}`,
            {
                "deployedKey": item._key
            }
        )
        .then((res) => {
            setMessage(`${item.deploymentName} restarted successfully.`)
            const auditObj = {
                username: currentUser.displayName,
                email: currentUser?.email,
                uid: currentUser?.uid,
                event: `${item.candidateName} in ${item.manifestDescription} restarted by ${currentUser.displayName}`,
                time: new Date(),
                manifest: item.manifestId,
                type: "Pod Restarted"
            }
            currentUser.getIdToken().then((token) => {
                axios.post('/v2/audit', auditObj, {
                    headers: {
                    'authorization': `Bearer ${token}`
                    }
                })
            })
        })
        .catch(err=>{
            console.error(err.message)
            setWarning(`Failed to restart ${item.deploymentName}. Please try again later.`)
        })
    }

    const deployedAt = parseInt((new Date().getTime() - item.deployedTimestamp)/1000/60)
    let displayTime = ''
    if (deployedAt >= 1440)
    {
        displayTime = `${Math.floor(deployedAt / 60 / 24)}d`
    }
    else if (deployedAt >= 60)
    {
        displayTime = `${Math.round(deployedAt /60)}h`
    }
    else
    {
        displayTime = `${Math.floor(deployedAt)}m`
    }

    return (
        <li className={`hover:shadow-md rounded-md p-4 border bg-white flex flex-col gap-8 transition duration-300`}>
            <div className='flex items-center justify-between gap-8'>
                <div className='flex items-center gap-6'>
                    <img src={deployed} className='h-[30px] w-[30px]' />
                    <div>
                        <b>{item.deploymentName}</b>
                        <p className='text-sm text-neutral-500'>{item.podName}</p>
                    </div>
                </div>
                <div className='flex items-center gap-2 justify-end relative'>
                    <Tooltip title="Show logs" placement='bottom'>
                        <button className={`${logId === id && showLogs ? 'shadow-[0_0_3px_2px_#1976d288] border-neutral-600 bg-neutral-600 text-neutral-100' : 'hover:bg-gray-100'} transition duration-300 border rounded-full p-1.5`}
                            onClick={(e)=>{e.stopPropagation();DisplayLogs();setDetach(false)}}
                        >
                            <HiOutlineCommandLine size={22} />
                        </button>
                    </Tooltip>
                    <Tooltip title="More options" placement='bottom'>
                        <button className='hover:bg-gray-100 transition duration-300 rounded-full p-1'
                            onClick={(e)=>{setOpen(id);e.stopPropagation()}}
                        >
                            <BsThreeDotsVertical />
                        </button>
                    </Tooltip>
                    { open === id ? 
                        <div className='absolute bg-white rounded-md border right-[-0.5vw] top-0 z-10 shadow-md w-max animate-fadeInFast flex flex-col'
                        >
                            { item.liveUrl ? 
                                Array.isArray(item.liveUrl) ?
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        {item.liveUrl.map((live, index) => {
                                            if(live.name === 'http') {
                                                return <a href={live.url} key={index} target='_blank' className='p-2 hover:bg-gray-100 flex gap-2 items-center cursor-pointer truncate'>Live URL <HiLink /></a>
                                            }
                                        })}
                                    </Box>
                                :
                                <a href={item.liveUrl} target='_blank' className='p-2 hover:bg-gray-100 flex gap-2 items-center cursor-pointer truncate'>Live URL <HiLink /></a> : <></>
                            }
                            <button className='p-2 hover:bg-gray-100 w-full text-left'
                                onClick={()=>setShowDetails(true)}
                            >Details</button>
                            <button className='p-2 hover:bg-gray-100 w-full text-left'
                                onClick={()=>setRestarting(true)}
                            >Restart Pod</button>
                            <button className='p-2 hover:bg-gray-100 text-red-500 w-full text-left'
                                onClick={()=>setDeleting(true)}
                            >Delete</button>
                        </div>
                    :
                        <></>
                    }
                </div>
            </div>
            <div>
                { item.liveUrl ? 
                    Array.isArray(item.liveUrl) ?
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            {item.liveUrl.map((live, index) => {
                                return <a href={live.url} key={index} target='_blank' className='text-sm flex items-center gap-2 text-blue-500 underline cursor-pointer truncate'>{live.url}</a>
                            })}
                        </Box>
                    :
                        <a href={item.liveUrl} target='_blank' className='text-sm flex items-center gap-2 text-blue-500 underline cursor-pointer truncate'>{item.liveUrl}</a> : <></>
                }
                <div className='flex justify-between text-sm text-neutral-600'>
                    <p>Deployed&nbsp;{displayTime}&nbsp;ago</p>
                </div>
            </div>
            <Dialog open={restarting} onClose={()=>setRestarting(false)} maxWidth="sm">
                <div className='p-4 text-center'>
                    <h3 className='font-semibold text-lg'>Are you sure you want to restart {item.deploymentName}?</h3>
                    <p className='my-4'>The deployment will be unavailable while restarting.</p>
                    <div className='flex justify-between mt-4'>
                        <button onClick={()=>setRestarting(false)}
                            className={` border border-neutral-800 h-max py-1 px-4 shadow-sm rounded-md transition duration-300 hover:shadow-lg`}
                        >
                            Cancel
                        </button>
                        <button onClick={Restart}
                            className={` border border-neutral-800 bg-neutral-800 text-white h-max py-1 px-4 shadow-sm rounded-md transition hover:bg-opacity-80 duration-300 hover:shadow-lg`}
                        >
                            Restart
                        </button>
                    </div>
                </div>
            </Dialog>
            <Dialog open={deleting} onClose={()=>setDeleting(false)} maxWidth="sm">
                <div className='p-4 text-center'>
                    <h3 className='font-semibold text-lg'>Are you sure you want to delete {item.deploymentName}?</h3>
                    <p className='my-4'>This action is permanent and cannot be undone.</p>
                    <div className='flex justify-between mt-4'>
                        <button onClick={()=>setDeleting(false)}
                            className={` border border-neutral-800 h-max py-1 px-4 shadow-sm rounded-md transition duration-300 hover:shadow-lg`}
                        >
                            Cancel
                        </button>
                        <button onClick={Delete}
                            className={` border border-red-500 text-red-500 h-max py-1 px-4 shadow-sm rounded-md transition hover:bg-opacity-80 duration-300 hover:shadow-lg`}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </Dialog>
            <Dialog open={showDetails} onClose={()=>setShowDetails(false)} maxWidth="lg">
                <div className='p-4 relative'>
                    <button className='absolute p-2 bg-gray-300 top-2 right-2 rounded-full hover:bg-gray-400'
                        onClick={()=>setShowDetails(false)}
                    >
                        <BsX size={20}/>
                    </button>
                    <h3 className='font-semibold text-lg text-center mb-4'>{item.deploymentName}</h3>
                    <div className='grid grid-cols-2 gap-2 text-left'>
                        <b>Release Name:</b>
                        <p>{item.manifestDescription}</p>
                        <b>Deployed On:</b>
                        <p>{new Date(item.deployedTimestamp).toString()}</p>
                        <b>Candidate Name:</b>
                        <p>{item.candidateName}</p>
                        <b>Service Name:</b>
                        <p>{item.serviceName}</p>
                        <b>Pod Name:</b>
                        <p>{item.podName}</p>
                        { item.internalUrl ? 
                            Array.isArray(item.internalUrl) && item.internalUrl.length !== 0 ?
                            item.internalUrl.map((internal, index) => {
                                return <>
                                <b>Internal URL {`(${internal.name})`}:</b>
                                <p key={index}>{internal.url}</p>
                                </>
                            })
                            :
                                <p>{item.internalUrl}</p> : <></>
                        }
                    </div>
                </div>
            </Dialog>
        </li>
    )
}
