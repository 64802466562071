import React, { useState, useEffect } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { BsInfoCircle, BsTrash } from 'react-icons/bs'

export default function CandidateView({can, index, ind, id, completed, IconArray, candidates, DATE_OPTIONS, setShowDelete, getItemStyle, dragging, mode, allowEdit}) {
    const [showDetails, setShowDetails] = useState(null)
    const [canDetails, setCanDetails] = useState(null)
    const ConditionalWrapper = ({condition, wrapper, children }) =>
        condition ? wrapper(children) : children
    
    useEffect(()=>{
        if(showDetails)
        {
            const objKeys = Object.keys(showDetails)
            const displayKeys = objKeys.filter((term) => term !== 'id' && term !== 'icon' && term !== 'user' && term !== 'key'  && term !== 'majorVersion' && term !== 'lastReleaseDate' && term !== 'lastReleaseNotes' && term !== 'type' && term !== 'logs' && term !== 'envs' && term !== 'dependsOnCandidates')
            setCanDetails(displayKeys)
        }
    },[showDetails])

    useEffect(()=>{
        setShowDetails(null)
        setCanDetails(null)
    },[dragging])

    function DeleteCandidate(e, can) {
        e.stopPropagation()
        setShowDelete(can)
    }

    return (
        <>
            <ConditionalWrapper
                condition={!completed}
                wrapper={children =>
                    <Draggable key={`${index}${id}-${can.name}`} draggableId={`${index}${id}-${can._id}`} index={index}
                        isDragDisabled={!allowEdit}
                    >
                        {(provided, snapshot)=>(
                            <div key={can.candidateIcon}
                                ref={provided.innerRef}
                                style={getItemStyle(snapshot.isDragging,
                                    provided.draggableProps.style)}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                            {children}
                        </div>)
                        }
                    </Draggable>
                }
            >
            <div className={`group grid grid-cols-12 hover:bg-blue-200 hover:bg-opacity-20 transition duration-200 items-center ${mode === 'dark' ? 'text-neutral-200 bg-zinc-900' : 'text-neutral-600'}`}>
                <div className="p-1 text-sm" align='center'>
                    <img src={can?.builderType ? can.builderType === 'Node' ? IconArray[88] : can.builderType === 'Go' ? IconArray[89]
                        : can.builderType === 'Python' ? IconArray[90] : can.builderType === 'C' ? IconArray[91]
                        : can.builderType === 'Java' ? IconArray[92] : can.builderType === 'Npm' ? IconArray[93] : IconArray[81]
                        : IconArray[can.icon]}
                    style={{height:22, width:22, verticalAlign:'sub'}}
                    />
                </div>
                <div className="p-1 text-sm col-span-2 break-words" >{can.name}</div>
                {/* <div className="p-1 text-sm col-span-2 break-words">{can.packageType?.join(', ')}</div> */}
                {/* <div className="p-1 text-sm col-span-2 break-words">{can.description}</div> */}
                <div className="p-1 text-sm col-span-6 break-words">{can.DockerRepositoryTag ? can.DockerRepositoryTag : can.artifactRepo && can.artifactRepo}</div>
                <div className="p-1 text-sm col-span-2 break-words" >
                    {new Date(can.dateAdded).toLocaleDateString([], DATE_OPTIONS)}
                </div>
                <div className="p-1 text-sm flex gap-3" >
                    <button onClick={() => {!showDetails ? setShowDetails(can) : setShowDetails(null)
                        setCanDetails(null)}}
                        className="p-1 hover:text-green-500 hover:scale-110 transition duration-200"
                    >
                        <BsInfoCircle size={18}/>
                    </button>
                    { allowEdit ?
                        <button onClick={(e)=>DeleteCandidate(e, can)}
                            className="p-1 hover:text-red-500 hover:scale-110 transition duration-200"
                        >
                            <BsTrash size={18}/>
                        </button>
                    :
                        <></>
                    }
                </div>
                <div className={`${showDetails ? 'scale-y-100 ease-in transition duration-200' : 'scale-y-0 ease-in transition duration-200'} rounded-sm col-span-12 border ${mode === 'dark' ? 'border-neutral-600' : 'border-gray-300'} shadow-md`}>
                    {canDetails ?
                    <table className={`w-full rounded-sm transition duration-200 ${mode === 'dark' ? 'bg-zinc-800 group-hover:bg-blue-200 group-hover:bg-opacity-20' : ''}`}>
                        <thead className={`border-b-2 ${mode === 'dark' ? 'border-neutral-600' : 'bg-slate-200'}`}>
                            <tr>
                            {canDetails.map((item,id)=> {
                                if(item !== '_key' && item !== '_id' && item !== '_rev' && item !== 'name' && item !== 'DockerRepositoryTag' && item !== 'dateAdded' && item !== 'artifactRepo' && item !== 'status' && item !== 'headerId')
                                return <th className="text-sm text-left px-2 py-1">{item}</th>
                            }
                            )}
                            </tr>    
                        </thead>
                        <tbody>
                            <tr>
                            {canDetails.map((item,id)=> {
                                console.log(item)
                                if(item !== '_key' && item !== '_id' && item !== '_rev' && item !== 'name' && item !== 'DockerRepositoryTag' && item !== 'dateAdded' && item !== 'artifactRepo' && item !== 'status' && item !== 'headerId')
                                return <td className="text-sm px-2 py-1 break-words">{showDetails[item]}</td>
                            }
                            )}
                            </tr>    
                        </tbody>
                    </table>
                    :
                    <></>
                    }
                </div>
            </div>
            </ConditionalWrapper>
            
        </>
    )
}