import { Avatar, IconButton, Button, Menu, MenuItem } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { BsInfoCircle, BsThreeDotsVertical } from 'react-icons/bs'
import builder from '../candidates/builder-orange.svg'
import { HiLink } from 'react-icons/hi'
import adlogo from '../adlogo.png'
import { useAuth } from '../auth/AuthContext'

export default function Settings({loading, userId, setPage, drawerWidth}) {
    const {currentUser, logout} = useAuth()
    const [tab, setTab] = useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleLogout = () => {
        handleClose();
        logout();
    }

    useEffect(()=>{
        setPage('Settings')
    },[])

    return (
        <div className={`pt-24 w-3/4 ${drawerWidth < 240 ? 'mx-auto' : 'ml-[280px]'} min-h-[100vh]`}>
            <div className='grid grid-cols-3 text-center text-lg text-gray-500 mb-12 bg-white mt-[-3vh] 3xl:mt-[-1vh]'>
                <div className={`${tab === 0 ? 'text-black' : ''} py-4 font-semibold cursor-pointer`} onClick={()=>setTab(0)}>
                    <p className={`w-max block mx-auto ${tab === 0 ? 'border-b-[1px] border-b-sky-500' : ''} transition-all duration-300`}>General</p>
                </div>
                <div className={`${tab === 1 ? 'text-black' : ''} py-4 font-semibold cursor-pointer border-l-2 border-r-2`} onClick={()=>setTab(1)}>
                    <p className={`w-max block mx-auto ${tab === 1 ? 'border-b-[1px] border-b-sky-500' : ''} transition-all duration-300`}>Integrations</p>
                </div>
                <div className={`${tab === 2 ? 'text-black' : ''} py-4 font-semibold cursor-pointer`} onClick={()=>setTab(2)}>
                    <p className={`w-max block mx-auto ${tab === 2 ? 'border-b-[1px] border-b-sky-500' : ''} transition-all duration-300`}>Release</p>
                </div>
            </div>
            { tab === 0 ?
                <div className='grid grid-cols-2 gap-6'>
                    <div className='border bg-white py-4 h-[25vh]'>
                        <p className='font-semibold text-xl px-6'>
                            Profile
                        </p>
                        <hr className='my-4' />
                        <div className='2xl:px-6 px-2 mt-8 grid grid-cols-8 w-full items-center gap-2'>
                            <Avatar sx={{height:50, width:50}} />

                            <div className='col-span-6 flex flex-col gap-2'>
                                <p>{currentUser?.displayName}</p>
                                <p className='text-sm text-gray-400'>{currentUser?.email}</p>
                            </div>
                            <div className='text-right'>
                                <IconButton onClick={handleClick}><BsThreeDotsVertical /></IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <div className='border bg-white py-4 h-[25vh]'>
                        <p className='font-semibold text-xl px-6'>
                            Appearance
                        </p>
                        <hr className='my-4' />
                        <div className='px-6 mt-8 grid grid-cols-8 w-full items-center gap-2'>
                            <div className='col-span-6 flex flex-col gap-2'>
                                <p>Mode</p>
                                <p className='text-sm text-gray-400'>Toggle Theme</p>
                            </div>
                            <div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            : tab === 1 ? 
                <div className='border bg-white py-6'>
                   
                    <div className='px-6 flex w-full items-center justify-between'>
                        <div className='flex gap-4 items-center'>
                            <img src={builder} style={{height:40, width:40}} />
                            <p className=' px-6'>
                                Builder
                            </p>
                        </div>
                        <div className='col-span-6 flex flex-col gap-2'>
                            <p className='text-gray-400'>Build custom artifacts for deployment via Release</p>
                        </div>
                        <a className='text-right' href="https://www.getbuilder.io/" target='blank'>
                            <IconButton><HiLink /></IconButton>
                        </a>
                    </div>
                </div>
            :
                <div className='grid grid-cols-2 gap-6'>
                    <div className='border bg-white py-4'>
                        <p className='font-semibold text-xl px-6'>
                            Platform Version
                        </p>
                        <hr className='my-4' />
                        <div className='2xl:px-6 px-2 mt-6 grid grid-cols-8 w-full items-center gap-2 mb-2'>
                            <img src={adlogo} style={{height:40, width:40}} />
                            <div className='col-span-6 flex flex-col gap-2'>
                                <p>1.0</p>
                                <p className='text-sm text-gray-400'>Demo version 1.0</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
} 