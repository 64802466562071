import { Box, Button, Card, CircularProgress, Divider, Typography, Paper, List, Breadcrumbs, Link, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import { BsCheck2Circle, BsDash, BsPlus } from 'react-icons/bs'
import DeployView from './DeployView'
import { useNavigate } from 'react-router'
import groupIcon from '../cube-of-notes-stack.png'
import phaseIcon from '../supply-chain.png'
import canIcon from '../cubes (1).png'
import {IconArray} from '../candidates/CandidateImport'
import DeployGraph from './DeployGraph'
import { DataSet } from "vis-data/esnext";
import { Network } from "vis-network/esnext";
import "vis-network/styles/vis-network.css";
import Graph from "react-graph-vis";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useAuth } from '../auth/AuthContext'
import TourNavigation from './TourNavigation'
import { Tour } from 'antd'

export default function Deploy({current, drawerWidth, DATE_OPTIONS, manifests, setPage, colors, mode, loading, setCurrent, manifestInfo, userId, username, tour, setTour, slide, setSlide}) {
    const [manifest, setManifest] = useState({})
    const [currentManifest, setCurrentManifest] = useState(null)
    const [deployLoading, setDeployLoading] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [started, setStarted] = useState(false)
    const [done, setDone] = useState(false)
    const [order, setOrder] = useState([])
    const [finished, setFinished] = useState([])
    const [phases, setPhases] = useState([])
    const [groups, setGroups] = useState([])
    const [uniqueGroups, setUniqueGroups] = useState([])
    const [nodes, setNodes] = useState([])
    const [edges, setEdges] = useState([])
    const [graph, setGraph] = useState({})
    const [lastCan, setLastCan] = useState([])
    const [selected, setSelected] = useState(null)
    const [mousePos, setMousePos] = useState({})
    const [deployObj, setDeployObj] = useState({})
    const [currentPhase, setCurrentPhase] = useState(1)
    const [candidates, setCandidates] = useState([])
    const [showTooltip, setShowTooltip] = useState(null)
    const [counter, setCounter] = useState(0)
    const [deployed, setDeployed] = useState([])
    const [graphCreated, setGraphCreated] = useState(null)
    const [approved, setApproved] = useState(false)
    const [openApprovalAlert, setOpenApprovalAlert] = useState(false)
    const { currentUser } = useAuth()
    const navigate = useNavigate()
    const ConditionalWrapper = ({condition, wrapper, children }) =>
    condition ? wrapper(children) : children
    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)

    const events = {
        // select: ({ nodes, edges }) => {
        //     nodes.length > 0 ? setSelected(nodes-1) : setSelected(null)
        //   },
        hoverNode: ({node}) => {
            setShowTooltip(node)
        },
        showPopup: id=>{
            console.log(id)
        }
        // showPopup: (()=>{
        //     setShowTooltip(true)
        // }),
        // hidePopup: (()=>{
        //     setShowTooltip(false)
        // })
    };

    const options = {
        layout: {
          hierarchical: false,
          randomSeed: 9
        },
        nodes: {
            shape: 'circularImage',
            size: 24,
            color: {
                background: mode === 'dark' ? colors[8] : colors[10],
                border: colors[10],
                highlight: {
                    border: 'green',
                    background:'#e5ffe5'
                }
            },
            font: { color: mode === 'dark' ? colors[8] : colors[9] },
            imagePadding: 4

        },
        edges: {
          color: mode === 'dark' ? colors[8] : colors[5],
          smooth: true
        },
        height: "100%",
        interaction: {
            hover: true,
            tooltipDelay: 100,
            selectable: false
        }
    };

    useEffect(()=>{
        if(!loading)
        {
            let manifestId
            if(sessionStorage.getItem('current') && sessionStorage.getItem('current') !== undefined)
            {
                manifestId = sessionStorage.getItem('current')
            }
            else
            {
                manifestId = current
            }
            setCurrentManifest(manifestId)
            setCurrent(manifestId)
            
            currentUser.getIdToken().then((token) => {
                axios.get(`/v2/manifests/${manifestId}`, {
                    headers: {
                      'authorization': `Bearer ${token}`
                    }
                })
                .then(res=>{
                    let nodesTemp = []
                    let phasesTemp = []
                    let groupsTemp = []
                    let candidatesTemp = []
                    const sorted = res.data.nodes.sort((a, b) => {
                        const typeA = a.name
                        const typeB = b.name
                        if (typeA < typeB) {
                            return -1;
                        }
                        if (typeA > typeB) {
                            return 1;
                        }
                        return 0;
                    });
                    sorted.forEach((item,id)=>{
                        const temp = {id: id, label: item.label ? item.type == 'group-start' ? `${item.label}-Start` : `${item.label}-End` : item.name, image: item?.builderType ? item.builderType === 'Node' ? IconArray[88] : item.builderType === 'Go' ? IconArray[89]
                        : item.builderType === 'Python' ? IconArray[90] : item.builderType === 'C' ? IconArray[91]
                        : item.builderType === 'Java' ? IconArray[92] : item.builderType === 'Npm' ? IconArray[93] : IconArray[81]
                        : IconArray[item.icon], title: item.label ? item.type == 'group-start' ? `${item.label}-Start` : `${item.label}-End` : item.name, status: item.status, _id: item._id}
                        nodesTemp.push(temp)
                        if(item.type === 'phase-end')
                        {
                            phasesTemp.push(item)
                        }       
                        else if (item.type === "group-start")
                        {
                            groupsTemp.push(item)
                        }
                        else if (item.type === 'candidate')
                        {
                            candidatesTemp.push(item)
                        }
                    })
                    let edgesTemp = []
                    res.data.edges.forEach((item)=>{
                        const fromIndex = nodesTemp.findIndex((node)=>node._id === item._from)
                        const toIndex = nodesTemp.findIndex((node)=>node._id === item._to)
                        let temp = {from: fromIndex, to: toIndex}
                        edgesTemp.push(temp)
                    })
                    if(res.data.meta.status === 'Approved') {
                        setApproved(true)
                    }
                    if(res.data.meta.status === 'Finished') {
                        setApproved(true)
                        setDone(true)
                    }
                    setPhases(phasesTemp)
                    setGroups(groupsTemp)
                    setCandidates(candidatesTemp)
                    setManifest(res.data.meta)
                    setNodes(nodesTemp)
                    setEdges(edgesTemp)
                    setDeployLoading(false)
                })
            })

            if(tour)
            {
                setSlide(4)
            }
            
            currentUser.getIdToken().then((token) => {
                axios.get(`/v2/deployed`, {
                    headers: {
                      'authorization': `Bearer ${token}`
                    }
                })
                .then((res) => {
                    setDeployed(res.data)
                })
            });

            const handleMouseMove = (event) => {
                setMousePos({ x: event.clientX, y: event.clientY });
              };
              setPage('Deploy')
              window.addEventListener('mousemove', handleMouseMove);
          
              return () => {
                window.removeEventListener('mousemove',handleMouseMove);
            };
        }
    },[loading])
        
    // constructing graph ----------------------------------------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(edges.length > 0 && nodes.length > 0) {
            let tempselected = []
            nodes.forEach((item,id)=>{
                item.status === 'Done' ? tempselected.push(item.id) : <></>
            })
            setSelected(tempselected)
            if(graphCreated === null)
            {
                const container = document.getElementById("mynetwork");
                const tempnodes = new DataSet([...nodes])
                const tempedges = new DataSet([...edges])
                console.log(tempedges, tempnodes)
                const graphdata = {nodes: tempnodes, edges: tempedges}
                const networkGraph = new Network(container, graphdata, options);
                setGraphCreated(networkGraph)
            }
        }
        if(done)
        {
            const tempselect = nodes.map((item)=>item.id)
            setSelected(tempselect)
        }
    },[edges, nodes])

    useEffect(()=>{
        currentUser.getIdToken().then((token) => {
            axios.get(`/v2/manifests/${current}`, {
                headers: {
                  'authorization': `Bearer ${token}`
                }
            })
            .then(res=>{
                let nodesTemp = []
                let phasesTemp = []
                let groupsTemp = []
                let candidatesTemp = []
                const sorted = res.data.nodes.sort((a, b) => {
                    const typeA = a.name
                    const typeB = b.name
                    if (typeA < typeB) {
                        return -1;
                    }
                    if (typeA > typeB) {
                        return 1;
                    }
                    return 0;
                });
                sorted.forEach((item,id)=>{
                    const temp = Object.assign({id: id, label: item.label ? item.type == 'group-start' ? `${item.label}-Start` : `${item.label}-End` : item.name, image: IconArray[item.icon], title: item.label ? item.type == 'group-start' ? `${item.label}-Start` : `${item.label}-End` : item.name}, item)
                    nodesTemp.push(temp)
                    if(item.type === 'phase-end')
                    {
                        phasesTemp.push(item)
                    }       
                    else if (item.type === "group-start")
                    {
                        groupsTemp.push(item)
                    }
                    else if (item.type === 'candidate')
                    {
                        candidatesTemp.push(item)
                    }
                })
                let edgesTemp = []
                res.data.edges.forEach((item)=>{
                    const fromIndex = nodesTemp.findIndex((node)=>node._id === item._from)
                    const toIndex = nodesTemp.findIndex((node)=>node._id === item._to)
                    let temp = {from: fromIndex, to: toIndex}
                    edgesTemp.push(temp)
                })
                
                axios.get(`/v2/deployed`, {
                    headers: {
                    'authorization': `Bearer ${token}`
                    }
                })
                .then((res) => {
                    setDeployed(res.data)
                })

                setPhases(phasesTemp)
                setGroups(groupsTemp)
                setCandidates(candidatesTemp)
                setManifest(res.data.meta)
                setNodes(nodesTemp)
                setEdges(edgesTemp)
                setTimeout(()=>{
                    setCounter(counter+1)
                },3000)
            })
        })
    },[counter, started])

    // finish constructing graph -------------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
      if(selected !== null && selected.length > 0)
      {
            if(graphCreated !== null)
            {
                graphCreated.selectNodes([...selected],false)
            }
      }
    }, [selected])

    useEffect(() => {
        if(done)
        {
            const tempselect = nodes.map((item)=>item.id)
            setSelected(tempselect)
        }
    }, [done])

    useEffect(()=>{
        const currentIndex = phases.findIndex(el=>el.status !== 'Done')
        if(currentIndex >= 0)
        {
            setCurrentPhase(currentIndex+1)
        }
    },[phases])

    function ApproveDeployment() {
        try {
            currentUser.getIdToken().then((token) => {
                axios.post(`/v2/manifests/${currentManifest}/approve`, {targetEnvironment: manifest.targetEnvironment}, {
                    headers: {
                    'authorization': `Bearer ${token}`
                    }
                })
                .then(() => {
                    setApproved(true)
                    setOpenApprovalAlert(false)
                    setSlide(slide+1)
                })
            })           

        } catch (e) {

        }
    }
    
    async function StartDeployment() {
        var token = await currentUser.getIdToken();

        axios.post(`/v2/manifests/${currentManifest}/deploying`, {targetEnvironment: manifest.targetEnvironment}, {
            headers: {
            'authorization': `Bearer ${token}`
            }
        })
        .then(() => {
            axios.post(`/v2/start/${currentManifest}/PHASE${currentPhase}`, {}, {params: { user: currentUser?.email ? currentUser?.email.split('@')[0].replace(/[+_.]/g, '-') : currentUser.displayName.replace(/\s+/g, '') }, headers: {'authorization': `Bearer ${token}`}})
            .then(() => {
                setStarted(true)
            })
        })
    }

    function PhaseDone() {
        const addedTime = new Date()
        if(currentPhase < phases.length) 
        {
            const phaseToEnd = currentPhase
            const phaseToDeploy = currentPhase + 1
            setCurrentPhase(phaseToDeploy)
            
            currentUser.getIdToken().then((token) => {
                axios.post(`/v2/manifests/${currentManifest}/phase/PHASE${phaseToEnd}-End/done`, {}, {
                    headers: {
                      'authorization': `Bearer ${token}`
                    }
                }).then(() => {
                    axios.post(`/v2/start/${currentManifest}/PHASE${phaseToDeploy}`, {}, {params: { user: currentUser?.email ? currentUser?.email.split('@')[0].replace(/[+_.]/g, '-') : currentUser.displayName.replace(/\s+/g, '') }, headers: {'authorization': `Bearer ${token}`}})    
                })
            })
        }
        else 
        {
            currentUser.getIdToken().then((token) => {
                axios.post(`/v2/manifests/${currentManifest}/phase/PHASE${currentPhase}-End/done`, {}, {
                    headers: {
                      'authorization': `Bearer ${token}`
                    }
                }).then(() => {
                    axios.post(`/v2/manifests/${currentManifest}/finish`, {}, {
                        headers: {
                          'authorization': `Bearer ${token}`
                        }
                    }).then(() => {
                        setStarted(false)
                        setDone(true)
                    })
                })
            })
        }
    }

    async function CompleteTour() {
        var token = await currentUser.getIdToken();
        axios.post(`/v2/profile`, {tourComplete:true}, {
            headers: {
              'authorization': `Bearer ${token}`
            }
        })
        .finally(()=>{
            setTour(false)
        })
    }

    return (
        <>
        {!deployLoading ?
        <>
            <div className={`pt-16 w-full mx-auto min-h-[100vh] flex`}>
                    <Paper variant='outlined' sx={{ background:colors[11], height:'92vh', width:'70vw', position: 'relative'}}>
                        {tour && slide === 4 ?
                            <Tour open={manifest?.status === 'In Progress' && tour} onClose={CompleteTour}
                            className='bg-neutral-500'
                            type="primary"
                                steps={[
                                    {
                                        title: 'Build Release',
                                        description: `The Deployment Preview shows you how your release will be deployed, along with the live status of the release.`,
                                    },
                                    {
                                        title: 'Build Release',
                                        description: `Once your release is approved, you can begin the deployment.`,
                                        target: () => ref2.current
                                    },
                                    {
                                        title: 'Build Release',
                                        description: `Once you initiate a deployment, click here to monitor your deployment in real-time. When your deployment completes, copy and paste the IP address from the logs to see your app live in the browser!`,
                                        target: () => ref3.current
                                    }
                                ]}
                                placement='top'
                            />
                        :
                            <></>
                        }
                            <div id="mynetwork" style={{height:'100%', width:'100%'}}
                                ref={ref1}
                            >
                            </div>
                            <Button 
                                variant="outlined" 
                                sx={{ 
                                    position: 'absolute',
                                    right: 8,
                                    bottom: 8, 
                                    padding: 1,
                                    minWidth: 0, 
                                    backgroundColor: 'white', 
                                    borderColor: '#262626', 
                                    color: '#262626',
                                    '&:hover': {
                                        backgroundColor: 'lightblue', 
                                        borderColor: '#262626', 
                                        color: '#262626',
                                    } 
                                }} 
                                onClick={() => graphCreated.fit()}
                            >
                                <MyLocationIcon />
                            </Button>
                        { showTooltip !== null ?
                            <Card variant="outlined" onMouseMove={()=>setShowTooltip(false)}
                            sx={{ position:'absolute', top: mousePos.y - 100, left: mousePos.x, p: '15px' }}
                            >
                                <Typography variant="subtitle2" sx={{mb:0.5}}>{nodes[selected-1].name}</Typography>
                                <Typography variant="body2">
                                    Status:&nbsp;
                                    {nodes.map((node,i)=>
                                        node.name === nodes[selected-1].name ?
                                        <>{node.status}</>
                                        :
                                        <></>
                                    )}
                                </Typography>
                            </Card>
                        :
                            <></>
                        }
                    </Paper>
                    <Paper elevation={0} sx={{height:'92vh',overflowY:'auto', width:'30vw', pt:1, position:'relative'}}>
                        <div className='flex justify-between gap-[0.5vw] items-center m-[1vh] px-2 relative'
                        >
                        { deployed.forEach((dep)=>
                            dep.manifestId === currentManifest ?
                            <div className='rounded-md border border-sky-500 bg-sky-300 text-sky-500 px-3 py-1'>
                                Service {dep.deploymentName} is now live at <a href={dep.liveUrl} target="_blank">{dep.liveUrl}</a>
                            </div>
                            :
                            <></>
                        )}
                            <Typography variant="h6">{manifest?.description}</Typography>
                            {manifest?.status === 'In Progress' && !approved ?
                                <>
                                    <button onClick={() => setOpenApprovalAlert(true)}
                                        ref={ref2}
                                        className={`w-max ${mode === 'dark' ? 'border border-neutral-600' : 'bg-neutral-800'} text-white shadow-sm flex items-center px-4 py-1.5 rounded-sm hover:shadow-lg active:bg-opacity-100 transition duration-300`}
                                    >
                                        Approve
                                    </button>
                                </>
                            : manifest?.status !== 'Deploying' && !done ?
                            <>
                                <button onClick={StartDeployment}
                                    className={`w-max ${mode === 'dark' ? 'border border-neutral-600' : 'bg-neutral-800'} text-white shadow-sm flex items-center px-4 py-1.5 rounded-sm hover:shadow-lg active:bg-opacity-100 transition duration-300`}
                                >
                                    Deploy to {manifest?.targetEnvironment}
                                </button>
                            </>
                            : done ?
                                <Card variant='outlined' sx={{p:'10px 10px', width:'max-content'}}>
                                    <Box sx={{display:'flex', alignItems:'center', gap:'1vw'}}>
                                        <BsCheck2Circle size={25} style={{color:'#00bf7c'}}/>
                                        <Box sx={{textAlign:'center'}}>
                                        <Typography>Deployment Finished</Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            :
                                <button
                                    className={`w-max ${mode === 'dark' ? 'border border-neutral-600' : 'border border-neutral-300'} shadow-sm flex items-center px-4 py-1.5 rounded-sm`}
                                >
                                    Deploying to {manifest?.targetEnvironment} &nbsp; <CircularProgress size={15}/>
                                </button>
                            }
                        </div>
                        <div className='border flex gap-2 justify-center py-[1.5vh] px-[10px] my-[1vh] mx-[0.7vw] bg-slate-100'>
                            <Card variant='outlined' sx={{p:'5px 10px', width:'max-content'}}>
                                <Box sx={{display:'flex', alignItems:'center', gap:'1vw'}}>
                                    <img src={IconArray[85]} style={mode === 'light' ? {height:20, width:20} : {height:20, width:20, filter:'invert(1)'}} />
                                    <Box sx={{textAlign:'center'}}>
                                        <Typography variant="body2">Phases</Typography>
                                        <Divider/>
                                        <Typography variant='caption'>
                                            {phases.length}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                            <Card variant='outlined' sx={{p:'5px 10px', width:'max-content'}}>
                                <Box sx={{display:'flex', alignItems:'center', gap:'1vw'}}>
                                    <img src={IconArray[86]} style={mode === 'light' ? {height:20, width:20} : {height:20, width:20, filter:'invert(1)'}} />
                                    <Box sx={{textAlign:'center'}}>
                                    <Typography variant="body2">Groups</Typography>
                                        <Divider/>
                                        <Typography variant="caption">
                                            {groups.length}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                            <Card variant='outlined' sx={{p:'5px 10px', width:'max-content'}}>
                                <Box sx={{display:'flex', alignItems:'center', gap:'1vw'}}>
                                    <img src={canIcon} style={mode === 'light' ? {height:20, width:20} : {height:20, width:20, filter:'invert(1)'}} />
                                    <Box sx={{textAlign:'center'}}>
                                    <Typography variant="body2">Candidates</Typography>
                                        <Divider/>
                                        <Typography variant="caption">
                                            {candidates.length}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </div>
                        <Typography variant="subtitle2" sx={{ml: '0.7vw'}}>Deployment History</Typography>
                        <Box>
                            <List
                                ref={ref3}
                            >
                            {
                                phases.map((item,id)=>{
                                return (
                                <>
                                    <Paper variant="outlined" sx={{m:'0.5vh 0.5vw'}}>
                                        <div className={`flex justify-between items-center px-4 py-2 border-b-2 ${mode === 'dark' ? 'border-b-neutral-600' :''}`}>
                                            <p>{item.name.split('-')[0]}</p>
                                            { loaded ?
                                                <>{item.status === 'Done' ? <Typography>Done</Typography>:<></>}</>
                                            :
                                                <></>
                                            }
                                        </div>
                                        { groups.map((group,ind)=>
                                            group.phase === item.name.split('-')[0] ?
                                            <>
                                                <DeployView group={group} DATE_OPTIONS={DATE_OPTIONS} colors={colors} order={order} ind={ind} groups={groups} phasename={item.name}
                                                    finished={finished} setFinished={setFinished} mode={mode} deployed={deployed} counter={counter}
                                                    loaded={loaded} started={started} lastCandidate={lastCan} phase={id} setLastCan={setLastCan} deployObj={deployObj}
                                                    candidates={candidates} IconArray={IconArray} slide={slide} setSlide={setSlide} tour={tour}
                                                />
                                            </>
                                            :
                                            <></>
                                        )
                                        }
                                    </Paper>
                                    { item.name.includes(currentPhase) && manifest?.status === 'Deploying' && item.status !== 'Done' && !done ?
                                        <button className='ml-3 mt-2 border border-neutral-500 px-3 py-1 rounded-md hover:bg-green-50 hover:text-green-700 hover:border-green-700 transition duration-200 w-max'
                                            onClick={PhaseDone}
                                        >
                                            Phase{currentPhase} Done
                                        </button>
                                    :
                                        <></>
                                    }
                                </>
                                )}
                                )
                            }
                            </List>
                        </Box>
                    </Paper>
            </div>
            <Dialog
                open={openApprovalAlert}
                maxWidth={'xs'}
                fullWidth
                onClose={() => setOpenApprovalAlert(false)}
            >
                <DialogTitle id="alert-dialog-title">
                {manifest.description}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Confirm approval for {manifest.description}
                    </DialogContentText>
                </DialogContent>
                <div className='flex justify-between p-4'>
                    <button onClick={() => setOpenApprovalAlert(false)}
                        className={`w-max border ${mode === 'dark' ? 'border-neutral-600' : 'border-neutral-800'} shadow-sm flex items-center px-4 py-1.5 rounded-sm hover:shadow-lg active:bg-opacity-100 transition duration-300`}
                    >
                        Cancel
                    </button>
                    <button onClick={() => ApproveDeployment()}
                        className={`w-max ${mode === 'dark' ? 'border border-neutral-600' : 'bg-neutral-800'} text-white shadow-sm flex items-center px-4 py-1.5 rounded-sm hover:shadow-lg active:bg-opacity-100 transition duration-300`}
                    >
                        Approve
                    </button>
                </div>
            </Dialog>
        </>
        :
        <></>
        }
        </>
    )

}