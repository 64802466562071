import React, { useState, useEffect } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { CiGlobe } from 'react-icons/ci'
export default function CandidatePageListItems({type, searchTerm, candidates, IconArray, filtered, showAll, catalog}) {
    const [show, setShow] = useState(false)
    const [localSet, setLocalSet] = useState([])

    useEffect(()=>{
        if(showAll)
        {
            setShow(true)
        }
        else
        {
            setShow(false)
        }
    },[showAll])

    useEffect(()=>{
        if(searchTerm === '')
        {
            const allFiltered = [...candidates]
            let localTemp = []
            allFiltered.forEach((item,id)=>item.packageType.includes(type) ? localTemp.push(id) : <></>)
            setLocalSet(localTemp)
        }
        else
        {
            const allFiltered = [...filtered]
            let localTemp = []
            allFiltered.forEach((item,id)=>candidates[item].packageType.includes(type) ? localTemp.push(item) : <></>)
            setLocalSet(localTemp)
        }
    },[filtered, searchTerm])
    
    return (
        <div className='max-h-[40vh] overflow-auto border border-gray-300 rounded-md' key={type}>
            <div className='p-3 bg-white flex items-center gap-3 cursor-pointer' onClick={()=>setShow(!show)}>
                <button>{show ? <BsChevronUp /> : <BsChevronDown />}</button>
                <strong>{type}</strong>
                <p className='text-sm'>{localSet.length}</p>
            </div>
            { show ?
                <table className='w-full bg-white border-t-2'>
                    <thead className='bg-slate-200'>
                        <tr>
                            <th className='text-sm text-left'></th>
                            <th className='text-sm text-left p-1'>Name</th>
                            <th className='text-sm text-left p-1'>Description</th>
                            <th className='text-sm text-left p-1'>Artifact ID</th>
                            <th className='text-sm text-left p-1'>Artifact Repo</th>
                            <th className='text-sm text-left p-1'>Source Repo</th>
                            <th className='text-sm text-left p-1'>Version</th>
                        </tr>
                    </thead>
                    <tbody>
                        { localSet.length > 0 ?
                            <>
                                {localSet.map((item,id)=>
                                    candidates[item].packageType.includes(type) ?
                                    <tr className='divide-y' key={candidates[item].name+candidates[item].id}>
                                            <td className='text-sm p-1'>
                                                <div className='h-max w-max'>
                                                    {candidates[item].icon !== undefined ?
                                                        <div className='p-1'>
                                                            <img style={{height:24, width:24}} src={IconArray[candidates[item].icon]} />
                                                        </div>
                                                        :
                                                        <CiGlobe style={{height:24, width:24}}/>
                                                    }  
                                                </div>
                                            </td>
                                            <td className='text-sm p-1'>{candidates[item].name}</td>
                                            <td className='text-sm p-1'>{candidates[item].description}</td>
                                            <td className='text-sm p-1'>{candidates[item].artifactId}</td>
                                            <td className='text-sm p-1'>{candidates[item].artifactRepo}</td>
                                            <td className='text-sm p-1'>{candidates[item].sourceCodeRepo}</td>
                                            <td className='text-sm p-1'>{candidates[item].version}</td>
                                    </tr>
                                    :
                                    <></>
                                )}
                            </>
                            :
                            <></>
                        }
                    </tbody>
                </table>
                :
                <></>
            }
            
        </div>
    )
}