import React, { useState, useEffect } from "react";
import releases from '../AD_Release-logo_icon-black (2).png'
import { useNavigate } from "react-router";
import adlogo from '../adlogo.png'
import { Box, Typography, IconButton, Breadcrumbs, Link, Fab, Button, Tooltip, Badge, Dialog, DialogTitle, DialogContent, TextField, DialogContentText, DialogActions } from '@mui/material'
import { BsBell, BsFolder2Open, BsGear, BsGraphUp, BsPerson  } from 'react-icons/bs'
import { styled } from '@mui/material/styles'
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import ResponsiveSidebar from "./ResponsiveSidebar";
import { BiMessageDetail } from "react-icons/bi"

const SignInButton = styled(Button)`
  color: #010203;
  &:hover {
    background-color: #a9a9a9;
  }
  
`;

export default function Navibar({page, mode, colors, drawerWidth, setDrawerWidth, manifests, setManifests, slide, apps, setApps, HandleTheme, setMode, user, setUser, current, manifestInfo}) {
    const [releaseName, setReleaseName] = useState('')
    const [releaseV, setReleaseV] = useState('')
    const [showFab, setShowFab] = useState(false)
    const [showFeedback, setShowFeedback] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [closed, setClosed] = useState(true)
    const [reviewText, setReviewText] = useState('')
    const open = Boolean(anchorEl);

    const navigate = useNavigate()

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const showSidebar = useMediaQuery('(min-width:1024px)');

    useEffect(()=>{
        const manifestId = sessionStorage.getItem('current') ? sessionStorage.getItem('current') : null
        if(manifestId !== null)
        {
            user.getIdToken().then((token) => {
                axios.get(`/v2/manifests/${manifestId}`, {
                    headers: {
                    'authorization': `Bearer ${token}`
                    }
                })
                .then(res=>{
                    
                    setReleaseName(res.data.meta.description)
                    setReleaseV(res.data.meta.version)
                })
            })
        }
    },[page])

    useEffect(()=>{
        if(!showSidebar && drawerWidth > 0)
        {
            setDrawerWidth(0)
            setShowFab(true)
        }
        else if (showSidebar && drawerWidth === 0)
        {
            setDrawerWidth(50)
            setShowFab(false)
        }
    },[showSidebar])

    useEffect(()=>{
        if(drawerWidth === 50)
        {
            setTimeout(()=>{
                setClosed(true)
            },500)
        }
        else
        {
            setClosed(false)
        }
    },[drawerWidth])


    function ToggleWidth(e) {
        e.stopPropagation()
        if (drawerWidth < 240)
        {
            setDrawerWidth(240)
        }
        else if (!showSidebar)
        {
            setDrawerWidth(50)
            setShowFab(true)
        }
        else
        {
            setDrawerWidth(50)
        }
        if(showFab)
        {
            setShowFab(false)
        }
    }

    function ToggleMode() {
        mode === 'dark' ? setMode('light') : setMode('dark')
    }


    function SendFeedback() {
        const msgObj = {
            "text": `${reviewText} \n On Page URL: ${window.location.href} \n UID: ${user.uid} \n Username: ${user.displayName} \n Email: ${user.email}`
        }
        axios.post('/v2/feedback', msgObj)
        .then(res=>{
            setReviewText('')
            setShowFeedback(false)
        })
        .catch(err=>{

        })
    }
    
    function TryToggleWidth() {
        if(closed)
        {
            setDrawerWidth(240)
        }
    }

    return (
    <>
        <div className="w-full fixed top-0 z-20">
            <div className={`w-full flex justify-between px-3 ${page === 'Get Started' || page === 'Tour' ? '' : ' py-3 border border-r-0'} ${mode === 'dark' ? 'bg-neutral-800 border-neutral-600' : 'bg-white border-neutral-200'}`}>
                <Box sx={{ml: showSidebar ? drawerWidth+30+'px' : '10px', display:'flex', gap:0.5, alignItems:'center', justifyContent:'space-between', width:'100%'}}>
                    <Box sx={{display:"flex", gap:1}}>
                        { showFab ?
                            <IconButton onClick={ToggleWidth} className="px-3 py-1">
                                <img src={adlogo} className="h-[30px] w-[30px] pointer-events-none" />
                            </IconButton>
                            :
                            <></>
                        }
                        <div>
                            <div className={`flex items-center text-[${colors[5]}]`}>
                                {page === 'Home' ?
                                    <>
                                        <BsGraphUp /> &nbsp; Home
                                    </>
                                : page === 'Dashboard' ?
                                    <>
                                        <BsGraphUp /> &nbsp; Reporting
                                    </>
                                : page === 'Settings' ?
                                    <>
                                        <BsGear /> &nbsp; Settings
                                    </>
                                : page === 'Catalog' || page === 'Artifacts' ?
                                    <>
                                        <BsFolder2Open /> &nbsp; {page}
                                    </>
                                : page === 'Setup' ?
                                    <>
                                        <img src={releases} style={mode === 'dark' ? {height:20, width:20, filter:'invert(1)'} : {height:20, width:20}}/> &nbsp; Release
                                    </>
                                : page === 'Release' ?
                                    <Box sx={{display:'flex', justifyContent:'space-between', gap:1}}>
                                        <img src={releases} style={mode === 'dark' ? {height:20, width:20, filter:'invert(1)'} : {height:20, width:20}}/>
                                        <Breadcrumbs>
                                            <Tooltip title="Back to Releases">
                                                <div className="cursor-pointer underline" onClick={()=>{sessionStorage.getItem('view') == 2 ? navigate('calendar') : navigate('/releases')}}>Release</div>
                                            </Tooltip>
                                            <div className={mode === 'dark' ? 'text-neutral-200' : 'text-neutral-700'}>
                                                {releaseName}
                                                <Typography variant="caption" sx={{background:colors[10], px:'3px', borderRadius:"5px"}}>v</Typography>
                                                &nbsp;{releaseV}
                                            </div>
                                        </Breadcrumbs>
                                    </Box>
                                : page === 'Deploy' ?
                                    
                                    <Box sx={{display:'flex', justifyContent:'space-between', gap:1}}>
                                        <img src={releases} style={mode === 'dark' ? {height:20, width:20, filter:'invert(1)'} : {height:20, width:20}}/>
                                        <Breadcrumbs>
                                            <Tooltip title="Back to Releases">
                                                <div className="cursor-pointer underline" onClick={()=>{sessionStorage.getItem('view') == 2 ? navigate('calendar') : navigate('/releases')}}>Release</div>
                                            </Tooltip>
                                            <Tooltip title="Edit Release">
                                                <div className="cursor-pointer underline" onClick={()=>navigate(`release/${current}`)}>
                                                    {releaseName}
                                                    <Typography variant="caption" sx={{background:colors[10], px:'3px', borderRadius:"5px"}}>v</Typography>
                                                    &nbsp;{releaseV}
                                                </div>
                                            </Tooltip>
                                            <div className={mode === 'dark' ? 'text-neutral-200' : 'text-neutral-700'}>Deploy</div>
                                        </Breadcrumbs>
                                    </Box>
                                :  page === 'Get Started' || page === 'Tour' ?
                                    <></>
                                :
                                    <>
                                        <img src={releases} style={mode === 'dark' ? {height:20, width:20, filter:'invert(1)'} : {height:20, width:20}}/> &nbsp; {page}
                                    </>
                                }
                            </div>
                            <Typography variant="body2" color="text.secondary">
                                { page === 'Deployments' ?
                                    <>Deploy and Monitor Your Releases Here</>
                                    : page === 'Setup' || page === 'Calendar' ?
                                    <>Create, Edit, and Manage Your Releases Here</>
                                    : page === 'Release' ?
                                    <>Add Services and Configure Release</>
                                    : page === 'Dashboard' ?
                                    <>View Dashboards</>
                                    : page === 'Catalog' || page === 'Artifacts' ?
                                    <>Available Artifacts</>
                                    :  page === 'Signup' ?
                                    <>Compare Plans and see all that AuditDeploy has to Offer</>
                                    :  page === 'Login' ?
                                    <>Log in to Start Using AuditDeploy</>
                                    : page === 'Settings' ?
                                    <>Adjust Settings and Preferences</>
                                    : page === 'Get Started' || page === 'Tour' ? 
                                    <></>
                                    : page === 'Environments' ?
                                        <>Live Deployments</>
                                    :
                                    <>Monitor Deployment</>
                                }
                            </Typography>
                        </div>
                    </Box>
                </Box>
                <Box sx={{display:'flex', mr: 1, alignItems:'center', gap:'0.5vw'}}>
                    { page !== 'Get Started' && page !== 'Tour' ?
                    <>
                        <Tooltip title="Requests/Problems? Leave us a note!" placement="left">
                            <button className="border rounded-md px-3 py-1"
                                onClick={()=>setShowFeedback(true)}
                            >
                                Feedback
                            </button>
                        </Tooltip>
                        <IconButton size="small">
                            <BsBell />
                        </IconButton>
                    
                    </>
                    :
                        <></>
                    }
                </Box>
            </div>
        </div>
        <div className={`${drawerWidth < 240 ? 'w-[50px]' : 'w-[260px]'} transition-all duration-500 h-full fixed top-0 z-50`}
            onMouseEnter={TryToggleWidth}
        >
            { page === 'Tour' ?
                <></>
            :
                <ResponsiveSidebar colors={colors} showSidebar={showSidebar} mode={mode} ToggleWidth={ToggleWidth}
                        page={page} drawerWidth={drawerWidth} apps={apps} setMode={setMode} ToggleMode={ToggleMode}
                        setApps={setApps} HandleTheme={HandleTheme} setDrawerWidth={setDrawerWidth} slide={slide}
                />
            }
        </div>
        { showFeedback ? 
            <Dialog open={showFeedback} onClose={()=>setShowFeedback(false)} maxWidth='sm' fullWidth>
                <DialogTitle>Provide feedback on features</DialogTitle>
                
                <DialogContent sx={{position:'relative'}}>
                    <textarea className="w-full border rounded-md p-2" value={reviewText} onChange={(e)=>setReviewText(e.target.value)}
                        placeholder="What do you like? What don't you like? What could be better?"
                    />
                    <p className="text-sm text-neutral-500 text-right">{reviewText.length}</p>
                </DialogContent>
                <DialogActions sx={{px:3}}>
                    { reviewText.length >= 10 ?
                    <button onClick={SendFeedback}
                        className={` border border-neutral-800 bg-neutral-800 text-white h-max py-1 px-4 shadow-sm rounded-md transition hover:bg-opacity-80 duration-300 hover:shadow-lg`}
                    >
                        Submit Feedback
                    </button>
                    :
                        <button disabled
                            className={`text-neutral-500 border border-neutral-300 bg-neutral-300 h-max py-1 px-4 shadow-sm rounded-md`}
                        >
                            Submit Feedback
                        </button>
                    }
                </DialogActions>
            </Dialog>
        :
            <></>
        }
    </>
    )
}