import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { BsX } from 'react-icons/bs'
import { RiExpandLeftRightLine } from "react-icons/ri";
import {useAuth} from '../auth/AuthContext'

export default function DeploymentLog({ title, started, status, candidate, handleClose, detach, setDetach, envPage }) {
    const [counter, setCounter] = useState(0)
    const [timer, setTimer] = useState(0)
    const [text, setText] = useState(['Fetching log data...'])
    const {currentUser} = useAuth()

    useEffect(()=>{
        if(!envPage)
        {
            currentUser.getIdToken().then((token) => {
                axios.post(`/v2/log/manifest/${sessionStorage.getItem('current')}/${candidate._key}`,{},
                {
                    //using ondownload progress to monitor chunk responses as they stream
                    onDownloadProgress: event => {
                        const log = event?.event?.currentTarget?.responseText.split('\n').filter(function(line){ 
                            return line.indexOf( "PING" ) == -1;
                        })
                        setText(log)
                    },
                    headers: {
                        'authorization': `Bearer ${token}`
                    }
                })

                const auditObj = {
                    username: currentUser.displayName,
                    email: currentUser?.email,
                    uid: currentUser?.uid,
                    event: `${title} logs viewed`,
                    time: new Date(),
                    manifest: sessionStorage.getItem('current'),
                    type: "Check Logs"
                }
                axios.post('/v2/audit', auditObj, {
                    headers: {
                    'authorization': `Bearer ${token}`
                    }
                })
            })
        }
        else if (envPage)
        {
            try{
                axios.get(`/v2/follow-pod-logs/namespace/${candidate.belongsTo}/deployment/${candidate.deploymentName}`,
                {
                    //using ondownload progress to monitor chunk responses as they stream
                    onDownloadProgress: event => {
                        console.log(event?.event?.currentTarget?.responseText)
                        // textTemp.push(event?.event?.currentTarget?.responseText)
                        setText(event?.event?.currentTarget?.responseText.split('\n'))
                    }
                })
                const auditObj = {
                        username: currentUser.displayName,
                        email: currentUser?.email,
                        uid: currentUser?.uid,
                        event: `${candidate.candidateName} logs viewed`,
                        time: new Date(),
                        manifest: candidate.manifestId,
                        type: "Check Logs"
                }
                currentUser.getIdToken().then((token) => {
                    axios.post('/v2/audit', auditObj, {
                        headers: {
                            'authorization': `Bearer ${token}`
                        }
                    })
                })
            }
            catch {
                setText(['Failed to get pod logs. Please try again later.'])
            }
        }
        else
        {
            setText([
                'Starting deployment to QA...',
                'Configuring candidate',
                'Fetching start scripts...',
                'Installing dependencies (0/12)...',
                'Installing dependencies (1/12)...',
                'Installing dependencies (2/12)...',
                'Installing dependencies (3/12)...',
                'Installing dependencies (4/12)...',
                'Installing dependencies (5/12)...',
                'Installing dependencies (6/12)...',
                'Installing dependencies (7/12)...',
                'Installing dependencies (8/12)...',
                'Installing dependencies (9/12)...',
                'Installing dependencies (10/12)...',
                'Installing dependencies (11/12)...',
                'Installing dependencies (12/12)...',
                'Running start scripts...',
                'Requesting resources...',
                'Provisioning resources...',
                'Deploying to cluster...',
                'Candidate deployed successfully!'
            ])
        }
    },[])

    useEffect(()=>{
        if(started)
        {
            setTimeout(()=>{
                setCounter(counter+1)
            },450)
        }
        if(status === 'Done')
        {
            setCounter(text.length)
        }
    },[counter, status, started])

    // useEffect(()=>{
    //     if(candidate.packageType[0]==='Builder')
    //     {
    //         if(candidate.name === 'bezkoder-ui')
    //         {
    //             setText([...uiLogs])
    //         }
    //         else
    //         {
    //             setText([...apiLogs])
    //         }
    //     }
    // },[])

    useEffect(()=>{
        setTimeout(() => {
            setTimer(timer+1)
        }, 500);   
    })

    return (
        <div className='rounded-md bg-neutral-800 text-white w-full'>
            <div className='bg-slate-500 px-4 py-1 rounded-t-md flex items-center gap-2'>
                {!detach ? 
                <>
                    <div className='rounded-full transition duration-200 cursor-pointer hover:bg-red-400 shadow-sm shadow-red-500 bg-red-500 text-red-700' onClick={() => handleClose()}>
                        <BsX size={18} />
                    </div>
                    <div className='transition duration-200 hover:bg-green-400 shadow-sm shadow-green-500 bg-green-500 rounded-full text-green-700'
                    >
                        <RiExpandLeftRightLine size={18} />
                    </div>
                </>
                :
                <></>
                }
                <p className='ml-2'>{title}</p>
            </div>
            <div className='overflow-y-auto h-[50vh] flex flex-col-reverse'>
                <div className='px-4 py-1'>
                    <div className='flex flex-col'>
                        {text.map((line)=>
                            <pre className='text-sm'>{line}</pre>
                        )}
                    </div>
                    <p>{`>`}&nbsp;<span className='animate-pulse'>|</span></p>
                </div>
            </div>
        </div>
    )
}