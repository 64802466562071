import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Box, Typography, TextField, Button, Paper, Snackbar, Alert, Divider, Link } from '@mui/material'
import adlogo from '../adlogo.png'
import Bgimg from '../screen2023-07-12-234131.png'
import PasswordChecklist from "react-password-checklist"


export default function Register({ TryLogin, emailSignup, colors, userId, setUserId, passwd, setPasswd, warning, setWarning, currentUser, setUser, setBuilderId }) {

  const navigate = useNavigate();
  const [passwdConfirm, setPasswdConfirm] = useState("")
  const [valid, setValid] = useState(false)

  function isError(obj) {
    return Object.prototype.toString.call(obj) === "[object Error]";
  }

  async function TrySignup(e) {
    e.preventDefault()
    try {
      const login = await emailSignup(userId, passwd)
      if (!isError(login)) {
        setUser(0)
        setBuilderId(login.user.uid)
        sessionStorage.setItem('builder', 0)
      } else {
        console.log("ELSE")
        throw new Error(login)
      }
    } catch (e) {
      alert(e.message)
    }
  }

  // function checkValidData() {
  //   if (passwd !== "" && userId !== "") {
  //     if (passwd === passwdConfirm) {
  //       return true
  //     }
  //   }

  //   return false
  // }

  useEffect(() => {
    if (currentUser)
      navigate("/");
  }, [currentUser, navigate])

  // useEffect(() => {
  //   setValid(checkValidData())
  // }, [passwd, passwdConfirm, userId])

  return (
    <Box className='login' sx={{ background: colors[4], overflow: 'hidden', height: '99.9vh' }}>
      <img src={Bgimg} style={{ objectFit: 'cover', height: "100vh", width: '100vw', zIndex: "-1", position: 'fixed' }} />
      <Box sx={{ display: 'grid', placeItems: 'center', height: '100vh', width: '100vw' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <Paper sx={{ p: '2vh 2vw', width: '25vw' }}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center' }}>
              <img src={adlogo} alt="Audit Deploy logo" style={{ height: 44, width: 44 }} />
              <div>
                <p className='text-3xl'><b>Audit</b>Deploy</p>
                <p className='text-gray-500'>Create your account</p>
              </div>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }} component='form' onSubmit={(e) => TrySignup(e)}>
              <TextField placeholder='Email' value={userId} onChange={(e) => setUserId(e.target.value)} />
              <TextField placeholder='Password' type="password" value={passwd} onChange={(e) => { setPasswd(e.target.value) }} />
              <TextField placeholder='Confirm Password' type="password" value={passwdConfirm} onChange={(e) => { setPasswdConfirm(e.target.value) }} />
              {passwd !== '' && <PasswordChecklist
                rules={["minLength", "specialChar", "number", "match"]}
                minLength={6}
                value={passwd}
                valueAgain={passwdConfirm}
                onChange={(isValid) => { setValid(isValid) }}
              />}
              <Button variant='contained' disableElevation sx={{ background: colors[7], color: colors[6], py: 1, borderRadius: "20px" }}
                type="submit"
                onClick={(e) => TrySignup(e)}
                disabled={!valid}
              >
                Create Account</Button>
              {/* <Divider></Divider>
              <GoogleButton style={{ width: 'auto', color: 'black' }} type="light" onClick={() => { googleLogin() }} />
              <GithubButton style={{ width: "auto", color: 'black' }} type='light' onClick={() => { githubLogin() }} /> */}
              {/* <Link component="button" variant="body2" onClick={() => { navigate('/login') }}>Return to Login</Link> */}
              <Button variant='outlined' disableElevation sx={{ background: 'white', color: 'black', py: 1, borderRadius: "20px", borderColor: 'black', width: '50%', fontSize: '12px', margin: 'auto',
                  ':hover': {
                    color: 'white',
                  }, }}
                onClick={() => navigate('/login')}
              >
                Return to Login</Button>
            </Box>
          </Paper>
          <Typography variant="caption">Release by AuditDeploy</Typography>
        </Box>
      </Box>
      <Snackbar open={warning !== null}
        onClose={() => setWarning(null)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity='error'>{warning}</Alert>
      </Snackbar>
    </Box>
  )
}