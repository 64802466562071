import React, { useState, useEffect } from 'react'
import { Box, List, ListItem, ListItemButton, ListItemText, ListItemIcon, Divider, Tooltip, Paper, Typography, InputBase, IconButton, Collapse, Switch } from '@mui/material'
import { BsHouse, BsChevronLeft, BsSearch, BsChevronUp, BsChevronDown, BsGear, BsHeadset, BsSun, BsMoon, BsPlayBtn, BsListTask, BsThreeDotsVertical, BsClipboardPulse } from 'react-icons/bs'
import capture from '../capture.png'
import builder from '../candidates/builder-orange.svg'
import catalog from '../cubes (1).png'
import adlogo from '../adlogo.png'
import { useNavigate } from 'react-router'
import releases from '../AD_Release-logo_icon-black (2).png'
import { TbGridDots } from 'react-icons/tb'
import { HiListBullet, HiOutlineCalendar, HiOutlinePlay, HiOutlinePresentationChartBar } from 'react-icons/hi2'
import { CiViewList } from 'react-icons/ci'
import { AiOutlineFileSearch } from 'react-icons/ai'
import dashboard from '../dashboard.svg'
import calendar2 from  '../calendar2.svg'
import { useAuth } from '../auth/AuthContext'

export default function ResponsiveSidebar({ page, mode, colors, drawerWidth, apps, setApps, HandleTheme, ToggleWidth, showSidebar, ToggleMode, setDrawerWidth, slide }) {
    const [welcome, setWelcome] = useState(false)
    const [releaseMenu, setReleaseMenu] = useState(false)
    const navigate = useNavigate()
    const ConditionalWrapper = ({condition, wrapper, children }) =>
        condition ? wrapper(children) : children
    const { currentUser } = useAuth()

    return (
        <>
        {showSidebar || drawerWidth > 50 ?
                <div className={`h-full text-center overflow-hidden flex flex-col justify-between bg-[#2c2c2c]`}
                >
                <List sx={{ml:'-4px'}}
                >
                    <ListItem disablePadding>
                        <ListItemButton onClick={ToggleWidth} sx={{mt:'8px'}}>
                            <ListItemIcon sx={{ml: '-3px'}}>
                                <img src={adlogo} style={{height:25, width:25, filter: `invert(1)`}} className={`${drawerWidth > 100 ? 'animate-spin-once' : ''}`}/>
                            </ListItemIcon>
                                { drawerWidth === 240 ?
                                <>
                                    <ListItemText sx={{color:'white'}}><p className={`${drawerWidth > 60 ? 'animate-fadeIn' : ''}`}>AuditDeploy</p></ListItemText>
                                    <ListItemIcon sx={{mr:-4}}>
                                        <div className={drawerWidth > 60 ? 'animate-fadeIn' : ''}>
                                            <BsChevronLeft style={{color:'white'}}/>
                                        </div>
                                    </ListItemIcon>
                                </>
                                :
                                <></>
                                }
                        </ListItemButton>
                    </ListItem>
                    <Divider sx={{mt: 1}}/>
                    {/* <ListItem>
                        { drawerWidth < 240 ?
                            <Tooltip title="Search" placement="right">
                                <ListItem disablePadding sx={{mt:'1vh'}}>
                                        <IconButton size="small">
                                            <BsSearch style={{color:'white', marginLeft:'-3px'}} size={18} />
                                        </IconButton>
                                </ListItem>
                            </Tooltip>
                        :
                            <div className='px-2 flex items-center w-full h-[40px] bg-neutral-600 bg-opacity-80 rounded-md'
                            >
                                <input className='p-1 text-white placeholder-white bg-transparent'
                                    placeholder="Search..."
                                />
                                <button className='mr-1'>
                                    <BsSearch style={{color:'white'}} size={17} />
                                </button>
                            </div>
                        }
                    </ListItem> */}
                    <ListItem disablePadding sx={page==='Get Started' ? {background:colors[4]} : {}}>
                        <Tooltip title={drawerWidth < 240 ? "Home":null} placement="right">
                            <ListItemButton onClick={()=>navigate('/')}
                            >
                                <ListItemIcon>
                                    <BsHouse size={20} style={{color:'white', marginLeft:'2px'}} />
                                </ListItemIcon>
                                <ListItemText sx={{color:'white'}} hidden={drawerWidth < 240}>
                                    <Typography variant='body2'>
                                        Home
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                    <ListItem disablePadding sx={page==='Setup' || page==='Release' || page === 'Deploy' ? {background:colors[4]} : {}}>
                        <Tooltip title={drawerWidth < 240 ? "Releases":null} placement="right">
                            
                            <ListItemButton onClick={()=>navigate('/releases')}
                            >
                                <ListItemIcon>
                                    <img src={releases} style={{height:23, width:23, filter:'invert(1)'}}/>
                                </ListItemIcon>
                                <ListItemText sx={{color:'white'}} hidden={drawerWidth < 240}>
                                    <Typography variant='body2'>
                                        Releases
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                    {/* <ListItem disablePadding sx={page==='Dashboard' ? {background:colors[4]} : {}}>
                        <Tooltip title={drawerWidth < 240 ? "Reporting":null} placement="right">
                            <ListItemButton onClick={()=>navigate('/reporting')}
                            >
                                <ListItemIcon>
                                    <HiOutlinePresentationChartBar style={{color:'white', marginLeft:'1px'}} size={22}/>
                                </ListItemIcon>
                                <ListItemText sx={{color:'white'}} hidden={drawerWidth < 240}>
                                    <Typography variant='body2'>
                                        Reporting
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem> */}
                    <ListItem disablePadding sx={page==='Calendar' ? {background:colors[4]} : {}}>
                        <Tooltip title={drawerWidth < 240 ? "Calendar":null} placement="right">
                            <ListItemButton onClick={()=>navigate('/calendar')}
                            >
                                <ListItemIcon>
                                    <img src={calendar2} className='w-[24px] h-[24px] ml-[-2px]' style={{color:'white'}} />
                                </ListItemIcon>
                                <ListItemText sx={{color:'white'}} hidden={drawerWidth < 240}>
                                    <Typography variant='body2'>
                                        Calendar
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                    <ListItem disablePadding sx={page==='Environments' ? {background:colors[4]} : {}}>
                        <Tooltip title={drawerWidth < 240 ? "Environments":null} placement="right">
                            <ListItemButton onClick={()=>navigate('/envs')}
                            >
                                <ListItemIcon>
                                    <img src={dashboard} className='w-[24px] h-[24px]' style={{color:'white'}} />
                                </ListItemIcon>
                                <ListItemText sx={{color:'white'}} hidden={drawerWidth < 240}>
                                    <Typography variant='body2'>
                                        Environments
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                    { currentUser.uid === 'DiPFGg0htzYKcAAbaDWCbKwijyW2' || currentUser.uid === 'E3t8CbTV5rS0L6NuWc5M37WDq4I2' || currentUser.uid === 'xvubwCQeLBYzVSEVMY569btxm6d2' || currentUser.uid === 'edhnSB63t6OHewdFuHQXbRp4O7x1'
                        ||currentUser.uid === 'oG1E74nAFugeVyg8WrGW6TJsqvP2' ?
                        <ListItem disablePadding sx={page==='Audits' ? {background:colors[4]} : {}}>
                            <Tooltip title={drawerWidth < 240 ? "Audit Trail":null} placement="right">
                                <ListItemButton onClick={()=>navigate('/audit')}
                                >
                                    <ListItemIcon>
                                    <AiOutlineFileSearch size={25} style={{color:'white'}} />
                                    </ListItemIcon>
                                    <ListItemText sx={{color:'white'}} hidden={drawerWidth < 240}>
                                        <Typography variant='body2'>
                                            Audit Trail
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </Tooltip>
                        </ListItem>
                    :
                        <></>
                    }
                    {/* <ListItem disablePadding sx={page==='Catalog' ? {background:colors[4]} : {}}>
                        <Tooltip title={drawerWidth < 240 ? "Catalog":null} placement="right">
                            <ListItemButton onClick={()=>navigate('/catalog')}>
                                <ListItemIcon>
                                    <img src={catalog} style={{height:25, width:25,filter:'invert(1)'}}/>
                                </ListItemIcon>
                                <ListItemText sx={{color:'white'}} hidden={drawerWidth < 240}>
                                    <Typography variant='body2'>
                                        Catalog
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem> */}
                    {/* <ListItem disablePadding sx={page==='Artifacts' ? {background:colors[4]} : {}}>
                        <Tooltip title={drawerWidth < 240 ? "Artifacts":null} placement="right">
                            <ListItemButton onClick={()=>navigate('/artifacts')}>
                                <ListItemIcon>
                                    <CiViewList size={25} style={{color:'white'}} />
                                </ListItemIcon>
                                <ListItemText sx={{color:'white'}} hidden={drawerWidth < 240}>
                                    <Typography variant='body2'>
                                        Artifacts
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem> */}
                </List>
                <Box
                >
                    <List>
                        <ListItem disablePadding>
                            <Tooltip title={drawerWidth < 240 ? "Apps":null} placement="right">
                                <ListItemButton onClick={()=>setApps(!apps)}>
                                    <ListItemIcon>
                                        <TbGridDots style={{color:'white'}} size={20}/>
                                    </ListItemIcon>
                                    <ListItemText sx={{color:'white'}} hidden={drawerWidth < 240}>
                                        <Typography variant='body2'>
                                            Apps
                                        </Typography>
                                    </ListItemText>
                                    {!apps ? <BsChevronUp style={{color:'white'}} /> : <BsChevronDown style={{color:'white'}} />}
                                </ListItemButton>
                            </Tooltip>
                        </ListItem>
                        { drawerWidth === 240 ? 
                            <Collapse in={apps} timeout="auto" sx={{background:colors[4]}}>
                                <List disablePadding sx={{ml: 3}}>
                                    <ListItem disablePadding>
                                        <ListItemButton
                                            onClick={()=>window.open('https://www.getbuilder.io','_blank')}
                                        >
                                            <img src={builder} style={{height: 25, width: 26}}
                                                />
                                            <Typography sx={{position:'absolute', left:'70px',color:'white'}} variant="body2">Builder</Typography>
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Collapse>
                            :
                            <Collapse in={apps} timeout="auto" sx={{background:colors[4]}}>
                                <div className='w-max ml-[12px] my-2 hover:cursor-pointer'
                                    onClick={()=>window.open('https://www.getbuilder.io','_blank')}
                                >
                                    <img src={builder} style={{height: 24, width: 26}}
                                    />
                                </div>
                            </Collapse>
                        }
                        <ListItem disablePadding
                        >
                            <Tooltip title={drawerWidth < 240 ? "Settings":null} placement="right">
                                <ListItemButton onClick={()=>navigate('/settings')}>
                                    <ListItemIcon>
                                        <BsGear style={{color:'white'}} size={20}/>
                                    </ListItemIcon>
                                    <ListItemText sx={{color:'white'}} hidden={drawerWidth < 240}>
                                        <Typography variant='body2'>
                                            Settings
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </Tooltip>
                        </ListItem>
                        <ListItem disablePadding
                        >
                            <Tooltip title={drawerWidth < 240 ? "Support":null} placement="right">
                                <ListItemButton
                                    onClick={()=>window.open('https://www.auditdeploy.com/contact','_blank')}
                                >
                                    <ListItemIcon>
                                        <BsHeadset style={{color:'white'}} size={20}/>
                                    </ListItemIcon>
                                    <ListItemText sx={{color:'white'}} hidden={drawerWidth < 240}>
                                        <Typography variant='body2'>
                                            Support
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </Tooltip>
                        </ListItem>
                        {/* <ListItem sx={{margin:'0 0 1vh 0'}} disablePadding>
                            <Tooltip title={drawerWidth < 240 ? "Mode":null} placement="right">
                                <ListItemButton onClick={ToggleMode}>
                                    { drawerWidth === 240 ?
                                        <Box sx={{ display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}
                                        >
                                            <BsSun size={20} style={{color:colors[6]}}/>
                                            <Switch size="small" checked={mode === 'dark'} onChange={HandleTheme}/>
                                            <BsMoon size={15} style={{color:colors[5]}}/>
                                        </Box>
                                    : mode === 'dark' ?
                                        <BsMoon size={15} style={{color:colors[5], marginLeft:'3px'}}/>
                                    :
                                        <BsSun size={20} style={{color:colors[6]}}/>
                                    }
                                </ListItemButton>
                            </Tooltip>
                        </ListItem> */}
                    </List>
                </Box>
            </div>
        :
        <></>
        }
        </>
    )
}