import React, {useState, useEffect} from 'react'
import { BsArrowRight, BsArrowUpRightSquare, BsCheckCircle, BsCheckCircleFill, BsChevronLeft, BsChevronRight, BsCircle, BsCircleFill, BsPerson, BsPersonFill, BsSearch, BsX, BsXCircle } from 'react-icons/bs'
import slide1 from '../1.svg'
import slide2 from '../2.svg'
import slide3 from '../3.svg'
import slide4 from '../4.svg'
import slide5 from '../5.svg'
import { useNavigate } from 'react-router'
import netgraph from '../netgraph.png'
import cubes from '../cubes (1).png'
import builder from '../candidates/builder-orange.svg'
import axios from 'axios'
import CreateRelease from './CreateRelease'
import releases from '../AD_Release-logo_icon-black (2).png'
import { AiOutlineFileSearch } from 'react-icons/ai'
import { useAuth } from '../auth/AuthContext'
import { TbMapCheck } from "react-icons/tb";
import { BsArrowCounterclockwise } from 'react-icons/bs'

const DATE_OPTIONS =
    { year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', hour12: false };

export default function Home({setPage, colors, mode, drawerWidth, loading, userId, setTour }) {
    const [active, setActive] = useState(0)
    const [manifests, setManifests] = useState([])
    const [showManifests, setShowManifests] = useState(false)
    const [showCreate, setShowCreate] = useState(false)
    const [width, setWidth] = useState(1800)
    const [counter, setCounter] = useState(0)
    const [builderCandidates, setBuilderCandidates] = useState([])
    const [userData, setUserData] = useState({})
    const [progressCount, setProgressCount] = useState(['account'])
    const slides = Array.from({length:5},(v,i)=>i)
    const navigate = useNavigate()
    const timeNow = new Date()
    const days = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const {currentUser} = useAuth()
    useEffect(()=>{
        if(!loading)
        {
            let progressCheck = [...progressCount]

            currentUser.getIdToken().then((token) => {
                axios.get('/v2/manifests', {
                    headers: {
                    'authorization': `Bearer ${token}`
                    }
                })
                .then(res=>{
                    setManifests(res.data.reverse())
                    if(res.data.length > 0)
                    {
                        progressCheck.push('manifests')
                    }
                })

                axios.get(`/v2/profile`, {
                    headers: {
                    'authorization': `Bearer ${token}`
                    }
                })
                .then(res=>{
                    setUserData(res.data)
                    if(res.data?.tourComplete)
                    {
                        progressCheck.push('tourComplete')
                    }
                })

                axios.get('/v2/builderCandidates', {
                    params: {
                        uid: currentUser.uid
                    },
                    headers: {
                    'authorization': `Bearer ${token}`
                    }
                })
                .then(res=>{
                    const sorted = res.data.sort((a, b) => {
                        const typeA = a.candidateName
                        const typeB = b.candidateName
                        if (typeA < typeB) {
                            return -1;
                        }
                        if (typeA > typeB) {
                            return 1;
                        }
                        
                        return 0;
                    });
                    if(sorted.length > 0)
                    {
                        progressCheck.push('builder')
                    }
                    setBuilderCandidates(sorted)
                })
            })

            setProgressCount(progressCheck)
        }
        setWidth(window.innerWidth)
        setPage('Get Started')
        setTour(false)
    },[])

    function scrollRight() {
        var elmnt = document.getElementById(`cardcontainer`);
        elmnt.scrollLeft += Math.ceil(.17*width)
        setCounter(counter+1)
    }
    function scrollLeft() {
        var elmnt = document.getElementById(`cardcontainer`);
        elmnt.scrollLeft -= Math.ceil(.17*width)
        setCounter(counter-1)
    }

    return (
        <div className='pt-4 w-5/6 xl:w-3/4 mx-auto min-h-[100vh] pb-12'>
            <h2 className='text-center font-semibold pt-4'>{timeNow.toDateString()}</h2>
            <h2 className='text-center font-semibold text-3xl py-1'>
                {timeNow.getHours() >= 18 ? 'Good evening' : timeNow.getHours() >= 12 ? 'Good afternoon' : timeNow.getHours() >= 5 ? 'Good morning' : 'Good evening'},
                &nbsp;{userId}
            </h2>
            <div className='grid grid-cols-3 items-end w-5/6 mx-auto px-2 mt-4'>
                { progressCount.length < 4 ? 
                <>
                    <p className='font-semibold'>Steps to get started</p>
                    <div>
                        <p className=' text-center mt-6'>Release Software like a Pro</p>
                        <div className='flex justify-center gap-4 items-center mx-auto'>
                            <div className='rounded-full h-[10px] w-3/4 bg-gray-200'>
                                <div className={`rounded-full w-${progressCount.length}/4 bg-sky-400 h-full`} />
                            </div>
                            <p className='text-sm'>{progressCount.length}/4</p>
                        </div>
                    </div>
                </>
                :
                <>
                    <p className='font-semibold'></p>
                    <div>
                        <p className=' text-center mt-6'>🏅&nbsp;Release Pro</p>
                    </div>
                </>
                }
            </div>
            <div className=' grid grid-cols-12 items-center gap-2'>
                { counter > 0 ?
                <button className='text-slate-500 p-1 bg-neutral-50 border border-slate-500 rounded-full ml-auto block'
                    onClick={scrollLeft}
                ><BsChevronLeft size={24} /></button>
                :
                <button className='text-slate-300 p-1 bg-neutral-100 border border-slate-300 rounded-full ml-auto block'
                ><BsChevronLeft size={24} /></button>
                }
                <ul className='flex overflow-hidden mt-2 gap-[1vw] py-2 col-span-10 min-h-[20vh] scroll-smooth pl-2'
                    id="cardcontainer"
                >
                    <li className='rounded-md p-4 bg-[#D0D9CD55] text-neutral-400 min-w-[16vw] max-w-[16vw] relative'
                        id="card0"
                    >
                        <BsPersonFill size={32} style={{marginBottom:'2vh'}} />
                        <button className='absolute top-2 right-2 text-emerald-600'><BsCheckCircleFill size={22} /></button>
                        <p className='font-semibold flex items-center gap-2 my-1'>Create an Account<span className='text-neutral-500'><BsArrowRight /></span></p>
                        <p className='text-xs'>Create your user account to link Release with Builder.</p>
                    </li>
                    <li className={`${userData?.tourComplete ? 'cursor-pointer rounded-md p-4 bg-[#D0D9CD55] text-neutral-400 min-w-[16vw] max-w-[16vw] relative':'cursor-pointer hover:scale-105 hover:shadow-md transition duration-500 rounded-md p-4 bg-green-100 min-w-[16vw] max-w-[16vw] relative'}`}
                        onClick={()=>{setTour(true);navigate('/tour')}}
                        id="card1"
                    >
                        <TbMapCheck size={32} style={{marginBottom:'2vh'}} />
                        {userData?.tourComplete ? <button className='absolute top-2 right-2 text-neutral-800'><BsArrowCounterclockwise size={22} /></button> : <></>}
                        <p className='font-semibold flex items-center gap-2 my-1'>Take platform tour<span className='text-neutral-500'><BsArrowRight /></span></p>
                        <p className='text-xs'>Learn the release platform to deploy applications with ease.</p>
                    </li>
                    <li className={`${manifests.length > 0 ? 'cursor-pointer rounded-md p-4 bg-[#D0D9CD55] text-neutral-400 min-w-[16vw] max-w-[16vw] relative':'cursor-pointer hover:scale-105 hover:shadow-md transition duration-500 rounded-md p-4 bg-green-100 min-w-[16vw] max-w-[16vw] relative'}`}
                        onClick={()=>setShowCreate(true)}
                        id="card1"
                    >
                        <img src={releases} height={36} width={36} className='rounded-full mb-4'/>
                        {manifests.length > 0 ? <button className='absolute top-2 right-2 text-neutral-800'><BsArrowCounterclockwise size={22} /></button> : <></>}
                        <p className='font-semibold flex items-center gap-2 my-1'>Create and Schedule a Release<span className='text-neutral-500'><BsArrowRight /></span></p>
                        <p className='text-xs'>Set up a Release to customize candidates and host your application.</p>
                    </li>
                    <li className={`${builderCandidates.length > 0 ? 'cursor-pointer rounded-md p-4 bg-[#D0D9CD55] text-neutral-400 min-w-[16vw] max-w-[16vw] relative':'cursor-pointer hover:scale-105 hover:shadow-md transition duration-500 rounded-md p-4 bg-green-100 min-w-[16vw] max-w-[16vw] relative'}`}
                        id="card2"
                        onClick={()=>window.open('https://www.getbuilder.io/#/builds','_blank')}
                    >
                        <img src={builder} height={36} width={36} className='rounded-full mb-4'/>
                        {builderCandidates.length > 0 ? <button className='absolute top-2 right-2 text-emerald-600'><BsCheckCircleFill size={22} /></button> : <></>}
                        <p className='font-semibold flex items-center gap-2 my-1'>Upload your builds using Builder<span className='text-neutral-500'><BsArrowRight /></span></p>
                        <p className='text-xs'>Learn more!</p>
                    </li>
                    {/* <li className='cursor-pointer hover:scale-105 hover:shadow-md transition duration-500 rounded-md p-4 bg-green-100 min-w-[16vw] max-w-[16vw] relative'
                        id="card3"
                        onClick={()=>navigate('/reporting')}
                    >
                        <img src={cubes} height={36} width={36} className='rounded-full mb-4'/>
                        <button className='absolute top-2 right-2 text-neutral-400'><BsX size={22} /></button>
                        <p className='font-semibold flex items-center gap-2 my-1'>Monitor Deployments<span className='text-neutral-500'><BsArrowRight /></span></p>
                        <p className='text-xs'>Deploy your Release to any environment and track its progress in real-time.</p>
                    </li>
                    <li className='cursor-pointer hover:scale-105 hover:shadow-md transition duration-500 rounded-md p-4 bg-green-100 min-w-[16vw] max-w-[16vw] relative'
                        id="card4"
                        onClick={()=>navigate('/audit')}
                    >
                        <AiOutlineFileSearch size={32} style={{marginBottom:'2vh'}} />
                        <button className='absolute top-2 right-2 text-neutral-400'><BsX size={22} /></button>
                        <p className='font-semibold flex items-center gap-2 my-1'>Audit Trails<span className='text-neutral-500'><BsArrowRight /></span></p>
                        <p className='text-xs'>Deploy your Release to any environment you choose.</p>
                    </li> */}
                    <li className='p-4 bg-none min-w-[16vw] max-w-[16vw] relative'
                        id="card5"
                    ></li>
                </ul>
                { counter < 1 ?
                <button className='text-slate-500 p-1 bg-neutral-50 border border-slate-500 rounded-full w-max'
                    onClick={scrollRight}
                ><BsChevronRight size={24} /></button>
                :
                <button className='text-slate-200 p-1 bg-neutral-100 border border-slate-200 rounded-full w-max'
                ><BsChevronRight size={24} /></button>
                }
            </div>
            <div className='grid grid-cols-2 gap-2 mt-4 w-5/6 mx-auto'>
                <div className='border border-neutral-200 rounded-md p-3 cursor-pointer bg-white'
                    onClick={()=>navigate('releases')}
                >
                    <p className='text-lg'>Releases</p>
                    <table className='w-full mt-2'>
                        <thead className='border-b-[1px]'>
                            <th className='text-left text-neutral-500 text-sm font-normal'>Name</th>
                            <th className='text-left text-neutral-500 text-sm font-normal'>Status</th>
                            <th className='text-left text-neutral-500 text-sm font-normal'>Scheduled Date</th>
                        </thead>
                        <tbody className={`divide-y font-light text-neutral-700`}>
                            { manifests.map((man,id)=>
                                id > 8 ?
                                <></>
                                :
                                <tr>
                                    <td className='py-1'>{man.description}</td>
                                    <td className='py-1'>{man.status}</td>
                                    <td className='py-1'>{new Date(man.scheduledTimeStamp).toLocaleDateString()}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                    <div className='border border-neutral-200 rounded-md p-3 bg-white'>
                        <div id="headertext">
                            <p className='text-lg'>Builds</p>
                            { builderCandidates.length > 0 ?
                            <>
                                <table className='w-full mt-2'>
                                    <thead className='border-b-[1px]'>
                                        <th className='text-left text-neutral-500 text-sm font-normal'>Name</th>
                                        <th className='text-left text-neutral-500 text-sm font-normal'>Artifacts</th>
                                    </thead>
                                    <tbody className={`divide-y font-light text-neutral-700`}>
                                        { builderCandidates.map((man,id)=>
                                            id > 8 ?
                                            <></>
                                            :
                                            <tr>
                                                <td className='py-1'>{man.candidateName}</td>
                                                <td className='py-1'>{man.artifacts?.length}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </>
                            :
                            <a href="https://www.getbuilder.io" target="_blank" className='h-max'>
                                <div>
                                    <p className='text-center p-4'>Go to <span className='text-sky-500'>getbuilder.io</span> to create your first build</p>
                                </div>
                            
                            </a>
                            }
                        </div>
                    </div>
            </div>
            <CreateRelease show={showCreate} setShow={setShowCreate} mode={mode} DATE_OPTIONS={DATE_OPTIONS} setManifests={false} username={userId} homepage={true}
            />
        </div>
    )
}