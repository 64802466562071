import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Box, Typography, TextField, Button, Paper, Snackbar, Alert, Divider, Link } from '@mui/material'
import GoogleButton from 'react-google-button'
import GithubButton from 'react-github-login-button'
import adlogo from '../release_by_auditdeploy.png'
import EmailButton from './Email Button/EmailButton'
import Bgimg from '../screen2023-07-12-234131.png'

export default function Login({ TryLogin, googleLogin, githubLogin, colors, userId, setUserId, passwd, setPasswd, warning, setWarning, currentUser }) {

    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser)
        navigate("/");
    }, [currentUser, navigate])

    return (
        <Box className='login' sx={{ background: colors[4], overflow: 'hidden', height: '99.9vh' }}>
            <img src={Bgimg} style={{ objectFit: 'cover', height: "100vh", width: '100vw', zIndex: "-1", position: 'fixed' }} />
            <div className='grid place-items-center w-full h-full px-4'>
                <div className='p-4 w-full md:w-1/2 lg:w-1/3 2xl:w-1/4 bg-white rounded-md'>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center' }}>
                    <img src={adlogo} alt="Audit Deploy logo" />
                    {/* <div>
                        <p className='text-3xl'><b>Audit</b>Deploy</p>
                        <p className='text-gray-500'>Login to your account</p>
                    </div> */}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }} component='form' onSubmit={(e) => TryLogin(e)}>
                    <TextField placeholder='Email' value={userId} onChange={(e) => setUserId(e.target.value)} />
                    <TextField placeholder='Password' type="password" value={passwd} onChange={(e) => { setPasswd(e.target.value) }} />
                    <button className='bg-neutral-800 rounded-full w-full py-2 text-white hover:bg-opacity-80 hover:shadow-lg transition duration-300'
                        type="submit"
                        onClick={(e) => TryLogin(e)}
                    >
                        Login</button>
                    {/* <Link component="button" variant="body2" onClick={() => { navigate('/signup') }}>Sign Up</Link> */}
                    <Divider></Divider>

                    <GoogleButton style={{ width: 'auto', color: 'black' }} type="light" onClick={() => { googleLogin() }} />
                    <GithubButton style={{ width: "auto", color: 'black' }} type='light' onClick={() => { githubLogin() }} />
                    <EmailButton style={{ width: 'auto', color: 'black' }} type="light" onClick={() => { navigate('/signup') }} />
                    </Box>
                </div>
            </div>
            <Snackbar open={warning !== null}
                onClose={() => setWarning(null)}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity='error'>{warning}</Alert>
            </Snackbar>
        </Box>
    )
}