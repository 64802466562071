import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress, Divider, IconButton, Tooltip, Dialog, DialogTitle, DialogContent } from '@mui/material'
import {SiKubernetes} from 'react-icons/si'
import { HiOutlineGlobeAlt } from 'react-icons/hi'
import { BsArrowCounterclockwise, BsInfoCircle, BsInfoCircleFill} from "react-icons/bs";
import PulseDot from 'react-pulse-dot'
import 'react-pulse-dot/dist/index.css'
import DeploymentLog from "./DeploymentLog";
import TourNavigation from "./TourNavigation";
import { Tour } from "antd";
import { HiOutlineCommandLine } from "react-icons/hi2";

export default function DeployCandidates({ order, can, started, phase, ind, deployObj, loaded, index, group, candidates, IconArray, mode, slide, setSlide, tour, counter, deployed})
{
    const [view, setView] = useState(false)
    const [localId, setLocalId] = useState(0)
    const [localStatus, setLocalStatus] = useState('')
    const [openLog, setOpenLog] = useState(false)
    const [liveCan, setLiveCan] = useState({})
    const ConditionalWrapper = ({condition, wrapper, children }) =>
    condition ? wrapper(children) : children
    const handleClose = () => {setOpenLog(false)};

    // useEffect(()=>{
    //     if(deployObj.length > 0)
    //     {
    //         deployObj[phase].nodes.map((node,i)=>{
    //             if(node.name === `${can.candidateIcon}${phase}${ind}${index}`)
    //             {
    //                 setLocalId(i)
    //             }
    //         })
    //         displayStatus = order.indexOf(candidates[can.candidateIcon].name)
    //     }
    // },[deployObj])

    useEffect(()=>{
        const found = deployed.findIndex(dep=>dep.candidateName === can.name)
        if(found >= 0)
        {
            setLiveCan(deployed[found])
        }
    },[deployed, counter])

    return (
        <>
                <div>
                    <div className={`flex items-center justify-between w-full ${mode === 'dark' ? 'hover:bg-neutral-800' : 'hover:bg-gray-100'} px-5 py-2 cursor-pointer`}
                    >
                        <div className="flex gap-3 items-center">
                            <img src={can?.builderType ? can.builderType === 'Node' ? IconArray[88] : can.builderType === 'Go' ? IconArray[89]
                                : can.builderType === 'Python' ? IconArray[90] : can.builderType === 'C' ? IconArray[91]
                                : can.builderType === 'Java' ? IconArray[92] : can.builderType === 'Npm' ? IconArray[93] : IconArray[81]
                                : IconArray[can.icon]} style={{height:22, width:22}} />
                            <p className="text-sm">
                                {can.name}
                            </p>
                        </div>
                        <div className="flex items-center">
                            <div className="flex items-center justify-end gap-0.5">
                                { can.status === 'Done' || can.status === 'Failed' ?
                                    <>
                                        <Tooltip title={`Status: ${can.status === 'Done' ? 'OK' : 'Failed'}`} placement="left">
                                            <PulseDot color={can.status === 'Done' ? 'success' : 'danger'} style={{fontSize:'0.7em', marginRight:'0.5vw'}} />
                                        </Tooltip>
                                    </>
                                :
                                    <></>
                                }
                                { liveCan.liveUrl ? 
                                    Array.isArray(liveCan.liveUrl) ?
                                        liveCan.liveUrl.map((live, index) => {
                                            if(live.name === 'http') {
                                                return <a href={live.url} key={index} target='_blank' className="text-sm text-blue-500 w-1/2 truncate">{live.url}</a>
                                            }
                                        })
                                    :
                                        <a href={liveCan.liveUrl} target='_blank' className="text-sm text-blue-500 w-1/2 truncate">{liveCan.liveUrl}</a> : <></>
                                }
                                {can.status !== 'Done' && can.status !== 'Failed' && started ?
                                    <p className="text-sm animate-pulse">{can.status}</p>
                                : can.status === 'Done' || can.status === 'Failed' ?
                                    <>
                                        <Tooltip title="Redeploy" placement="left">
                                            <IconButton size="small" onClick={(e)=>{e.stopPropagation();setLocalStatus('Redeploying')}}>
                                                <BsArrowCounterclockwise />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                :
                                    <></>
                                }
                            </div>
                            <div className={`ml-2 ${view ? 'text-blue-500' : ''}`}
                                onClick={()=>setOpenLog(true)}
                            >
                                <HiOutlineCommandLine size={20} />
                            </div>
                        </div>
                    </div>
                </div>
            {view ?
                <DeploymentLog title={can.name} details={can.artifactId}
                    started={started} status={can.status} candidate={can} 
                />
            :
                <></>
            }
            <Dialog
                open={openLog}
                onClose={() => handleClose()}
                fullWidth={true}
                maxWidth={'md'}
                PaperProps={{
                    style: {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    },
                  }}
            >
                <DeploymentLog title={can.name} details={can.artifactId}
                    started={started} status={can.status} candidate={can} handleClose={handleClose}
                />
            </Dialog>
        </>           
    )
}