import { Alert, Box, Dialog, Divider, IconButton, Snackbar, Typography } from '@mui/material'
import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import { BsChevronDoubleRight, BsCircleFill, BsX } from 'react-icons/bs'
import PhaseView from './PhaseView'
import { useNavigate } from 'react-router'
import { DragDropContext } from 'react-beautiful-dnd'
import {IconArray} from '../candidates/CandidateImport'
import CandidateAdd from './CandidateAdd'
import TourNavigation from './TourNavigation'
import { useAuth } from '../auth/AuthContext'
import { Tour } from 'antd'

export default function Build({current, drawerWidth, DATE_OPTIONS, manifests, currentUser, setPage, colors, mode, loading, setCurrent, manifestInfo, userId, username, slide, setSlide, tour, setTour}) {
    const [manifest, setManifest] = useState({})
    const [currentManifest, setCurrentManifest] = useState({})
    const [allDetails, setAllDetails] = useState([])
    const [buildLoading, setBuildLoading] = useState(true)
    const [feedback, setFeedback] = useState(null)
    const [showAdd, setShowAdd] = useState(null)
    const [groups, setGroups] = useState(0)
    const [show, setShow] = useState(false)
    const [manifestLength, setManifestLength] = useState(0)
    const [allCan, setAllCan] = useState([])
    const [completed, setCompleted] = useState(false)    
    const [globalCandidates, setGlobalCandidates] = useState([])
    const [allowEdit, setAllowEdit] = useState(true)
    const [adding, setAdding] = useState(false)
    const [addToPhase, setAddToPhase] = useState(0)
    const [addToGroup, setAddToGroup] = useState(0)
    const [candidatesGrouped, setCandidatesGrouped] = useState([])
    const [userCandidates, setUserCandidates] = useState([])
    const [userFiltered, setUserFiltered] = useState([])
    const [filteredGlobalCan, setFilteredGlobalCan] = useState([])
    const [dragging, setDragging] = useState(false)
    const [search, setSearch] = useState('')
    const [phases, setPhases] = useState([])
    const [mGroups, setMGroups] = useState([])
    const [mCandidates, setMCandidates] = useState([])
    const [edges, setEdges] = useState([])
    const [nodes, setNodes] = useState([])
    const [showAddPhase, setShowAddPhase] = useState(false)
    const [sample, setSample] = useState(false)
    const ref1 = useRef(null)
    const ref2 = useRef(null)

    const navigate = useNavigate()
    const ConditionalWrapper = ({condition, wrapper, children }) =>
        condition ? wrapper(children) : children

    useEffect(()=>{
        if(!loading)
        {
            const manifestTemp = sessionStorage.getItem('current')

            currentUser.getIdToken().then((token) => {
                axios.get('/v2/manifests/'+manifestTemp, {
                    headers: {
                    'authorization': `Bearer ${token}`
                    }
                })
                .then(res=>{
                    let nodesTemp = []
                    let phasesTemp = []
                    let groupsTemp = []
                    let candidatesTemp = []
                    const sorted = res.data.nodes.sort((a, b) => {
                        const typeA = a.name
                        const typeB = b.name
                        if (typeA < typeB) {
                            return -1;
                        }
                        if (typeA > typeB) {
                            return 1;
                        }
                        return 0;
                    });
                    sorted.forEach((item)=>{
                        nodesTemp.push(item)
                        if(item.type === 'phase-start')
                        {
                            phasesTemp.push(item)
                        }       
                        else if (item.type === "group-start")
                        {
                            groupsTemp.push(item)
                        }
                        else if (item.type === 'candidate')
                        {
                            candidatesTemp.push(item)
                        }
                    })
                    let edgesTemp = []
                    res.data.edges.forEach((item)=>{
                        const fromIndex = nodesTemp.findIndex((node)=>node._id === item._from)
                        const toIndex = nodesTemp.findIndex((node)=>node._id === item._to)
                        let temp = {from: fromIndex, to: toIndex}
                        edgesTemp.push(temp)
                    })
                    setNodes(nodesTemp)
                    setEdges(edgesTemp)
                    setPhases(phasesTemp)
                    setMGroups(groupsTemp)
                    setMCandidates(candidatesTemp)
                    setManifest(res.data.meta)
                    setCurrent(manifestTemp)
                    setBuildLoading(false)
                })
            })

            currentUser.getIdToken().then((token) => {
                axios.get('/v2/catalog', {
                    headers: {
                      'authorization': `Bearer ${token}`
                    }
                })
                .then(res=>{
                    const sorted = res.data.collections.sort((a, b) => {
                        const typeA = a.name
                        const typeB = b.name
                        if (typeA < typeB) {
                            return -1;
                        }
                        if (typeA > typeB) {
                            return 1;
                        }
                        
                        return 0;
                    });
                    setGlobalCandidates(sorted)
                    setFilteredGlobalCan(sorted)
                    
                })
            })
            
            currentUser.getIdToken().then((token) => {
                axios.get('/v2/user/catalog', {
                    headers: {
                      'authorization': `Bearer ${token}`
                    }
                })
                .then(res=>{
                    const sorted = res.data.sort((a, b) => {
                        const typeA = a.candidateName
                        const typeB = b.candidateName
                        if (typeA < typeB) {
                            return -1;
                        }
                        if (typeA > typeB) {
                            return 1;
                        }
                        
                        return 0;
                    });

                    setUserCandidates(sorted)
                    setUserFiltered(sorted)
                })
            })

            if(sessionStorage.getItem('id'))
            {
                const donecheck = JSON.parse(sessionStorage.getItem('id'))
                if (donecheck.status === 'Completed')
                {
                    setCompleted(true)
                }
            }
            setSlide(3)
            setPage('Release')
        }
    },[loading])

    useEffect(()=>{
        if(search !== '' && search !== undefined)
        {
            let filterTemp = []
            candidatesGrouped.forEach((item,id)=>item.name.toLowerCase().includes(search.toLowerCase()) ? filterTemp.push(item) : <></>)
            setFilteredGlobalCan(filterTemp)
            let userFilterTemp = []
            userCandidates.forEach((item,id)=>item.name.toLowerCase().includes(search.toLowerCase()) ? userFilterTemp.push(item) : <></>)
            setUserFiltered(userFilterTemp)
        }
        else
        {
            setFilteredGlobalCan([...globalCandidates])
            setUserFiltered([...userCandidates])
        }
    },[search])

    useEffect(()=>{
        if(globalCandidates.length > 0)
        {
            let groupTemp = []
            globalCandidates.forEach((item,id)=>{
                if(item.packageType.includes('Builder'))
                {
                    groupTemp.push(item)
                }
                else if(id > 0)
                {
                    if(item.name !== globalCandidates[id-1].name)
                    {
                        groupTemp.push({name: item.name, icon: item.icon, packageType: item.packageType})
                    }
                }
                else
                {
                    groupTemp.push({name: item.name, icon: item.icon, packageType: item.packageType})
                }
            })
            setCandidatesGrouped(groupTemp)
        }
    },[globalCandidates])

    useEffect(()=>{
        if(manifest?.status !== 'In Progress' && manifest?.status !== 'Approved')
        {
            setAllowEdit(false)
        }
        else
        {
            setAllowEdit(true)
        }
    },[manifest])

    useEffect(() => {
        setAddToGroup(0)
    }, [addToPhase])

    function AddCandidate(item, builder) {
        const addedTime = new Date()
        const filteredGroup = mGroups.filter((group) => group.phase === phases[addToPhase].name.split('-')[0])
        let postObj = {
            candidate: {
                name: item.name,
                type: "candidate",
                hash: item.hash,
                version: item.version,
                artifactRepo: item.artifactRepo,
                icon: item.icon,
                dateAdded: new Date().getTime(),
                group: filteredGroup[addToGroup].name.split('-')[0],
                phase: phases[addToPhase].name.split('-')[0],
                DockerRepositoryTag: item.DockerRepositoryTag ? item.DockerRepositoryTag : null,
                containerPort: item.containerPort ? item.containerPort : null,
                servicePort: item.servicePort ? item.servicePort : null,
                envs: item.envs ? item.envs : [],
                dependsOnCandidates: item.dependsOnCandidates ? item.dependsOnCandidates : [],
                ports: item.ports
            },
            groupId: filteredGroup[addToGroup]._id
        }
        if(item.ProjectName)
        {
            postObj = {
                candidate: {
                    name: item.ProjectName,
                    type: "candidate",
                    builderType: item.ProjectType,
                    hash: item.MasterGitHash,
                    version: item.ArtifactName,
                    artifactRepo: item.ArtifactLocation,
                    icon: 81,
                    dateAdded: new Date().getTime(),
                    group: filteredGroup[addToGroup].name.split('-')[0],
                    phase: phases[addToPhase].name.split('-')[0],
                    DockerRepositoryTag: item.DockerRepositoryTag,
                    envs: item.ApplicationEnvs ? item.ApplicationEnvs : [],
                    dependsOnCandidates: item.ApplicationDependencies ? item.ApplicationDependencies : [],
                    ports: item.Ports,
                },
                groupId: filteredGroup[addToGroup]._id
            }
        }
        currentUser.getIdToken().then((token) => {
            axios.post(`/v2/manifests/${current}/add`, postObj, {
                headers: {
                  'authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                setShow(false)
    
                RefreshManifest()
                setFeedback('Successfully added candidate.')
            })
        })
    }

    function addSampleCandidates() {
        const filteredGroup = mGroups.filter((group) => group.phase === phases[addToPhase].name.split('-')[0])
        const addedTime = new Date()
        const postObj = {
            dateAdded: new Date().getTime(),
            group: filteredGroup[addToGroup].name.split('-')[0],
            phase: phases[addToPhase].name.split('-')[0],
            groupId: filteredGroup[addToGroup]._id
        }

        currentUser.getIdToken().then((token) => {
            axios.post(`/v2/manifests/${current}/addSample`, postObj, {
                headers: {
                'authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                setSample(false)
                RefreshManifest()
                setFeedback('Successfully added candidates.')
            })
        })
    }

    async function RefreshManifest() {
        var token = await currentUser.getIdToken();

        axios.get('/v2/manifests/'+current, {
            headers: {
            'authorization': `Bearer ${token}`
            }
        })
        .then(res=>{

            let phasesTemp = []
            let groupsTemp = []
            let candidatesTemp = []
            const sorted = res.data.nodes.sort((a, b) => {
                const typeA = a.name
                const typeB = b.name
                if (typeA < typeB) {
                    return -1;
                }
                if (typeA > typeB) {
                    return 1;
                }
                return 0;
            });
            sorted.forEach((item)=>{
                if(item.type === 'phase-start')
                {
                    phasesTemp.push(item)
                }       
                else if (item.type === "group-start")
                {
                    groupsTemp.push(item)
                }
                else if (item.type === 'candidate')
                {
                    candidatesTemp.push(item)
                }
            })
            setPhases(phasesTemp)
            setMGroups(groupsTemp)
            setMCandidates(candidatesTemp)
            CloseAdding()
        })
    }

    function CloseAdding() {
        setShowAdd(null)
        setAddToPhase(0)
        setAddToGroup(0)
        setSample(false)
    }

    function AddPhase() {
        let allManifests = [...allDetails]
        let manifestTemp = currentManifest
        const newNumber = manifestTemp.phaseView.length+1
        manifestTemp.phaseView.push({
            "name": "PHASE"+newNumber,
            "groups": [
                {
                "name": "Group 1",
                "candidates": []
                }
            ]
        })
        const manifestIndex = allManifests.findIndex((item,id)=>item.id == sessionStorage.getItem('current'))
        allManifests.splice(manifestIndex,1,manifestTemp)
        const postTemp = {
            key: "manifestDetails",
            user: 0,
            data: allManifests
        }
        setAdding(true)
        axios.put('/manifestdetails',postTemp)
        .then(res=>{
            axios.get('/manifestdetails')
            .then(res=>{
                const phaseTemp = res.data.results[0][`release-service`].data
                const currentTemp = phaseTemp.find((item,ind)=>item.id == sessionStorage.getItem('current'))
                setCurrentManifest(currentTemp)
                setAdding(false)
            })
            .catch(err=>{
                setAdding(false)
            })
        })
    }

    const reorder = (list, startIndex, endIndex, sInd) => {
        const result = list;
        // const [removed] = result.groups[sInd].candidates.splice(startIndex, 1);
        // result.groups[sInd].candidates.splice(endIndex, 0, removed);
        return result;
      };
     
      const reordergroup = (list, startIndex, endIndex) => {
        const result = list;
        const [removed] = result.groups.splice(startIndex, 1);
        result.groups.splice(endIndex, 0, removed);
        return result;
      };
      const movegroup = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = source;
        const destClone = destination;
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        destClone.splice(droppableDestination.index, 0, removed);
        const result = [sourceClone,destClone]
        return result;
      };
     
      /**
       * Moves an item from one list to another list.
       */
      const move = (source, destination, sInd, dInd) => {
        const firstGroup = mGroups[parseInt(sInd)]
        const firstGroupName = firstGroup.name.split('-')[0]
        const groupedCandidates = mCandidates.filter((item)=>item.group === firstGroupName)
        const movedCandidate = groupedCandidates[source.index]
        const endGroup = mGroups[parseInt(dInd)].name.split('-')[0]
        console.log(movedCandidate,endGroup)
        const postObj = {
            candidate: movedCandidate,
            group: endGroup
        }
        
        currentUser.getIdToken().then((token) => {
            axios.post(`/v2/manifests/${current}/edit/move`, postObj, {
                headers: {
                'authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                RefreshManifest()
            })
        })

        // const [removed] = sourceClone.splice(droppableSource.index, 1);
        // destClone.splice(droppableDestination.index, 0, removed);
        // const result = [sourceClone,destClone];
        // return result;
      };

    function onDragEnd(result) {
        setDragging(!dragging)
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
          return;
        }
        let manifesttemp = currentManifest
        
        //sInd: index of source group
        //dInd: index of destination group
        //sind2: index of source phase
        //dind2: index of destination phase
        const split1 = source.droppableId.split(',')
        const split2 = destination.droppableId.split(',')
        const sInd2 = split1[1]
        const sInd = split1[0]
        const dInd = split2[0]
        const dInd2 = split2[1]
   
        //dropped in same container then reorder
        if (sInd === dInd && sInd2 === dInd2)
        {
            // reorder(manifesttemp.phaseView[sInd], source.index, destination.index,sInd2);
        }
       //otherwise call move
        else
        {
            move(source, destination, sInd2, dInd2);
        }
        if(tour)
        {
            setSlide(slide+1)
        }
      }

    const getListStyle = isDraggingOver => ({
        // background: isDraggingOver && mode === 'light' ? 'linear-gradient(#2997ff55, white)' : isDraggingOver && mode === 'dark' ? 'linear-gradient(#010304, #2997ff55)' : 'none',
        boxShadow: isDraggingOver ? '0 0 10px 2px #2997ff55' : 'none',
        padding: 0,
        width: '100%',
        minHeight: 50
    })
    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        ...draggableStyle
    })

    return (
        <>
        {!buildLoading ?
        <>
            <div className={`pt-24 w-3/4 ${drawerWidth < 240 ? 'mx-auto' : 'ml-[280px]'} min-h-[100vh]`}>
                <>
                    <div className='fixed w-full h-[5vh] mt-[-4vh] backdrop-blur-sm z-10'></div>
                    <div className={`flex justify-between items-start h-[206px] mt-[-1vh] ${mode === 'dark' ? 'bg-zinc-800 border-neutral-600' : 'bg-[#fff]'} fixed w-3/4 shadow-md rounded-sm z-50 p-2 border`}>
                        <div className='w-full'>
                            <Box sx={{display:'flex', gap:'1vw'}}>
                                {/* <GraphView edges={edges} phases={phases} groups={mGroups} mNodes={nodes} manifest={manifest} colors={colors} IconArray={IconArray} candidates={candidates}
                                /> */}
                                <div className='flex flex-col w-full gap-4 pl-4'>
                                    <div className='flex justify-between items-center'>
                                        <div>
                                            <p className='text-neutral-500 text-sm'>Name</p>
                                            <p className='font-semibold'>{manifest.description}</p>
                                        </div>
                                        <Tour open={tour && slide === 3} onClose={()=>setSlide(slide+1)}
                                        type="primary"
                                            steps={[
                                                {
                                                    title: 'Build Release',
                                                    description: `You can sort your release into any order of groups and phases that you like. You can also edit the names of the groups.`,
                                                    target: () => ref2.current
                                                },
                                                {
                                                    title: 'Preview Deployment',
                                                    description: `Once your release is setup the way you want, you're ready to deploy!`,
                                                    target: () => ref1.current
                                                }
                                            ]}
                                            nextButtonProps={<button className='bg-blue'>Next</button>}
                                            placement={slide == 3 ? 'top' :'left'}
                                        />
                                        { phases.length > 0 ?
                                            <>
                                                <button onClick={()=>navigate(`/deploy/${current}`)} ref={ref1}
                                                    className={`w-max border ${mode === 'dark' ? 'border-neutral-600' : 'border-neutral-300'} shadow-sm flex items-center px-4 py-1.5 rounded-sm hover:shadow-lg active:bg-opacity-100 transition duration-300`}
                                                >
                                                    Deployment Preview &nbsp; <BsChevronDoubleRight />
                                                </button>
                                            </>
                                        :
                                            <></>
                                        }
                                    </div>
                                    <div className='flex gap-12'>
                                        <div>
                                            <p className='text-neutral-500 text-sm'>Scheduled Release Date</p>
                                            <p className='font-semibold'>{new Date(manifest.scheduledTimeStamp).toLocaleDateString([], DATE_OPTIONS)}</p>
                                        </div>
                                        <div>
                                            <p className='text-neutral-500 text-sm'>Status</p>
                                            <p className='flex items-center gap-2 font-semibold'>
                                                {manifest?.status}
                                                <span className='animate-pulse'><BsCircleFill color="#00bc7f" size={15} /></span>
                                            </p>
                                        </div>
                                    </div>
                                    <Divider/>
                                    <div className='flex w-full justify-between items-end'>
                                        <div className='flex gap-12'>
                                            <div>
                                                <p className='text-neutral-500 text-sm'>Phases</p>
                                                <p className='font-semibold'>{phases.length}</p>
                                            </div>
                                            <div>
                                                <p className='text-neutral-500 text-sm'>Groups</p>
                                                <p className='font-semibold'>{mGroups.length}</p>
                                            </div>
                                            <div>
                                                <p className='text-neutral-500 text-sm'>Candidates</p>
                                                <p className='font-semibold'>{mCandidates.length}</p>
                                            </div>
                                        </div>
                                        { allowEdit ?
                                        <div className='flex gap-2'>
                                            <button onClick={()=>setShow(true)}
                                                className={` bg-neutral-800 text-white h-max py-1 px-4 shadow-sm rounded-md transition duration-300 hover:shadow-lg`}
                                            >
                                                Add Candidates
                                            </button>
                                            <button onClick={() => setShowAddPhase(true)}
                                                className={`${mode === 'dark' ? 'border-neutral-600' : 'border-neutral-300'}  h-max py-1 px-4 shadow-sm rounded-md hover:bg-opacity-40 border transition duration-300 hover:shadow-lg`}
                                            >
                                                {adding ? <>Adding Phase...</> : <>Add Phase</>}
                                            </button>
                                        </div>
                                        :
                                        <></>
                                        }
                                    </div>
                                </div>
                            </Box>
                        </div>
                    </div> 
                </>
                {phases.length > 0 ?
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className='mt-[220px] pb-12' ref={ref2}>
                            {
                                phases.map((item,id)=>
                                    <PhaseView DATE_OPTIONS={DATE_OPTIONS} colors={colors} item={item} id={id} currentManifest={currentManifest} nodes={nodes}
                                    setCurrentManifest={setCurrentManifest} manifestLength={manifestLength} getItemStyle={getItemStyle} manifest={manifest}
                                    getListStyle={getListStyle} key={item.name} completed={completed} candidates={globalCandidates} IconArray={IconArray} allowEdit={allowEdit}
                                    AddPhase={AddPhase} allDetails={allDetails} setAllDetails={setAllDetails} dragging={dragging} mode={mode} RefreshManifest={RefreshManifest}
                                    username={username} mGroups={mGroups} mCandidates={mCandidates} showAddPhase={showAddPhase} setShowAddPhase={setShowAddPhase}
                                    />
                                )
                            }
                        </div>
                        
                    </DragDropContext>
                :
                <>
                    <Box sx={{textAlign:'center', mt:'220px'}}>
                        <Typography variant="subtitle2">This manifest is empty.</Typography>
                        <br />
                        <Typography variant="subtitle2">Click the 'Add Candidates' button to start building!</Typography>
                    </Box>
                </>
                }
            </div>
        </>
            :
            <></>
        }
        <Dialog open={show} onClose={()=>setShow(false)} maxWidth='xl' fullWidth>
            <div className={`flex ${mode === 'dark' ? 'text-white' :'text-black'} px-5 pt-3 pb-1 items-center gap-8 border-b-2`}>
                <div className={`text-xl`}>Add Candidates</div>
                <div className='border rounded-md border-neutral-300 text-base w-1/4'>
                    <input
                        type='text' placeholder='Search for Candidates'
                        value={search}
                        onChange={(e)=>setSearch(e.target.value)}
                        onSubmit={(e)=>e.preventDefault()}
                        className='w-full px-4 py-1.5'
                    />
                </div>
                <button onClick={()=>{setShowAdd(true); setSample(true)}}
                    className={`w-max border ${mode === 'dark' ? 'border-neutral-600' : 'border-neutral-300'} shadow-sm flex items-center px-4 py-1.5 rounded-md hover:shadow-md active:bg-opacity-100 transition duration-300`}
                >
                    Add Demo Candidates
                </button>
                <IconButton onClick={()=>setShow(false)} sx={{ml:'auto'}}><BsX size={36} /></IconButton>
            </div>
            <div className='px-5 py-2 overflow-y-auto'>
                <div className='divide-y w-full border border-gray-200 rounded-sm border-t-0'>
                    {userFiltered.map((item,id)=>{
                        return (
                        <CandidateAdd
                        item={item} num={id} allCan={allCan} globalCandidate={false} setShowAdd={setShowAdd} completed={completed} IconArray={IconArray} candidates={userCandidates}
                        filteredCan={userFiltered} user={currentUser} />
                        )
                    }
                    )}
                </div>
                <div className='divide-y w-full border border-gray-200 rounded-sm my-4 border-t-0'>
                    {filteredGlobalCan.map((item,id)=>{
                        return (
                        <CandidateAdd
                        item={item} num={id} allCan={allCan} globalCandidate={true} setShowAdd={setShowAdd} completed={completed} IconArray={IconArray} candidates={globalCandidates}
                        filteredCan={filteredGlobalCan} user={currentUser} />
                        )
                    }
                    )}
                </div>
            </div>
        </Dialog>
        <Dialog open={showAdd !== null } onClose={CloseAdding} maxWidth='sm'>
            <>
                <div className='p-7 text-center flex flex-col gap-5 items-center min-w-[16vw] mx-auto'>
                    { globalCandidates[0] && showAdd ? 
                        <p className='font-semibold text-xl'>Add {showAdd.name} to</p>
                        :
                        <></>
                    }
                    <div className='flex items-center gap-5'>
                        <p>Phase</p>
                        { phases.length <= 1 ?
                        <select className='p-1 border border-gray-300 rounded-md' disabled
                        >
                            <option className='p-1'>PHASE1</option>
                        </select>
                        :
                        <select className='p-1 border border-gray-300 rounded-md'
                            onChange={(e)=>setAddToPhase(e.target.value)}
                        >
                            { phases.map((item,id)=>
                            <option className='p-1' key={item.name} value={id}>{item.name.split('-')[0]}</option>
                            )}
                        </select>
                        }
                    </div>
                    <div className='flex items-center gap-5'>
                        <p>Group</p>
                        { phases.length <= 1 && !phases[0] ?
                            <select className='p-1 border border-gray-300 rounded-md' disabled
                            >
                                <option className='p-1'>Group 1</option>
                            </select>
                        : groups.length <= 1 ?
                            <select className='p-1 border border-gray-300 rounded-md' disabled
                            >
                                <option className='p-1'>Group 1</option>
                            </select>
                        :
                        <select className='p-1 border border-gray-300 rounded-md'
                            onChange={(e)=> setAddToGroup(e.target.value)}
                        >
                            { mGroups.filter((group) => group.phase === phases[addToPhase].name.split('-')[0]).map((item,id)=>
                               <option className='p-1' key={item.name} value={id}>{item.label}</option>
                            )}
                        </select>
                        }
                    </div>
                </div>
                <button onClick={()=>sample ? addSampleCandidates() : AddCandidate(showAdd)}
                    className='bg-neutral-800 shadow-md text-white rounded-md w-1/4 px-3 py-1 block mx-auto mb-7 hover:scale-105 hover:shadow-xl transition duration-200'
                >
                Add</button>
            </>
        </Dialog>
        <Snackbar open={feedback !== null}
        onClose={()=>setFeedback(null)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert severity='success'>{feedback}</Alert>
        </Snackbar>
        </>
    )

}
