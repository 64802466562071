import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Box, Paper, IconButton, InputBase, Container, Card, CardContent, Typography, Grid, Divider, Toolbar, Dialog, Tooltip } from '@mui/material'
import { SiKubernetes, SiPostgresql, SiRabbitmq, SiNginx, SiGradle, SiElastic, SiAlpinelinux } from "react-icons/si";
import { CiGlobe } from 'react-icons/ci';
import { BsBoxArrowUpRight, BsSearch, BsX, BsFillGrid3X3GapFill, BsList } from 'react-icons/bs'
import cube from '../cube (1).png'
import chatlogo from '../ChatGPT-Logo.png'
import {IconArray} from '../candidates/CandidateImport'
import { candidateTypes } from '../constants';
import CandidatePageListItems from './CandidatePageListItems';
import { HiLink } from 'react-icons/hi2';

export default function CatalogListItem({card, item, id, IconArray, candidateImages, setCard, mode, user }) {
    const [showCandidates, setShowCandidates] = useState(false)
    const [localCandidates, setLocalCandidates] = useState([])
    const [transition, setTransition] = useState(false)
    
    // useEffect(()=>{
    //     if(candidateImages.length > 0)
    //     {
    //         const startIndex = candidateImages.findIndex((can,ind) => can.icon === item.icon)
    //         const endIndex = candidateImages.findLastIndex((can,ind) => can.icon === item.icon)
    //         setLocalCandidates(candidateImages.slice(startIndex, endIndex+1))
    //     }
    // },[candidateImages])

    useEffect(()=>{
        if(showCandidates)
        {
            user.getIdToken().then((token) => {
                axios.get(`/v2/candidates/${item.name}`, {
                    headers: {
                    'authorization': `Bearer ${token}`
                    }
                })
                .then(res=>{
                    setLocalCandidates(res.data)
                })
            })
        }
    },[showCandidates])

    useEffect(()=>{
        if(transition)
        {
            setCard(false)
            setTimeout(()=>{
                setTransition(false)
            },800)
        }
    },[transition])

    useEffect(()=>{
        if(card)
        {
            setShowCandidates(false)
        }
    },[card])

    return(
        <>
            { card ?
                <Grid item lg={4} md={6} sm={12}>
                    <div
                        className={`group border ${mode === 'dark' ? 'border-neutral-600 hover:bg-slate-600 bg-neutral-800 bg-opacity-20' : 'border-gray-200 hover:bg-sky-100 bg-white'} rounded-md shadow-sm h-full flex flex-col justify-between hover:bg-opacity-40 transition duration-200 cursor-pointer`}
                        onClick={()=>setShowCandidates(!showCandidates)}
                    >
                        <div className='p-2 h-full'>
                        <div>
                            <div className='flex items-center justify-between gap-3 w-full'>
                                <div className='h-max w-max flex items-center gap-3'>
                                    {item.icon !== undefined ?
                                        <div className='p-1'>
                                            <img style={{height:30, width:30}} src={IconArray[item.icon]} />
                                        </div>
                                        :
                                        <CiGlobe style={{height:30, width:30}}/>
                                    }  
                                    <p>{item?.displayName}</p>
                                </div>
                                {
                                    item.link ? 
                                    <Tooltip title={item.link} placement="left">
                                        <a href={item.link} target="blank">
                                            <button className='hover:bg-gray-200 p-2 rounded-full transition duration-200 hover:shadow-md hover:text-[#2997ff]'>
                                                <HiLink size={18}/></button>
                                        </a>
                                    </Tooltip>
                                    :
                                    <></>
                                }
                            </div>
                            <hr className='my-2' />
                        </div>
                        <p className='text-sm px-1 line-clamp-4'>
                            {item.description}
                            </p>
                        </div>
                        <div className={`${mode === 'dark' ? 'bg-zinc-800 group-hover:bg-slate-600' : 'bg-gray-200 group-hover:bg-slate-300'} px-3 py-1.5 transition duration-200`}>
                            <p className='text-sm font-medium'>Artifacts</p>
                        </div>
                    </div>
                </Grid>
            :
                <Grid item xs={12}>
                    <Paper elevation={showCandidates ? 6 : 1}>
                        <div className={`grid grid-cols-12 items-center w-full gap-1 px-2 py-2 ${mode === 'dark' ? 'bg-zinc-900' : ''}`}>
                            <div className=''>
                                {item.icon !== undefined ?
                                    <img style={{height:24,width:24}} src={IconArray[item.icon]}
                                    />
                                    :
                                    <CiGlobe size={30}/>
                                }
                            </div>
                            <div className='col-span-2'>
                                <Typography variant="subtitle2">{item?.displayName}</Typography>
                            </div>
                            <div className='col-span-8'>
                                <Typography variant="body2" noWrap>{item.description}</Typography>
                            </div>
                            {
                                item.link ? 
                                <Tooltip title={item.link} placement="left">
                                    <a href={item.link} target="blank" className='text-right'>
                                        <button className='hover:bg-gray-200 p-2 rounded-full transition duration-200 hover:shadow-md hover:text-[#2997ff]'>
                                            <HiLink size={18}/></button>
                                    </a>
                                </Tooltip>
                                :
                                <></>
                            }
                        </div>
                        <div className={`${mode === 'dark' ? 'bg-zinc-800 hover:bg-slate-600' : 'bg-gray-200 hover:bg-slate-300'} px-3 py-1.5 cursor-pointer transition duration-200`}
                            onClick={()=>setShowCandidates(!showCandidates)}
                        >
                            <p className='text-sm font-medium'>Artifacts</p>
                        </div>
                        {showCandidates ?
                            <div className={`divide-y ${mode === 'dark' ? 'bg-zinc-900' : ''} ${transition ? 'bg-sky-200 bg-opacity-80' : ''} transition duration-700`}>
                                <div className={`font-medium w-full grid grid-cols-10 text-sm`}>
                                    <p className='font-medium col-span-2 px-2 py-1'>Artifact ID</p>
                                    <p className='py-1'>Version</p>
                                    <p className='py-1'>Created At</p>
                                    <p className='font-medium col-span-3 px-2 py-1'>Artifact Repo</p>
                                    <p className='font-medium break-words col-span-3 px-2 py-1'>Source Repo</p>
                                </div>
                                { localCandidates.map((can,ind)=>
                                    <div className='w-full grid grid-cols-10 text-sm'>
                                        <p className='col-span-2 px-2 py-1 break-words'>{can.hash}</p>
                                        <p className='py-1'>{can.version}</p>
                                        <p className='py-1'>{new Date(can.createdAt).toLocaleString()}</p>
                                        <p className='col-span-3 px-2 py-1'>{can.artifactRepo}</p>
                                        <p className='break-words col-span-3 px-2 py-1'>{can.sourceCodeRepo}</p>
                                    </div>
                                )}
                            </div>
                        :
                            <></>
                        }
                    </Paper>
                </Grid>
            }
            <Dialog open={card && showCandidates} onClose={()=>setShowCandidates(false)} maxWidth="lg" fullWidth>
                {showCandidates ?
                    <div className='px-5 py-2'>
                        <div className='flex items-center gap-4'>
                            <img style={{height:24,width:24}} src={IconArray[item.icon]} />
                            <p className='font-semibold text-md my-2'>{item.name}</p>
                        </div>
                        <div className={`border ${mode === 'dark' ? 'border-neutral-600 bg-neutral-900' : 'border-neutral-300'} rounded-sm divide-y`}>
                            <div className={`font-medium w-full grid grid-cols-10 text-sm ${mode === 'dark' ? 'bg-neutral-800' : 'bg-slate-200'} rounded-sm`}>
                                <p className='font-medium col-span-2 px-2 py-1'>Artifact ID</p>
                                <p className='py-1'>Version</p>
                                <p className='py-1'>Created At</p>
                                <p className='font-medium col-span-3 px-2 py-1'>Artifact Repo</p>
                                <p className='font-medium break-words col-span-3 px-2 py-1'>Source Repo</p>
                            </div>
                            { localCandidates.map((can,ind)=>
                                <div className='w-full grid grid-cols-10 text-sm'>
                                    <p className='col-span-2 px-2 py-1 break-words'>{can.hash}</p>
                                    <p className='py-1'>{can.version}</p>
                                    <p className='py-1'>{new Date(can.createdAt).toLocaleString()}</p>
                                    <p className='col-span-3 px-2 py-1'>{can.artifactRepo}</p>
                                    <p className='break-words col-span-3 px-2 py-1'>{can.sourceCodeRepo}</p>
                                </div>
                            )}
                        </div>
                    </div>
                :
                    <></>
                }
            </Dialog>
        </>
    )
}