import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Box, Paper, IconButton, InputBase, Container, Card, CardContent, Typography, Grid, Divider, Toolbar, Dialog } from '@mui/material'
import { SiKubernetes, SiPostgresql, SiRabbitmq, SiNginx, SiGradle, SiElastic, SiAlpinelinux } from "react-icons/si";
import { CiGlobe } from 'react-icons/ci';
import { BsBoxArrowUpRight, BsSearch, BsX, BsFillGrid3X3GapFill, BsList } from 'react-icons/bs'
import cube from '../cube (1).png'
import chatlogo from '../ChatGPT-Logo.png'
import {IconArray} from '../candidates/CandidateImport'
import { candidateTypes } from '../constants';
import CatalogListItem from './CatalogListItem';

export default function Catalog({ drawerWidth, setPage, colors, loading, mode, user, userId }) {
    const [candidates, setCandidates] = useState([])
    const [filtered, setFiltered] = useState([])
    const [candidateImages, setCandidateImages] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [card, setCard] = useState(true)
    const [addCandidate, setAddCandidate] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    useEffect(()=>{
        if(!loading)
        {
            // axios.get('/candidates')
            // .then(res=>{
            //     let resTemp = res.data.results[0][`release-service`].data
            //     setCandidateImages(resTemp)
            // })
            // axios.get('/catalog')
            // .then(res=>{
            //     const temp = res.data.results[0][`release-service`].data.sort((a,b)=>{
            //         const nameA = a.name.toLowerCase()
            //         const nameB = b.name.toLowerCase()
            //         if (nameA < nameB)
            //         {
            //             return -1;
            //         }
            //         if (nameA > nameB)
            //         {
            //             return 1;
            //         }
            //         return 0;
            //     })
            //     axios.get('/builderCandidates',{params:{user:userId}})
            //     .then(res=>{
            //         res.data.forEach((item,id)=>{
            //             const tempItem = {
            //                 name: item.project,
            //                 description: `${item?.projectType} ${item.builds[0]?.branch || ''}`,
            //                 artifactId: item.builds[0]?.ArtifactLocation?.split('artifact_')[1] || '',
            //                 artifactRepo: item.builds[0]?.ArtifactLocation || '',
            //                 sourceCodeRepo: item.builds[0]?.GitURL || '',
            //                 createdAt: item.builds[0]?.EndTime || '',
            //                 type: item?.projectType || '',
            //                 icon: 81,
            //                 id: temp.length,
            //                 version: 1,
            //                 packageType: ['Builder']
            //             }
            //             temp.push(tempItem)
            //         })
            //         setCandidates(temp)
            //     })
            //     .catch(err=>{
            //         console.error('Error fetching Builder artifacts')
            //         setCandidates(temp)
            //     })
            //     setFiltered(temp)
            //     setPageLoading(false)
            // })

            user.getIdToken().then((token) => {
                axios.get('/v2/catalog', {
                    headers: {
                      'authorization': `Bearer ${token}`
                    }
                }).then(res=>{
                    const temp = res.data.collections.sort((a,b)=>{
                        const nameA = a.name.toLowerCase()
                        const nameB = b.name.toLowerCase()
                        if (nameA < nameB)
                        {
                            return -1;
                        }
                        if (nameA > nameB)
                        {
                            return 1;
                        }
                        return 0;
                    })
                    setCandidates(temp)
                    setFiltered(temp)
                    setPageLoading(false)
                })
            })

            setPage('Catalog')
        }        
    },[loading])

    useEffect(()=>{
        let searched = []
        candidates.map((item,id)=>{
        JSON.stringify(item).toLowerCase().includes(searchTerm.toLowerCase()) ? searched.push(id) : <></>
    })
    setFiltered(searched)
    },[searchTerm])

    function Search(e) {
        e.preventDefault()
    }

    function AddCatalogItem(e) {
        e.preventDefault()
        // form values
        const name = e.target[0].value
        const packageType = [e.target[1].value]
        const version = e.target[2].value
        const mVersion = e.target[3].value
        const artifactId = e.target[4].value
        const description = e.target[5].value
        const artifactRepo = e.target[6].value
        const sourceCodeRepo = e.target[7].value
        // default values
        const type = "candidate"
        const user = 0
        const createdAt = new Date().getTime()
        const candidateId = candidateImages.length
        const catalogId = candidates.length
        const icon = 76
    }

    return(
        <div className={`pt-24 w-11/12 ${drawerWidth < 240 ? 'mx-auto' : 'ml-[280px]'} min-h-[100vh] flex gap-2`}>
            <div className='w-1/6 px-1 mt-16'>
                <p className='font-semibold'>Type</p>
                <select className='border rounded-md px-2 py-3 mt-1 mb-4'>
                    {candidateTypes.map((item,id)=>
                        <option key={id}>{item}</option>
                    )}
                </select>
                <p className='font-semibold'>Organization</p>
                <select className='border rounded-md px-2 py-3 mt-1 mb-4'>
                    <option>AuditDeploy</option>
                </select>
                <p className='font-semibold'>Tags</p>
                <select className='border rounded-md px-2 py-3 mt-1 mb-4'>
                    <option></option>
                </select>
                <p className='font-semibold'>Environment</p>
                <select className='border rounded-md px-2 py-3 mt-1 mb-4'>
                    <option></option>
                </select>
            </div>
            <div className='w-5/6'>
                <div className='flex items-center justify-between gap-2 mt-2'>
                    <div className='flex items-baseline gap-2'>
                        <Paper elevation={0} onSubmit={(e)=>Search(e)}
                            component="form"
                            sx={{ p: '0 2px', display: 'flex', alignItems: 'center', width: 300, border: `solid 1px ${colors[8]}`, mb: 1}}
                        >
                            <InputBase value={searchTerm} onChange={(e)=>setSearchTerm(e.target.value)}
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search"
                            />
                            {searchTerm === '' ?
                                <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                    <BsSearch size={20} />
                                </IconButton>
                            :
                                <IconButton sx={{ p: '10px' }} aria-label="clear search" onClick={(e)=>setSearchTerm('')}>
                                    <BsX size={20} />
                                </IconButton>
                            }
                        </Paper>
                        {card ?
                        <Box sx={{mr: '10px'}}>
                            <IconButton size="small"><BsFillGrid3X3GapFill style={{color:'#2997ff'}} /></IconButton>
                            <IconButton size="small" onClick={()=>setCard(false)}><BsList /></IconButton>
                        </Box>
                        :
                        <Box sx={{mr: '10px'}}>
                            <IconButton size="small" onClick={()=>setCard(true)}><BsFillGrid3X3GapFill /></IconButton>
                            <IconButton size="small"><BsList style={{color:'#2997ff'}} /></IconButton>
                        </Box>
                        }
                    </div>
                    <button className='bg-neutral-800 text-white px-3 py-1 rounded-md shadow-md hover:bg-neutral-700 transition duration-200'
                        onClick={()=>setAddCandidate(true)}
                    >Add Artifact</button>
                </div>
                <div className='pb-12'>
                { pageLoading ? 
                    <>
                        {candidateTypes.map((type,index)=>
                            <>
                                <p className='font-semibold text-neutral-600 mt-2 mb-1'>{type}</p>
                                <Grid container spacing={1}>
                                    <div className='h-[200px] w-[320px] rounded-md bg-gray-200 animate-pulse mx-1'></div>
                                    <div className='h-[200px] w-[320px] rounded-md bg-gray-200 animate-pulse mx-1'></div>
                                    <div className='h-[200px] w-[320px] rounded-md bg-gray-200 animate-pulse mx-1'></div>
                                    <div className='h-[200px] w-[320px] rounded-md bg-gray-200 animate-pulse mx-1'></div>
                                </Grid>
                            </>
                        )}
                    </>
                :
                    candidateTypes.map((type,index)=>
                        <>
                            {index > 0 ? <hr className='mt-4' /> : <></>}
                            <p className='font-semibold text-neutral-600 mt-2 mb-1'>{type}</p>
                            <Grid container spacing={1}>
                                {candidates.map((item,id)=>
                                    <>
                                    { item.packageType?.includes(type) && ((searchTerm !== '' && filtered.includes(id)) || (searchTerm === '' || searchTerm === undefined)) ?
                                        <CatalogListItem card={card} item={item} id={id} IconArray={IconArray} candidateImages={candidateImages} setCard={setCard}
                                            mode={mode} user={user}
                                        />
                                        :
                                        <></>
                                    }
                                    </>
                                )}
                            </Grid>
                        </>
                    )
                }
                </div>
            </div>
            <Dialog open={addCandidate} onClose={()=>setAddCandidate(false)} maxWidth="md" fullWidth>
                <div className='p-4'>
                    <strong>Add New Artifact</strong>
                    <form id="addCandidate" onSubmit={(e)=>AddCatalogItem(e)} className='grid grid-cols-4 items-center mt-2 gap-3'>
                        <p>Name</p>
                        <input id="name" name="name" required className='border border-gray-400 rounded-md col-span-3 px-2 py-1' placeholder='Artifact Name' />
                        <p>Type</p>
                        <input id="type" name="type" required className='border border-gray-400 rounded-md col-span-3 px-2 py-1' placeholder='Technology Type' />
                        <p>Version</p>
                        <input id="version" name="version" required className='border border-gray-400 rounded-md col-span-3 px-2 py-1' placeholder='Artifact Version' />
                        <p>Major Version</p>
                        <input id="mVersion" name="mVersion" className='border border-gray-400 rounded-md col-span-3 px-2 py-1' placeholder='Major Version' />
                        <p>Artifact ID</p>
                        <input id="id" name="id" required className='border border-gray-400 rounded-md col-span-3 px-2 py-1' placeholder='Artifact ID' />
                        <p>Description</p>
                        <textarea id="description" name="description" required className='border border-gray-400 rounded-md col-span-3 px-2 py-1' placeholder='Description' />
                        <p>Artifact Repo</p>
                        <textarea id="repo" name="repo" required className='border border-gray-400 rounded-md col-span-3 px-2 py-1' placeholder='Artifact Repo' />
                        <p>Source Code</p>
                        <textarea id="sourceRepo" name="sourceRepo" required className='border border-gray-400 rounded-md col-span-3 px-2 py-1' placeholder='Source Repo' />
                        <div className='flex justify-between mt-4 col-span-4'>
                            <button onClick={()=>setAddCandidate(false)}
                                className='border border-neutral-800 px-3 py-1 rounded-md shadow-md hover:bg-red-100 transition duration-200'
                            >Cancel</button>
                            <button onClick={()=>setAddCandidate(false)}
                                className='bg-neutral-800 text-white px-3 py-1 rounded-md shadow-md hover:bg-[#2997ff] transition duration-200'
                            >Add</button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    )
}