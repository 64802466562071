export const candidateTypes = [
    "Infrastructure",
    "Application",
    "Networking",
    "Data Management",
    "Source Control",
    "AI",
    "Server",
    "Analytics",
    "Testing",
    "Project Management",
    "Builder"
]
