import React, { useState, useEffect } from 'react'
import { Alert, Dialog, DialogContent, Snackbar, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router'
import axios from 'axios'
import { useAuth } from '../auth/AuthContext'

export default function Tour({ setTour, slide, setSlide, setPage, drawerWidth, setDrawerWidth, userId, current, setCurrent }) {
    const [description, setDescription] = useState('')
    const [creating, setCreating] = useState(false)
    const [warning, setWarning] = useState(null)

    const navigate = useNavigate()
    const { currentUser } = useAuth()

    useEffect(()=>{
        setPage('Tour')
        setSlide(0)
    },[])

    async function CreateExampleRelease() {
        setCreating(true)
        const timeNow = new Date().getTime()

        var token = await currentUser.getIdToken();

        axios.get('/v2/generateHash', {
            headers: {
              'authorization': `Bearer ${token}`
            }
        })
        .then((hashResponse) => {
            const newId = hashResponse.data
            let formData = {
                dateAdded: timeNow,
                id: newId,
                uid: currentUser.uid,
                description: description,
                scheduledTimeStamp: timeNow,
                status: "In Progress",
                targetEnvironment: 'DEV',
                releaseType: 'SCHEDULED',
                type: "manifest",
                version: '0.0.1'
            }
            setCreating(true)
            setCurrent(newId)
            sessionStorage.setItem('current',newId)
            
            currentUser.getIdToken().then((token) => {
                axios.post(`/v2/createmanifest/${newId}`, formData, {
                    headers: {
                    'authorization': `Bearer ${token}`
                    }
                })
                .then((res) => { 
                    setSlide(slide+1)
                    setCreating(false)
                    setDescription('')
                    navigate(`/release/${newId}`)
                })
                .catch(err=>{
                    setCreating(false)
                    setWarning(`Unable to finalize release ${description}. Please try again later.`)
                })
            })
        })
        .catch(err=>{
            setCreating(false)
            setWarning(`Unable to create release ID for ${description}. Please try again later.`)
        })
    }
    console.log(current)
    return (
        <>
            <div className={`w-5/6 xl:w-3/4 mx-auto`}>
                <div className='w-full h-full grid place-items-center grid-rows-7 min-h-[100vh] pb-12'>
                    <div className={`flex w-3/4 justify-center gap-8 items-center ${slide > 0 ? 'visible' : "invisible"}`}>
                        <button className='text-neutral-500 tracking-wide'
                            onClick={()=>navigate('/')}
                        >Exit</button>
                        <div className='w-[14vw] h-[12px] border border-gray-300 rounded-full bg-gray-200'>
                            <div style={{width:`${slide*2}vw`}} className={`rounded-full h-[11px] bg-sky-400 transition-all duration-200`} />
                        </div>
                        <p className='text-neutral-500 tracking-wider'>{slide}/7</p>
                    </div>
                    <div className={`row-span-6 text-center w-2/3 flex flex-col gap-4 ${slide > 0 ? 'animate-fadeOut h-0[px] hidden' : 'animate-fadeIn'}`} id="slide0">
                        <div className='flex flex-col gap-1 items-center'>
                            <b className='font-medium tracking-wide text-4xl'>Welcome to Release!</b>
                            <b className='mt-4 text-xl text-neutral-600'>The best teams rely on pipelines like Release to deliver their software.</b>
                            <p className='font-light my-6 text-xl text-neutral-600'>Get ready to launch your very first software release and witness it come to life! This tour has been designed to guide you through the process of creating and deploying a real-life application on Kubernetes in minutes.
                            </p>
                        </div>
                        <button className='w-1/2 mx-auto rounded-full border border-neutral-600 hover:border-neutral-50 bg-neutral-800 text-white px-5 py-2 transition-all duration-500'
                            onClick={()=>setSlide(slide+1)}
                        >Start Tour</button>
                    </div>
                    <div className={`row-span-6 text-center w-2/3 flex flex-col gap-4 ${slide === 1 ?'animate-fadeIn' : ' h-[0px] hidden'}`} id="slide0">
                        <div className='flex flex-col gap-1 items-center'>
                            <b className='font-medium tracking-wide text-4xl'>Create a Release</b>
                            <b className='mt-4 text-lg text-neutral-600 mb-4'>First, enter a name for your release.</b>
                            <input value={description}
                                onChange={(e)=>setDescription(e.target.value)}
                                className='rounded-md px-4 py-3 border border-neutral-200 shadow-sm w-1/2 mb-6'
                                placeholder="Name your release"
                            />
                        </div>
                        { description.length < 1 ?
                            <button className='w-1/2 mx-auto rounded-full border border-neutral-300 bg-neutral-200 text-neutral-400 px-5 py-2'
                            >Next</button>
                        :
                            <button className='w-1/2 mx-auto rounded-full border border-neutral-600 hover:border-neutral-50 bg-neutral-800 text-white px-5 py-2 transition-all duration-500'
                                onClick={()=>setSlide(slide+1)}
                            >Next</button>
                        }
                        <button className='w-max mx-auto text-neutral-600 py-2'
                            onClick={()=>setSlide(slide-1)}
                        >Go back</button>
                    </div>
                    <div className={`row-span-6 text-center w-2/3 flex flex-col gap-4 ${slide === 2 ?'animate-fadeIn' : ' h-[0px] hidden'}`} id="slide0">
                        <div className='flex flex-col gap-1 items-center'>
                            <b className='font-medium tracking-wide text-4xl'>Add Candidates</b>
                            <b className=' mt-4 text-lg text-neutral-600'>Candidates are the software images you want to deploy.</b>
                            <p className='font-light text-lg mt-6 text-neutral-600'>Release integrates directly with Builder to deploy your custom software.</p>
                            <p className='font-light text-lg mb-6 text-neutral-600'>In this release, we've added some candidates for you.</p>
                        </div>
                        <button className='w-1/2 mx-auto rounded-full border border-neutral-600 hover:border-neutral-50 bg-neutral-800 text-white px-5 py-2 transition-all duration-500'
                            onClick={()=>setSlide(slide+1)}
                        >Next</button>
                        <button className='w-max mx-auto text-neutral-600 py-2'
                            onClick={()=>setSlide(slide-1)}
                        >Go back</button>
                    </div>
                    <div className={`row-span-6 text-center w-2/3 flex flex-col gap-4 ${slide === 3 ?'animate-fadeIn' : ' h-[0px] hidden'}`} id="slide0">
                        <div className='flex flex-col gap-1 items-center'>
                            <b className='font-medium tracking-wide text-4xl'>Approve Release</b>
                            <b className='mt-4 text-lg text-neutral-600'>Get ready to deploy your software.</b>
                            <p className='font-light my-6 text-lg text-neutral-600'>Make some final changes and watch as your software goes live.</p>
                        </div>
                        { creating ? 
                            <p className='py-3'>Creating your Release...</p>
                        :
                            <button className='w-1/2 mx-auto rounded-full border border-neutral-600 hover:border-neutral-50 bg-neutral-800 text-white px-5 py-2 transition-all duration-500'
                                onClick={CreateExampleRelease}
                            >Next</button>
                        }
                        <button className='w-max mx-auto text-neutral-600 py-2'
                            onClick={()=>setSlide(slide-1)}
                        >Go back</button>
                    </div>
                </div>
                <Snackbar open={warning !== null}
                    onClose={()=>setWarning(null)}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert severity='error'>{warning}</Alert>
                </Snackbar>
            </div>
        </>
    )
}