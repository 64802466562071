import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import { AppBar, Box, Button, Container, Paper, TextField, Toolbar, Typography } from '@mui/material'
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ManifestCard from './components/ManifestCard';
import Navibar from './components/Navibar';
import Build from './components/Build';
import Deploy from './components/Deploy'
import Analytics from './components/Analytics'
import { HostName } from './config'
import axios from 'axios';
import Home from './components/Home';
import Catalog from './components/Catalog';
import CandidateList from './components/CandidateList'
import Signup from './components/Signup';
import GoogleLogin from './components/GoogleLogin';
import Login from './components/Login';
import Register from './components/Register';
import Profile from './components/Profile';
import adlogo from './adlogo.png'
import Calendar from './components/Calendar';
import Audits from './components/Metrics/Audits';
import Settings from './components/Settings';
import Tour from './components/Tour';
import { useAuth } from './auth/AuthContext';
import PrivateRoutes from './auth/PrivateRoutes';
import Environments from './components/Environments';
import { ConfigProvider } from 'antd';

const DATE_OPTIONS =
    { year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', hour12: false };

function App() {
    const { currentUser, emailSignup, emailLogin, googleLogin, githubLogin  } = useAuth();
    const [show, setShow] = useState(false);
    const [paletteNum, setPaletteNum] = useState(0)
    const [mode, setMode] = useState('light')
    const [colors, setColors] = useState([])
    const [preview, setPreview] = useState(false)
    const [apps, setApps] = useState(false)
    const [current, setCurrent] = useState('')
    const [page, setPage] = useState('Deployments')
    const [loading, setLoading] = useState(true)
    const [login, setLogin] = useState(null)
    const theme = useTheme()
    const [manifests, setManifests] = useState([])
    const [drawerWidth, setDrawerWidth] = useState(50)
    const [user, setUser] = useState(null)
    const [userId, setUserId] = useState('')
    const [passwd, setPasswd] = useState('')
    const [builderId, setBuilderId] = useState(0)
    const [manifestInfo, setManifestInfo] = useState({})
    const [warning, setWarning] = useState(null)
    const [tour, setTour] = useState(false)
    const [slide, setSlide] = useState(0)
    const timeNow = new Date()
    const users = ['armond.honore@tekiesoft.com','jake.street@tekiesoft.com','john.potter@tekiesoft.com','katie.harris@tekiesoft.com','sal.stabler@tekiesoft.com']
    const themed = React.useMemo(
      () =>
        createTheme({
          palette: {
            mode,
          },
          components: {
            MuiButton: {
              styleOverrides: {
                root: ({ ownerState }) => ({
                  '&:hover': {
                    background:'#78909c',
                    opacity: 0.5,
                    border: 'solid 1px #d1d3d4'
                  },
                }),
              },
            },
          },
        }),
      [mode],
    );
        
    function HandleTheme() {
      mode === 'dark' ? setMode('light') : setMode('dark')      
    }

    const HandlePalette = (event, newPalette) => {
      setPaletteNum(newPalette);
    };

    useEffect(()=>{
      axios.defaults.headers.common[`user`] = 0
      setLoading(false)
      // if(process.env.REACT_APP_API_URL)
      // {
      //   axios.defaults.baseURL = process.env.REACT_APP_API_URL
      // }
      // else
      // {
      // }
      if(sessionStorage.getItem('user') && sessionStorage.getItem("user") !== undefined)
      {
        sessionStorage.setItem("user",0)
        setLogin(true)
      } 
      if(sessionStorage.getItem('builder') && sessionStorage.getItem("builder") !== undefined)
      {
        setBuilderId(sessionStorage.getItem('builder'))
        setUserId(users[sessionStorage.getItem('builder')])
      } 
      if(sessionStorage.getItem('current') && sessionStorage.getItem("current") !== undefined)
      {
        setCurrent(sessionStorage.getItem('current'))
      } 
      if(window.location.href.split('/')[5])
      {
        sessionStorage.setItem('current', window.location.href.split('/')[5])
        setCurrent(window.location.href.split('/')[5])
      }
      if(sessionStorage.getItem(tour) == true)
      {
        setTour(true)
      }
    },[])

    useEffect(()=>{
      if(user !== null && !login)
      {
        axios.defaults.headers.common[`user`] = user
        sessionStorage.setItem("user",0)
        if(window.location.href.split('/')[5])
        {
          setCurrent(window.location.href.split('/')[5])
          sessionStorage.setItem('current', window.location.href.split('/')[5])
        }
        setLogin(true)
      }
    },[login, user])

   useEffect(()=>{
          if(mode === 'light')
          {
            setColors(['#b0bec5dd','#00a972', '#f3a200','white','#d1d3d422','#010203','white', '#010203dc','#d1d3d4','#2c2c2c', '#f5f5f7', '#f5f5f7'])
          }
          else
          {
            setColors(['#2c2c2c','#00bc7f','#f4b400','rgba(0,0,0,0.08)','rgba(255,255,255,0.02)','#f5f5f5','#2c2c2c10','#f5f5f5fd','#d1d3d488','#2c2c2c44', '#2c2c2c', '#010203'])
          }
   },[mode, paletteNum])

   useEffect(()=>{
        sessionStorage.setItem('tour', tour)
   },[tour])

   useEffect(()=>{
    if(currentUser) {
      currentUser.getIdToken().then((token) => {
        axios.get(`/v2/user/check`, {
          headers: {
            'authorization': `Bearer ${token}`
          }
        }).then(() => {console.log("User verified")}).catch((e) => {console.log(e)})
      })
    }
  },[currentUser])

  async function TryLogin(e) {
    e.preventDefault()
    try {
      const login = await emailLogin(userId, passwd)
      setUser(0)
      setBuilderId(login.user.uid)
      sessionStorage.setItem('builder',0)
    } catch(e) {
      console.log(e)
    }
 }

  //  function TryLogin(e) {
  //     e.preventDefault()
  //     switch(userId) {
  //       case 'armond.honore@tekiesoft.com':
  //           AuditLogin()
  //           setUser(0)
  //           setBuilderId(0)
  //           sessionStorage.setItem('builder',0)
  //           break
  //       case 'armond@auditdeploy.com':
  //           AuditLogin()
  //           setUser(0)
  //           setBuilderId(0)
  //           sessionStorage.setItem('builder',0)
  //           break
  //       case 'jake.street@tekiesoft.com':
  //           AuditLogin()
  //           setUser(0)
  //           setBuilderId(1)
  //           sessionStorage.setItem('builder',1)
  //           break
  //       case 'john.potter@tekiesoft.com':
  //           AuditLogin()
  //           setUser(0)
  //           setBuilderId(2)
  //           sessionStorage.setItem('builder',2)
  //           break
  //       case 'katie.harris@tekiesoft.com':
  //           AuditLogin()
  //           setUser(0)
  //           setBuilderId(3)
  //           sessionStorage.setItem('builder',3)
  //           break
  //       case 'sal.stabler@tekiesoft.com':
  //           AuditLogin()
  //           setUser(0)
  //           setBuilderId(4)
  //           sessionStorage.setItem('builder',4)
  //           break
  //       case 'sal@auditdeploy.com':
  //           AuditLogin()
  //           setUser(0)
  //           setBuilderId(4)
  //           sessionStorage.setItem('builder',4)
  //           break
  //       default:
  //           setWarning('Could not find user details. Please check username and password.')
  //     }
  //  }

    // async function AuditLogin() {
    //   const auditObj = {
    //   username: userId,
    //   uid: currentUser?.uid,
    //   event: `${userId} logged in`,
    //   time: new Date(),
    //   type: "Login"
    //   }
    //   var token = await currentUser.getIdToken();

    //   axios.post('/v2/audit', auditObj, {
    //     headers: {
    //       'authorization': `Bearer ${token}`
    //     }
    //   })
    // }
  //  if (!login)
  //  {
  //       return (
  //           <Login TryLogin={TryLogin} colors={colors} userId={userId} setUserId={setUserId} passwd={passwd} setPasswd={setPasswd} warning={warning} setWarning={setWarning} />
  //       )
  //  }

   return (
    <div class='Appcontainer' style={mode === 'dark' ? {} : {background:colors[4]}}
    >
      <ConfigProvider theme={{ token: { colorPrimary: '#404040' }}}>
        <ThemeProvider theme={themed}>
            <CssBaseline />
            <HashRouter basename='/'>
                {currentUser !== null && <Navibar page={page} mode={mode} drawerWidth={drawerWidth} setDrawerWidth={setDrawerWidth} colors={colors}
                    manifests={manifests} setManifests={setManifests} apps={apps} setMode={setMode} manifestInfo={manifestInfo}
                    setApps={setApps} HandleTheme={HandleTheme} user={currentUser} setUser={setUser} current={current} slide={slide}
                />}
                <Routes>
                    <Route path="/signup" element={
                                  <Register TryLogin={TryLogin} emailSignup={emailSignup} googleLogin={googleLogin} githubLogin={githubLogin} colors={colors} userId={userId} 
                                  setUserId={setUserId} passwd={passwd} setPasswd={setPasswd} warning={warning} 
                                  setWarning={setWarning} currentUser={currentUser} setUser={setUser} setBuilderId={setBuilderId} /> } 
                    />
                    <Route path="/login" element={
                                  <Login TryLogin={TryLogin} googleLogin={googleLogin} githubLogin={githubLogin} colors={colors} userId={userId} 
                                  setUserId={setUserId} passwd={passwd} setPasswd={setPasswd} warning={warning} 
                                  setWarning={setWarning} currentUser={currentUser} /> } 
                    />
                    <Route element={<PrivateRoutes/>}>
                      <Route path="/" element={<Home setPage={setPage} colors={colors} mode={mode} drawerWidth={drawerWidth} userId={currentUser?.displayName}
                            setTour={setTour}
                          /> }
                      />
                      <Route path="/tour" element={<Tour setPage={setPage} colors={colors} mode={mode} current={current} setCurrent={setCurrent} currentUser={currentUser}
                            drawerWidth={drawerWidth} setDrawerWidth={setDrawerWidth} userId={currentUser?.displayName} slide={slide} setSlide={setSlide} setTour={setTour}
                            />}
                      />
                      {/* <Route path="/reporting"
                        element={
                            <Analytics drawerWidth={drawerWidth} setPage={setPage} colors={colors} mode={mode} loading={loading} 
                            user={user}
                            />}
                        /> */}
                        <Route path={`release/${current}`} element={
                                    <Build current={current} drawerWidth={drawerWidth} DATE_OPTIONS={DATE_OPTIONS} setPage={setPage} setCurrent={setCurrent}
                                            manifests={manifests} colors={colors} mode={mode} loading={loading} user={user} manifestInfo={manifestInfo}
                                            currentUser={currentUser}
                                            userId={builderId} username={currentUser?.displayName} slide={slide} setSlide={setSlide} tour={tour} setTour={setTour}
                                    />
                            }
                        />
                        <Route path="releases" element={<>
                                    <ManifestCard colors={colors} preview={preview} setPreview={setPreview} timeNow={timeNow} mode={mode} page={page}
                                        setCurrent={setCurrent} manifests={manifests} setManifests={setManifests} setPage={setPage} drawerWidth={drawerWidth}
                                        loading={loading} user={user} username={currentUser?.displayName} slide={slide} setSlide={setSlide} tour={tour}
                                    />
                                </>}
                        />
                        <Route path={`deploy/${current}`} element={<Deploy current={current} drawerWidth={drawerWidth} DATE_OPTIONS={DATE_OPTIONS} setPage={setPage}
                                    manifests={manifests} colors={colors} mode={mode} loading={loading} user={user} setCurrent={setCurrent} userId={builderId}
                                    manifestInfo={manifestInfo} username={currentUser?.displayName} slide={slide} setSlide={setSlide} tour={tour} setTour={setTour}
                                    />}
                        />
                        <Route path="catalog" element={<Catalog drawerWidth={drawerWidth} setPage={setPage} colors={colors} loading={loading} user={user} userId={builderId}
                                mode={mode}
                            />}
                        />
                        <Route path="artifacts" element={<CandidateList drawerWidth={drawerWidth} setPage={setPage} colors={colors} loading={loading} user={user} userId={builderId} />} />
                        <Route path="calendar" element={<Calendar colors={colors} preview={preview} setPreview={setPreview} timeNow={timeNow} mode={mode} page={page}
                                        setCurrent={setCurrent} manifests={manifests} setManifests={setManifests} setPage={setPage} drawerWidth={drawerWidth}
                                        loading={loading} user={user} username={currentUser?.displayName}
                                        />}
                        />
                        { currentUser?.uid === 'DiPFGg0htzYKcAAbaDWCbKwijyW2' || currentUser?.uid === 'E3t8CbTV5rS0L6NuWc5M37WDq4I2' || currentUser?.uid === 'xvubwCQeLBYzVSEVMY569btxm6d2' || currentUser?.uid === 'edhnSB63t6OHewdFuHQXbRp4O7x1'
                        ||currentUser?.uid === 'oG1E74nAFugeVyg8WrGW6TJsqvP2' ?
                        <Route path="audit" element={<Audits mode={mode} page={page} setCurrent={setCurrent} setPage={setPage} drawerWidth={drawerWidth}
                                        loading={loading} user={user} colors={colors}
                                        />}
                        />
                        :
                        <></>
                        }
                        <Route path="envs" element={<Environments mode={mode} page={page} setCurrent={setCurrent} setPage={setPage} drawerWidth={drawerWidth}
                                        loading={loading} user={user} colors={colors} currentUser={currentUser}
                                        />}
                        />
                        <Route path="settings" element={<Settings loading={loading} userId={userId} setPage={setPage} drawerWidth={drawerWidth} />} />
                    </Route>
                </Routes>
            </HashRouter>
          </ThemeProvider>
        </ConfigProvider>
    </div>
    );
}

export default App;
