// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBMu53glTR6OCpSYFbw_zjvw4v_hg-uUhU",
//   authDomain: "builder-5a9cd.firebaseapp.com",
//   projectId: "builder-5a9cd",
//   storageBucket: "builder-5a9cd.appspot.com",
//   messagingSenderId: "2082809382",
//   appId: "1:2082809382:web:5d6cc2782d15285b1928e7",
//   measurementId: "G-MEPYF8TV68"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBMu53glTR6OCpSYFbw_zjvw4v_hg-uUhU",
  authDomain: "builder-5a9cd.firebaseapp.com",
  projectId: "builder-5a9cd",
  storageBucket: "builder-5a9cd.appspot.com",
  messagingSenderId: "2082809382",
  appId: "1:2082809382:web:15141b1273f693d91928e7",
  measurementId: "G-VY1RQ03620"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
