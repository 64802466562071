import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Box, Paper, IconButton, InputBase, Container, Card, CardContent, Typography, Grid, Divider, Toolbar, Dialog } from '@mui/material'
import { SiKubernetes, SiPostgresql, SiRabbitmq, SiNginx, SiGradle, SiElastic, SiAlpinelinux } from "react-icons/si";
import { CiGlobe } from 'react-icons/ci';
import { BsBoxArrowUpRight, BsSearch, BsX, BsFillGrid3X3GapFill, BsList } from 'react-icons/bs'
import cube from '../cube (1).png'
import chatlogo from '../ChatGPT-Logo.png'
import {IconArray} from '../candidates/CandidateImport'
import { candidateTypes } from '../constants';
import CandidatePageListItems from './CandidatePageListItems';

export default function Catalog({drawerWidth, setPage, colors, loading, userId}) {
    const [candidates, setCandidates] = useState([])
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [addCandidate, setAddCandidate] = useState(false)
    const [showAll, setShowAll] = useState(false)

    useEffect(()=>{
        if(!loading)
        {
            axios.get('/candidates')
            .then(res=>{
                let resTemp = res.data.results[0][`release-service`].data
                axios.get('/builderCandidates',{params:{user:userId}})
                .then(res=>{
                    res.data.forEach((item,id)=>{
                        const tempItem = {
                            name: item.project,
                            description: `${item?.projectType} ${item.builds[0]?.branch || ''}`,
                            artifactId: item.builds[0]?.ArtifactLocation?.split('artifact_')[1] || '',
                            artifactRepo: item.builds[0]?.ArtifactLocation || '',
                            sourceCodeRepo: item.builds[0]?.GitURL || '',
                            createdAt: item.builds[0]?.EndTime || '',
                            type: item?.projectType || '',
                            icon: 81,
                            id: resTemp.length,
                            version: 1,
                            packageType: ['Builder']
                        }
                        resTemp.push(tempItem)
                    })
                    setCandidates(resTemp)
                })
                .catch(err=>{
                    console.error('Error fetching Builder artifacts')
                    setCandidates(resTemp)
                })
                let filterTemp = []
                resTemp.forEach((item,id)=>filterTemp.push(id))
                setFiltered(filterTemp)
            })
            setPage('Artifacts')
        }
    },[loading])


    useEffect(()=>{
        let searched = []
        candidates.map((item,id)=>{
            JSON.stringify(item).toLowerCase().includes(searchTerm.toLowerCase()) ? searched.push(id) : <></>
        })
        setFiltered(searched)
    },[searchTerm])

    function Search(e) {
        e.preventDefault()
    }

    return(
        <div className={`pt-24 w-3/4 ${drawerWidth < 240 ? 'mx-auto' : 'ml-[280px]'} min-h-[100vh]`}>
            <div className='flex items-center justify-between gap-2 mt-2'>
                <Paper elevation={0} onSubmit={(e)=>Search(e)}
                    component="form"
                    sx={{ p: '0 2px', display: 'flex', alignItems: 'center', width: 300, border: `solid 1px ${colors[8]}`, mb: 1}}
                >
                    <InputBase value={searchTerm} onChange={(e)=>setSearchTerm(e.target.value)}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search"
                    />
                    {searchTerm === '' ?
                        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                            <BsSearch size={20} />
                        </IconButton>
                    :
                        <IconButton sx={{ p: '10px' }} aria-label="clear search" onClick={(e)=>setSearchTerm('')}>
                            <BsX size={20} />
                        </IconButton>
                    }
                </Paper>
                <button className='bg-neutral-800 text-white px-3 py-1 rounded-md shadow-md hover:bg-neutral-700 transition duration-200'
                    onClick={()=>setAddCandidate(true)}
                >Add Candidate</button>
            </div>
            <div className='flex flex-col gap-1'>
                { showAll ? 
                <button className='border border-neutral-800 px-3 py-1 rounded-md shadow-md hover:bg-neutral-300 transition duration-200 w-max'
                    onClick={()=>setShowAll(!showAll)}
                >Hide All</button>
                :
                <button className='bg-neutral-500 text-white px-3 py-1 rounded-md shadow-md hover:bg-neutral-700 transition duration-200 w-max'
                    onClick={()=>setShowAll(!showAll)}
                >Show All</button>
                }
                { candidateTypes.map((type,index)=>
                    <CandidatePageListItems type={type} candidates={candidates} searchTerm={searchTerm} IconArray={IconArray} filtered={filtered}
                        showAll={showAll}
                    />
                )}
            </div>
            <Dialog open={addCandidate} onClose={()=>setAddCandidate(false)}>
                <div className='p-4'>
                    <strong>Add New Candidate</strong>
                    <div className='grid grid-cols-4 items-center mt-2 gap-2'>
                        <p>Name</p>
                        <input required className='border border-gray-400 rounded-md col-span-3 px-2 py-0.5' placeholder='Candidate Name' />
                        <p>Type</p>
                        <input required className='border border-gray-400 rounded-md col-span-3 px-2 py-0.5' placeholder='Candidate Type' />
                        <p>Version</p>
                        <input required className='border border-gray-400 rounded-md col-span-3 px-2 py-0.5' placeholder='Candidate Version' />
                        <p>Major Version</p>
                        <input className='border border-gray-400 rounded-md col-span-3 px-2 py-0.5' placeholder='Major Version' />
                        <p>Artifact ID</p>
                        <input required className='border border-gray-400 rounded-md col-span-3 px-2 py-0.5' placeholder='Artifact ID' />
                        <p>Description</p>
                        <textarea required className='border border-gray-400 rounded-md col-span-3 px-2 py-0.5' placeholder='Description' />
                        <p>Artifact Repo</p>
                        <textarea required className='border border-gray-400 rounded-md col-span-3 px-2 py-0.5' placeholder='Artifact Repo' />
                        <p>Source Code</p>
                        <textarea required className='border border-gray-400 rounded-md col-span-3 px-2 py-0.5' placeholder='Source Repo' />
                    </div>
                    <div className='flex justify-between mt-4'>
                        <button onClick={()=>setAddCandidate(false)}
                            className='border border-neutral-800 px-3 py-1 rounded-md shadow-md hover:bg-red-100 transition duration-200'
                        >Cancel</button>
                        <button
                            className='bg-neutral-800 text-white px-3 py-1 rounded-md shadow-md hover:bg-[#2997ff] transition duration-200'
                        >Add</button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}