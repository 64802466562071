import alpine from './alpine.svg'
import ansible from './ansible.svg'
import aws from './aws.svg'
import azure from './azure.svg'
import bamboo from './bamboo.svg'
import buddy from './buddy.svg'
import circleci from './circleci.svg'
import codeship from './codeship.svg'
import docker from './docker.svg'
import elastic from './elastic.svg'
import gradle from './gradle.svg'
import kub from './icons8-kubernetes.svg'
import postgre from './icons8-postgresql.svg'
import jenkins from './jenkins.svg'
import nginx from './nginx.svg'
import prometheus from './prometheus2.svg'
import rabbit from './rabbit.svg'
import travisci from './tci.svg'
import terraform from './terraform.svg'
import gitlab from './gitlab.svg'
import teamcity from './TeamCity_Icon.svg'
import appdynamic from './appdynamic.png'
import aqua from './aqua.svg'
import argo from './argo.svg'
import artifactory from './artifactory.svg'
import bitbucket from './bitbucket.svg'
import chatgpt from './chatgpt.svg'
import chef from './chef.png'
import cloud from './cloud.svg'
import consul from './consul.svg'
import datadog from './datadog.svg'
import dynatrace from './dynatrace.svg'
import envoy from './envoy.svg'
import etcd from './etcd.svg'
import github from './githuv.svg'
import grafana from  './grafana.png'
import graphql from './graphql.svg'
import graylog from './graylog.svg'
import harness from './harness.svg'
import helm from './helm.svg'
import honeycomb from './honeycomb.svg'
import istio from './istio.svg'
import jfrog from './jfrog.svg'
import jira from './jira.svg'
import kafka from './kafka.svg'
import lambda from './lambda.svg'
import loggly from './loggly.svg'
import maven from './maven.svg'
import mesos from './mesos.svg'
import mongo from './mongo.svg'
import mssql from './mssql.svg'
import mysql from './mysql.svg'
import nagios from './nagios.svg'
import newrelic from './newrelic.svg'
import nomad from './nomad.svg'
import openshift from './openshift.svg'
import opente from './opente.png'
import packer from './packer.svg'
import pagerduty from './pagerduty.svg'
import puppet from './puppet.svg'
import pytorch from './pytorch.svg'
import rancher from './rancher.svg'
import saltstack from './saltstack.svg'
import semaphore from './semaphore.svg'
import sentry from './sentry.svg'
import snyk from './snyk.png'
import sonarqube from './sonarqube.svg'
import sonatype from './sonatype.png'
import spinnaker from './spinnaker.svg'
import sumo from './sumo.svg'
import sysdif from './sysdif.svg'
import tensor from './tensor.svg'
import twistlock from './twistlock.svg'
import vagrant from './vagrant.svg'
import zookeeper from './zookeeper.svg'
import keras from './keras.svg'
import globe from './globe.png'
import selenium from './Selenium_Logo.png'
import applause from './applause-icon.svg'
import appium from './appium.svg'
import testsigma from './testsigma.png'
import builder from './builder-orange.svg'
import ubuntu from './ubuntu-icon.svg'
import busybox from './BusyBoxLogo.png'
import debian from './debian.png'
import phaseIcon from '../group.png'
import groupIcon from '../supply-chain.png'
import canIcon from '../cubes (1).png'
import python from './python-logo-only.png'
import go from './Go-Logo_Aqua.svg'
import node from './icons8-nodejs.svg'
import java from './java-icon.svg'
import c from './icons8-c-programming.svg'
import npmlogo from './npm.svg'
import influxdb from './influxdb.svg'
import ghost from './ghost.png'
import anythingllm from './anything-llm.png'
import tetris from './tetris.png'
import joomla from './joomla.svg'
import mariadb from './mariadb.svg'
import spring from './spring.svg'
import jetty from './jetty.png'
import tomcat from './apache_tomcat.png'
import jboss_wildfly from './wildfly.png'
import oracle from './oracle.svg'
import lens from './lens.svg'
import arangodb from './arangodb.png'
import code_server from './codeServer.png'
import lets_chat from './lets_chat.png'
import mindsdb from './mindsdb.png'
import redis from './redis.png'
import localai from './localai.png'
import neo4j from './neo4j.png'
import nexus from './nexus.png'
import websphere from './websphere.png'
import phpmyadmin from './phpmyadmin.png'
import vault from './vault.svg'
import chroma from './chroma.png'
import ollama from './ollama.png'
import qdrant from './qdrant.png'
import weaviate from './weaviate.png'
import kdchat from './kd-chat.png'

export const IconArray = [
    //0     1       2      3    4
    alpine, ansible, aws, azure, bamboo,
    //5     6          7       8      9
    buddy, circleci, codeship, docker, elastic,
    //10     11   12      13       14
    gradle, kub, postgre, jenkins, nginx,
    //15        16       17        18        19
    prometheus, rabbit, travisci, terraform, gitlab,
    //20      21          22    23    24
    teamcity, appdynamic, aqua, argo, artifactory,
    //25        26      27      28    29
    bitbucket, chatgpt, chef, cloud, consul,
    //30     31         32     33    34
    datadog, dynatrace, envoy, etcd, github,
    //35     36       37       38       39
    grafana, graphql, graylog, harness, helm,
    //40       41     42     43    44
    honeycomb, istio, jfrog, jira, kafka,
    //45     46     47     48    49
    lambda, loggly, maven, mesos, mongo,
    //50   51     52      53        54
    mssql, mysql, nagios, newrelic, nomad,
    //55       56       57      58        59
    openshift, opente, packer, pagerduty, puppet,
    //60     61       62         63         64
    pytorch, rancher, saltstack, semaphore, sentry,
    //65    66        67       68         69
    snyk, sonarqube, sonatype, spinnaker, sumo,
    //70    71       72         73      74
    sysdif, tensor, twistlock, vagrant, zookeeper,
    //75    76   77         78        79      80
    keras, globe, selenium, applause, appium, testsigma,
    //81     82      83       84      85         86
    builder, ubuntu, busybox, debian, phaseIcon, groupIcon,
    //87     88    89  90     91  92    93          94
    canIcon, node, go, python, c, java, npmlogo, influxdb,
    //95     96          97       98      99       100
    ghost, anythingllm, tetris, joomla, mariadb, spring,
    //101    102        103        104    105     106
    jetty, tomcat, jboss_wildfly, oracle, lens, arangodb,
    //107           108       109     110     111     112
    code_server, lets_chat, mindsdb, redis, localai, neo4j,
    //113     114         115      116     117    118
    nexus, websphere, phpmyadmin, vault, chroma, ollama,
    //119     120      121
    qdrant, weaviate, kdchat
]