import React, { useState, useEffect } from 'react'
import { InputBase, Paper, Table, TableRow, TableCell, TableBody, IconButton, Container,
        Typography, Button, MenuItem, TableHead, Menu, Dialog, DialogTitle, DialogContent,
        Select, InputLabel, FormControl, TextField, Grid, DialogActions, Snackbar, Alert, Card, CardActionArea, CardContent, LinearProgress, Input, Divider, Tooltip } from '@mui/material'
import { BsSearch, BsFilter, BsFillGrid3X3GapFill, BsList, BsTools, BsCalendar, BsCalendar2Event, BsBoxSeam, BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { VscSymbolNamespace } from 'react-icons/vsc'
import { HiOutlineGlobeAmericas, HiOutlineTrash, HiPencilSquare, HiTrash } from 'react-icons/hi2'
import { Box } from '@mui/system';
import { useNavigate } from 'react-router';
import axios from 'axios';
import '../App.css'
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  MonthView,
  Appointments,
  Resources
} from '@devexpress/dx-react-scheduler-material-ui';
import cube from '../cube (1).png'
import CreateRelease from './CreateRelease'
import TourNavigation from './TourNavigation'
import { useAuth } from '../auth/AuthContext'

const DATE_OPTIONS =
    { year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', hour12: false };


export default function ManifestCard({colors, preview, setPreview, mode, setCurrent, manifests, setManifests, setPage, drawerWidth, page, loading, user, username, slide, setSlide, tour}) {
    const [showFilter, setShowFilter] = useState(false)
    const [filterEnv, setDenv] = useState('ALL')
    const [filterStatus, setFilterStatus] = useState('ALL')
    const [manifestDetails, setManifestDetails] = useState([])
    const [filtered, setFiltered] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [warning, setWarning] = useState(false)
    const [view, setView] = useState(1)
    const [show, setShow] = useState(false)
    const [showEdit, setShowEdit] = useState(null)
    const [disableAll, setDisableAll] = useState(false)
    const [appointments, setAppointments] = useState([])
    const [currentCandidate, setCurrentCandidate] = useState(null)
    const [destination, setDestination] = useState('')
    const timeNow = new Date().getTime()
    const ConditionalWrapper = ({condition, wrapper, children }) =>
        condition ? wrapper(children) : children
    const { currentUser } = useAuth()


    const navigate = useNavigate()

    useEffect(()=>{
        if(!loading)
        {
            currentUser.getIdToken().then((token) => {
                axios.get('/v2/manifests', {
                    headers: {
                      'authorization': `Bearer ${token}`
                    }
                })
                .then(res=>{
                    const latest = res.data.sort((a,b)=>{
                        if(a.dateAdded > b.dateAdded)
                        {
                            return -1
                        }
                        if(a.dateAdded < b.dateAdded)
                        {
                            return 1
                        }
                        return 0
                    })
                    setManifests(latest)
                })
            })
            // GetAllDetails()
            // .then(res=>{
            //     setManifestDetails(res)
            // })
            // GetAllReleases()
            // .then(res=>{
            //     setManifests(res)
            // })
            if(sessionStorage.getItem('view') && sessionStorage.getItem('view') !== undefined && sessionStorage.getItem('view') != 2) 
            {
                setView(sessionStorage.getItem('view'))
            }
            else
            {
                sessionStorage.setItem('view',1)
            }
            setPage('Setup')
        }
        
    },[loading])

    useEffect(()=>{
        sessionStorage.setItem('view',view)
    },[view])

    useEffect(()=>{
        if(manifests.length > 0)
        {
            let filterTemp = []
            let filterFinal = []
            if(filterEnv !== 'ALL')
            {
                manifests.forEach((item,id)=>{
                    item.targetEnvironment === filterEnv ? filterTemp.push(item) : <></>
                })
            }
            else
            {
                filterTemp = [...manifests]
            }
            if(filterStatus !== 'ALL')
            {
                filterTemp.forEach((item,id)=>{
                    item.status === filterStatus ? filterFinal.push(item) : <></>
                })
                setFiltered(filterFinal)
            }
            else
            {
                setFiltered(filterTemp)
            }
        }
    },[filterEnv, filterStatus, manifests])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };
  
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);



    function Search(e) {
        e.preventDefault()
        if(destination !== '' && destination !== ' ' && destination !== undefined) {
            let dbid = null
        }
    }

    return (
        <div className={`pt-24 w-3/4 ${drawerWidth < 240 ? 'mx-auto' : 'ml-[280px]'} min-h-[100vh] pb-8`}>
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:'10px'}}>
                <Box sx={{display:'flex',alignItems:'center'}}>
                    <Paper elevation={0} onSubmit={(e)=>Search(e)}
                    component="form"
                    sx={{ p: '0 2px', display: 'flex', alignItems: 'center', width: 300, border: `solid 1px ${colors[8]}`}}
                    >
                    <InputBase onChange={(e)=>setDestination(e.target.value)}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Filter Releases"
                    />
                    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                        <BsSearch size={20} />
                    </IconButton>
                    </Paper>
                    <button
                        className={`flex border gap-1 border-neutral-300 rounded-md ${mode === 'dark' ? 'bg-neutral-900 text-neutral-400' : 'bg-white text-neutral-600'} py-1.5 px-4 ml-2 hover:text-blue-500 hover:shadow-md transition duration-200`}
                        onClick={handleClick}
                    >
                        <BsFilter size={20}/> &nbsp;{filterStatus === 'ALL' ? 'Status' : filterStatus}
                    </button>
                    <Menu open={open} onClose={handleClose}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={(e) => {setFilterStatus('ALL')
                            setAnchorEl(null)
                            }}
                        >ALL</MenuItem>
                        <MenuItem onClick={(e) => {setFilterStatus('In Progress')
                            setAnchorEl(null)
                            }}
                        >In Progress</MenuItem>
                        <MenuItem onClick={(e) => {setFilterStatus('Finished')
                            setAnchorEl(null)
                            }}
                        >Finished</MenuItem>
                    </Menu>
                    <button
                        className={`flex border gap-1 border-neutral-300 rounded-md ${mode === 'dark' ? 'bg-neutral-900 text-neutral-400' : 'bg-white text-neutral-600'} py-1.5 px-4 ml-2 hover:text-blue-500 hover:shadow-md transition duration-200`}
                        onClick={handleClick2}
                    >
                        <BsFilter size={20}/> &nbsp;{filterEnv === 'ALL' ? 'Environment' : filterEnv}
                    </button>
                    <Menu open={open2} onClose={handleClose2}
                        anchorEl={anchorEl2}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={(e) => {setDenv('ALL')
                            setAnchorEl2(null)
                            sessionStorage.setItem('env','ALL')}}
                        >ALL</MenuItem>
                        <MenuItem onClick={(e) => {setDenv('DEV')
                            setAnchorEl2(null)
                            sessionStorage.setItem('env','DEV')}}
                        >DEV</MenuItem>
                        <MenuItem onClick={(e) => {setDenv('QA')
                            setAnchorEl2(null)
                            sessionStorage.setItem('env','QA')}}
                        >QA</MenuItem>
                        <MenuItem onClick={(e) => {setDenv('STAGE')
                            setAnchorEl2(null)
                            sessionStorage.setItem('env','STAGE')}}
                        >STAGE</MenuItem>
                        <MenuItem onClick={(e) => {setDenv('PROD')
                            setAnchorEl2(null)
                            sessionStorage.setItem('env','PROD')}}
                        >PROD</MenuItem>
                    </Menu>
                    {view == 0 ?  
                        <Box sx={{ml: '10px', display:"flex", gap:0.5, alignItems:'center'}}>
                            <IconButton size="small" onClick={()=>setView(1)}><BsFillGrid3X3GapFill size={20} /></IconButton>
                            <p className='text-neutral-300 text-2xl font-light'>|</p>
                            <IconButton size="small"><BsList style={{color:'#2997ff'}} size={24} /></IconButton>
                        </Box>  
                        :
                        <Box sx={{ml: '10px', display:"flex", gap:0.5, alignItems:'center'}}>
                            <IconButton size="small"><BsFillGrid3X3GapFill style={{color:'#2997ff'}} size={20} /></IconButton>
                            <p className='text-neutral-300 text-2xl font-light'>|</p>
                            <IconButton size="small" onClick={()=>setView(0)}><BsList size={24} /></IconButton>
                        </Box>
                    }
                </Box>
                <Box>
                    <button onClick={()=>{setShow(true);setSlide(2)}}
                        className='bg-neutral-800 px-4 py-2 text-white rounded-md hover:bg-opacity-80 transition duration-200 active:bg-opacity-100 hover:shadow-lg'
                    >
                        Create Release
                    </button>
                </Box>
            </Box>
            {view == 0 ?
                <div className='pb-8'>
                    <Paper variant="outlined" sx={{borderBottom:'none'}}>
                        <Table sx={mode === 'dark' ? {background:colors[9]} : {}}>
                            <TableHead>
                                <TableCell size="small" align="left">Release Name</TableCell>
                                <TableCell size="small" align="center">Version</TableCell>
                                <TableCell size="small" align="center">Environment</TableCell>
                                <TableCell size="small" align="center">Release Date</TableCell>
                                <TableCell size="small" align="center">Status</TableCell>
                                <TableCell size="small" align="center"></TableCell>
                            </TableHead>
                            <TableBody>
                                { manifests.length > 0 ?
                                    <>
                                        {filtered.map((item,id)=>{
                                            const uniqueDetails = manifestDetails.find((det,ind)=>det.id === item.id)
                                            let canCount = 0
                                            if(manifestDetails.length > 0)
                                            {
                                                uniqueDetails.phaseView.forEach((phase,pId)=>{
                                                    phase.groups.forEach((group,gId)=>{
                                                        canCount += group.candidates.length
                                                    })
                                                })
                                            }    
                                            return (
                                            <TableRow sx={{padding:'10px'}}
                                                hover
                                                onClick={()=>{ setCurrent(item.id)
                                                    sessionStorage.setItem('current',item.id)
                                                    sessionStorage.setItem('id',JSON.stringify(item))
                                                    item.status === 'In Progress' ? navigate(`/release/${item.id}`) : navigate(`/deploy/${item.id}`)
                                                }}
                                            >
                                                
                                                <TableCell size="small" align="left">
                                                    {showEdit === id ? <input className='border px-3 py-1 rounded-md' placeholder={item.description} /> : item.description}
                                                </TableCell>
                                                <TableCell size="small" align="center">
                                                    {showEdit === id ? <input className='border px-3 py-1 rounded-md' placeholder={item.version} /> : item.version}
                                                </TableCell>
                                                <TableCell size="small" align='center'>{showEdit === id ?
                                                                <select className='border px-3 py-1 rounded-md ml-1' defaultValue={item.targetEnvironment}
                                                                    onClick={e=>e.stopPropagation()}                                                                
                                                                >
                                                                    <option value='DEV'>DEV</option>
                                                                    <option value='QA'>QA</option>
                                                                    <option value='STAGE'>STAGE</option>
                                                                    <option value='PROD'>PROD</option>
                                                                </select>
                                                            :
                                                                item.targetEnvironment
                                                            }</TableCell>
                                                <TableCell size="small" align="center">
                                                    {showEdit === id ?
                                                        <input className='border px-3 py-1 rounded-md ml-1' type="datetime-local"
                                                            defaultValue={new Date(item.scheduledTimeStamp).toISOString().slice(0,16)} />
                                                    :
                                                        new Date(item.scheduledTimeStamp).toLocaleTimeString([], DATE_OPTIONS)
                                                    } 
                                                </TableCell>
                                                <TableCell size="small" align='center'>
                                                    <span
                                                        className={`${item.status === 'Finished' ? 'border-green-600 text-green-600'
                                                            : 'text-sky-500 border-sky-500'}
                                                            group-hover:shadow-md transition duration-500 text-xs py-0.5 px-1.5 border rounded-lg w-max h-max ml-auto`}
                                                    >
                                                        {item.status}
                                                    </span>
                                                </TableCell>
                                                <TableCell size="small" align='right'>
                                                    { showEdit !== id ? 
                                                        <button className='text-neutral-500 hover:text-blue-500'
                                                            onClick={(e)=>{e.stopPropagation();setShowEdit(id)}}
                                                        ><HiPencilSquare size={22} /></button>
                                                    :
                                                        <div className='flex gap-2 justify-end'
                                                        onClick={(e)=>e.stopPropagation()}
                                                        >
                                                            <button className='text-red-500 hover:text-red-500'
                                                            ><HiOutlineTrash size={22} /></button>
                                                            <button onClick={()=>setShowEdit(null)}
                                                                className='px-2 py-0.5 border border-neutral-400 rounded-md hover:opacity-80 hover:shadow-md transition duration-300'
                                                            >Cancel</button>
                                                            <button
                                                                className='px-2 py-0.5 border border-neutral-800 bg-neutral-800 text-white rounded-md hover:bg-opacity-80 hover:shadow-md transition duration-300'
                                                            >Save</button>
                                                        </div>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            )}
                                        )}
                                    </>
                                    :
                                    <></>
                                }
                            </TableBody>
                        </Table>
                    </Paper>
                </div>
                :
                <div className='grid grid-cols-3 gap-12 mt-2 mb-4'>
                    {manifests.length > 0 ?
                        <>
                            {filtered.map((item,id)=>
                                <div>
                                    <div className={`group border ${mode === 'dark' ? 'bg-zinc-900 border-neutral-600 hover:bg-slate-500 hover:bg-opacity-20' : 'bg-white border-gray-200'} rounded-md shadow-sm hover:shadow-lg cursor-pointer transition duration-200 h-full`}
                                        onClick={()=>{ 
                                            if(showEdit !== id)
                                            {
                                                setCurrent(item.id)
                                                sessionStorage.setItem('current',item.id)
                                                sessionStorage.setItem('id',JSON.stringify(item))
                                                item.status === 'In Progress' ? navigate(`/release/${item.id}`) : navigate(`/deploy/${item.id}`)
                                            }
                                        }}
                                    >
                                        <div className='flex flex-col h-full'>
                                            <div className={`${mode === 'dark' ? 'bg-zinc-800' : ''} group-hover:bg-opacity-60 group-hover:bg-slate-200 transition duration-200 rounded-t-md py-3 px-3 grid grid-cols-3 items-baseline`}>
                                                <p className='text-md font-semibold col-span-2 truncate'>
                                                    {showEdit === id ? <input className='border px-3 py-1 rounded-md ml-1' placeholder={item.description} /> : item.description}
                                                </p>
                                                <p
                                                    className={`${item.status === 'Finished' ? 'border-green-600 text-green-600'
                                                        : 'border-sky-500 text-sky-500'}
                                                        group-hover:shadow-md transition duration-500 text-xs py-0.5 px-1.5 border rounded-lg w-max h-max ml-auto`}
                                                >
                                                    {item.status}
                                                </p>
                                            </div>
                                            <hr className={`mb-1 ${mode === 'dark' ? 'border-neutral-600' : ''}`} />
                                            <div className={`flex flex-col gap-3 py-3`}>
                                                <div className={`flex flex-col gap-3 relative`}>
                                                    <div className='px-3 flex items-center gap-2'>
                                                        <BsBoxSeam />
                                                        <p className='text-sm'>
                                                            Release Version: {showEdit === id ? <input className='border px-3 py-1 rounded-md ml-1' placeholder={item.version} /> : item.version}
                                                        </p>
                                                    </div>
                                                    <div className='px-3 flex items-start gap-2 ml-[-3px]'>
                                                        <HiOutlineGlobeAmericas size={20} />
                                                        <p className='text-sm'>
                                                            Environment: {showEdit === id ?
                                                                <select className='border px-3 py-1 rounded-md ml-1' defaultValue={item.targetEnvironment}>
                                                                    <option value='DEV'>DEV</option>
                                                                    <option value='QA'>QA</option>
                                                                    <option value='STAGE'>STAGE</option>
                                                                    <option value='PROD'>PROD</option>
                                                                </select>
                                                            :
                                                                item.targetEnvironment
                                                            }
                                                        </p>
                                                    </div>
                                                    { showEdit !== id ? 
                                                        <button className='right-[12px] text-neutral-500 hover:text-blue-500 absolute'
                                                            onClick={(e)=>{e.stopPropagation();setShowEdit(id)}}
                                                        ><HiPencilSquare size={22} /></button>
                                                    :
                                                        <button className='right-[12px] text-red-500 hover:text-red-500 absolute bottom-0'
                                                            onClick={(e)=>e.stopPropagation()}
                                                        ><HiOutlineTrash size={22} /></button>
                                                    }
                                                </div>
                                                <hr className={`${showEdit !== id ? 'mt-10' : 'mt-2'} ${mode === 'dark' ? 'border-neutral-600' : ''}`} />
                                                <div className='px-3 flex items-center justify-between flex-wrap gap-2'>
                                                    <div className='flex items-center gap-2'>
                                                        <BsCalendar size={16}/>
                                                        <p className='text-sm'>
                                                            <span className='hidden 2xl:inline-block'>Date:&nbsp;</span>{showEdit === id ?
                                                            <input className='border px-3 py-1 rounded-md ml-1' type="datetime-local"
                                                                defaultValue={new Date(item.scheduledTimeStamp).toISOString().slice(0,16)} />
                                                            :
                                                            new Date(item.scheduledTimeStamp).toLocaleTimeString([], DATE_OPTIONS)}
                                                        </p>
                                                    </div>
                                                    { showEdit === id ? 
                                                        <div className='flex gap-1'>
                                                            <button onClick={()=>setShowEdit(null)}
                                                                className='px-2 py-0.5 border border-neutral-400 rounded-md hover:opacity-80 hover:shadow-md transition duration-300'
                                                            >Cancel</button>
                                                            <button
                                                                className='px-2 py-0.5 border border-neutral-800 bg-neutral-800 text-white rounded-md hover:bg-opacity-80 hover:shadow-md transition duration-300'
                                                            >Save</button>
                                                        </div>
                                                    :
                                                        <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    :
                    <></>
                    }
                </div>
            }
            <CreateRelease show={show} setShow={setShow} username={username} mode={mode} DATE_OPTIONS={DATE_OPTIONS} setManifests={setManifests}
                slide={slide} setSlide={setSlide} setCurrent={setCurrent} tour={tour}
            />
            <Snackbar sx={{ml:drawerWidth+'px'}}
                open={warning}
                autoHideDuration={3000}
                onClose={()=>setWarning(false)}
            >
                <Alert severity='error'>Deployment {destination} not found</Alert>
            </Snackbar>
        </div>
    )
}