import React, { useState, useEffect } from 'react'
import { InputBase, Paper, Table, TableRow, TableCell, TableBody, IconButton, Container,
        Typography, Button, MenuItem, TableHead, Menu, Dialog, DialogTitle, DialogContent,
        Select, InputLabel, FormControl, TextField, Grid, DialogActions, Snackbar, Alert, Card, CardActionArea, CardContent, LinearProgress, Input } from '@mui/material'
import { BsSearch, BsFilter, BsFillGrid3X3GapFill, BsList, BsTools, BsCalendar, BsCalendar2Event } from 'react-icons/bs'
import { Box } from '@mui/system';
import { useNavigate } from 'react-router';
import axios from 'axios';
import '../App.css'
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  MonthView,
  Appointments,
  Resources,
  Toolbar,
  DateNavigator,
  TodayButton
} from '@devexpress/dx-react-scheduler-material-ui';
import cube from '../cube (1).png'
import { useAuth } from '../auth/AuthContext';
import CreateRelease from './CreateRelease';

const DATE_OPTIONS =
    { year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', hour12: false };


export default function Calendar({colors, preview, setPreview, mode, setCurrent, manifests, setManifests, setPage, drawerWidth, page, loading, user, username}) {
    const [showFilter, setShowFilter] = useState(false)
    const [filterEnv, setDenv] = useState('ALL')
    const [filterStatus, setFilterStatus] = useState('ALL')
    const [filtered,setFiltered] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [warning, setWarning] = useState(false)
    const [show, setShow] = useState(false)
    const [view, setView] = useState(2)
    const [appointments, setAppointments] = useState([])
    const [destination, setDestination] = useState('')
    const timeNow = new Date().getTime()

    const currentDate = new Date().toLocaleDateString()
    const { currentUser } = useAuth()
    const navigate = useNavigate()

    useEffect(()=>{
        if(!loading)
        {
            currentUser.getIdToken().then((token) => {
                axios.get('/v2/manifests', {
                    headers: {
                      'authorization': `Bearer ${token}`
                    }
                })
                .then(res=>{
                    setManifests(res.data.reverse())
                })
            })

            setPage('Calendar')
        }
            
    },[loading])
    
    useEffect(()=>{
        sessionStorage.setItem('view',view)
    },[view])

    useEffect(()=>{
        if(manifests.length > 0)
        {
            let filterTemp = []
            if(filterEnv !== 'ALL')
            {
                manifests.forEach((item,id)=>{
                    item.targetEnvironment === filterEnv ? filterTemp.push(item) : <></>
                })
                setFiltered(filterTemp)
            }
            else if (filterStatus !== 'ALL')
            {
                manifests.forEach((item,id)=>{
                    item.status === filterStatus ? filterTemp.push(item) : <></>
                })
                setFiltered(filterTemp)

            }
            else
            {
                filterTemp = [...manifests]
                setFiltered([filterTemp])
            }
        
            let appointstemp = []
            filterTemp.map((man,id)=>{
                const appointObj = {
                    title: man.description,
                    ind: id,
                    startDate: new Date(man.scheduledTimeStamp).toISOString(),
                    endDate: new Date(man.scheduledTimeStamp+60*60*1000).toISOString()
                }
                appointstemp.push(appointObj)
            })
            setAppointments(appointstemp)
        }
    },[filterStatus, filterEnv, manifests])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const handleClose = () => {
        setAnchorEl(null);
      };
  

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
      };

    const handleClose2 = () => {
        setAnchorEl2(null);
      };
  
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);

    function Search(e) {
        e.preventDefault()
        if(destination !== '' && destination !== ' ' && destination !== undefined) {
            let dbid = null
        }
    }

    const Appointment = ({
        children, style, onClick, onMouseEnter, onMouseLeave, ...restProps
      }) => (
        <Appointments.Appointment
          {...restProps}
          onClick={(e)=>{ setCurrent(manifests[e.data.ind].id)
                sessionStorage.setItem('current',manifests[e.data.ind].id)
                sessionStorage.setItem('id',JSON.stringify(manifests[e.data.ind]))
                manifests[e.data.ind].status === 'In Progress' ? navigate(`/release/${manifests[e.data.ind].id}`) : navigate(`/deploy/${manifests[e.data.ind].id}`)
            }}
          style={
                {
                    ...style,
                    background: 'linear-gradient(to left, #333333, #4d4d4d)',
                    borderRadius: '8px',
                }
            }
        >
          {children}
        </Appointments.Appointment>
      );

    return (
        <div className={`pt-24 w-3/4 ${drawerWidth < 240 ? 'mx-auto' : 'ml-[280px]'} min-h-[100vh]`}>
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:'10px'}}>
                <Box sx={{display:'flex',alignItems:'center'}}>
                    <Paper elevation={0} onSubmit={(e)=>Search(e)}
                    component="form"
                    sx={{ p: '0 2px', display: 'flex', alignItems: 'center', width: 300, border: `solid 1px ${colors[8]}`}}
                    >
                    <InputBase onChange={(e)=>setDestination(e.target.value)}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Filter Releases"
                    />
                    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                        <BsSearch size={20} />
                    </IconButton>
                    </Paper>
                    <button
                        className={`flex border gap-1 border-neutral-300 rounded-md ${mode === 'dark' ? 'bg-neutral-900 text-neutral-400' : 'bg-white text-neutral-600'} py-1.5 px-4 ml-2 hover:text-blue-500 hover:shadow-md transition duration-200`}
                        onClick={handleClick}
                    >
                        <BsFilter size={20}/> &nbsp;{filterStatus === 'ALL' ? 'Status' : filterStatus}
                    </button>
                    <Menu open={open} onClose={handleClose}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={(e) => {setFilterStatus('ALL')
                            setAnchorEl(null)
                            }}
                        >ALL</MenuItem>
                        <MenuItem onClick={(e) => {setFilterStatus('In Progress')
                            setAnchorEl(null)
                            }}
                        >In Progress</MenuItem>
                        <MenuItem onClick={(e) => {setFilterStatus('Finished')
                            setAnchorEl(null)
                            }}
                        >Finished</MenuItem>
                    </Menu>
                    <button
                        className={`flex border gap-1 border-neutral-300 rounded-md ${mode === 'dark' ? 'bg-neutral-900 text-neutral-400' : 'bg-white text-neutral-600'} py-1.5 px-4 ml-2 hover:text-blue-500 hover:shadow-md transition duration-200`}
                        onClick={handleClick2}
                    >
                        <BsFilter size={20}/> &nbsp;{filterEnv === 'ALL' ? 'Environment' : filterEnv}
                    </button>
                    <Menu open={open2} onClose={handleClose2}
                        anchorEl={anchorEl2}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={(e) => {setDenv('ALL')
                            setAnchorEl2(null)
                            sessionStorage.setItem('env','ALL')}}
                        >ALL</MenuItem>
                        <MenuItem onClick={(e) => {setDenv('DEV')
                            setAnchorEl2(null)
                            sessionStorage.setItem('env','DEV')}}
                        >DEV</MenuItem>
                        <MenuItem onClick={(e) => {setDenv('QA')
                            setAnchorEl2(null)
                            sessionStorage.setItem('env','QA')}}
                        >QA</MenuItem>
                        <MenuItem onClick={(e) => {setDenv('STAGE')
                            setAnchorEl2(null)
                            sessionStorage.setItem('env','STAGE')}}
                        >STAGE</MenuItem>
                        <MenuItem onClick={(e) => {setDenv('PROD')
                            setAnchorEl2(null)
                            sessionStorage.setItem('env','PROD')}}
                        >PROD</MenuItem>
                    </Menu>
                </Box>
                <Box>
                    <button onClick={()=>setShow(true)}
                        className='bg-neutral-800 px-4 py-2 text-white rounded-md hover:bg-opacity-80 transition duration-200 active:bg-opacity-100 hover:shadow-lg'
                    >
                        Create Release
                    </button>
                </Box>
            </Box>
            <Paper sx={{mt: '2vh'}}>
                <Scheduler
                    data={appointments}
                >
                    <ViewState
                        defaultCurrentDate={currentDate}
                    />
                    <MonthView />
                    <Toolbar />
                    <DateNavigator />
                    <TodayButton />
                    <Appointments appointmentComponent={Appointment} />
                    <Resources resources={appointments} />
                </Scheduler>
            </Paper>
            <CreateRelease show={show} setShow={setShow} username={username} mode={mode} DATE_OPTIONS={DATE_OPTIONS} setManifests={setManifests}
                setCurrent={setCurrent}
            />
            <Snackbar sx={{ml:drawerWidth+'px'}}
                open={warning}
                autoHideDuration={3000}
                onClose={()=>setWarning(false)}
            >
                <Alert severity='error'>Deployment {destination} not found</Alert>
            </Snackbar>
        </div>
    )
}